import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import { ReactComponent as IronStoneLogo } from "assets/logo/ironstone_logo_title.svg";
import { app, authentication } from "@microsoft/teams-js";
import { TeamsApplicationView } from "components/TeamsApplicationView/TeamsApplicationView";
import { RequestAccessDialog } from "./RequestAccessDialog/RequestAccessDialog";

import styles from "./LoginView.module.scss";
import { useEnvironment } from "hooks/hooks";

interface LoginViewProps {
	allowedTenants: string[];
}

const LoginView = ({ allowedTenants }: LoginViewProps): JSX.Element => {
	const { instance } = useMsal();
	const [teamsCtx, setTeamsCtx] = useState<app.Context>();
	const [authToken, setAuthToken] = useState<string>();
	const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);
	const mailFromUrl = window.location.search.toLowerCase().split("mailloginprompt=")[1];
	const { isDemo } = useEnvironment();

	useEffect(() => {
		const getTeamsContext = async () => {
			try {
				await app.initialize();
				const teamsCtx = await app.getContext();
				const authToken = await authentication.getAuthToken();

				setTeamsCtx(teamsCtx);
				setAuthToken(authToken);
			} catch (error) {}
		};

		teamsCtx ?? getTeamsContext();
	}, [teamsCtx, setTeamsCtx]);

	const getLoginProperties = () => {
		const scopes = ["profile", "openid", "offline_access", "email"];

		if (mailFromUrl) {
			return {
				scopes,
				loginHint: mailFromUrl,
			};
		} else if (isDemo) {
			return {
				scopes,
				prompt: "select_account",
			};
		} else {
			return {
				scopes,
			};
		}
	};

	const onLoginClick: MouseEventHandler = async () =>
		await instance.loginRedirect({
			...getLoginProperties(),
		});

	return (
		<>
			{teamsCtx && authToken ? (
				allowedTenants.some((tid) => tid === teamsCtx.user?.tenant?.id) && (
					<TeamsApplicationView teams={teamsCtx} authToken={authToken} />
				)
			) : (
				<UnauthenticatedTemplate>
					<main className={styles.main}>
						<div className={styles.logoContainer}>
							<IronStoneLogo className={styles.logo} />
						</div>
						<Grid container className={styles.buttonContainer}>
							<Button
								className={styles.loginButton}
								onClick={onLoginClick}
								disableElevation
								variant="contained"
								size="large"
							>
								<Typography variant="h2" className={styles.loginButtonText}>
									Sign in to Ironstone Portal
								</Typography>
							</Button>
							{isDemo && (
								<Typography
									variant="h3"
									className={styles.requestDemo}
									onClick={() => setIsRequestFormOpen(true)}
								>
									Request demo access
								</Typography>
							)}
							<RequestAccessDialog
								onClose={() => setIsRequestFormOpen(false)}
								open={isRequestFormOpen}
							/>
						</Grid>
					</main>
				</UnauthenticatedTemplate>
			)}
		</>
	);
};

export { LoginView };
