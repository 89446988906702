import { Divider, Grid, Typography } from "@mui/material";
import { selectCartCountBySku, selectCartItems } from "features/hardware/products";
import { useAppSelector } from "hooks/hooks";
import type { ReactElement } from "react";
import { nonNullable } from "utilities/filters";
import { formatScandinavianCostString } from "utilities/currency/numberFormattingUtility";
import { HardwareImage } from "components/Hardware/HardwareImage";
import { ExternalLink } from "components/Common/Links";

export function SummaryCartProducts(): ReactElement {
	const cartItems = useAppSelector(selectCartItems);
	const countBySku = useAppSelector(selectCartCountBySku);
	return (
		<Grid container direction="column">
			{cartItems
				.filter(nonNullable)
				.map(
					({ url, imageUrl, displayName, description, sku, priceInfo: { priceNet } }) => {
						return (
							<Grid key={sku} container direction="column">
								<Grid container item py={2}>
									<Grid container item>
										<Grid item container alignItems="center">
											<HardwareImage
												productId={sku}
												imageUrl={imageUrl}
												size="large"
											/>
											<Grid container item xs={7} pl={1}>
												<Grid
													item
													xs={1}
													container
													alignItems="flex-start"
													justifyContent="flex-end"
													pr={1}
												>
													<Typography variant="description">
														{countBySku[sku]} x
													</Typography>
												</Grid>
												<Grid item xs={11} container direction="column">
													<Typography
														variant="description"
														fontWeight={500}
													>
														{displayName}
													</Typography>
													<Typography
														variant="caption"
														mt={1}
														color="text.secondary"
													>
														{description.substring(displayName.length)}
													</Typography>
													<ExternalLink
														href={url}
														textDecoration="underline"
														target="_blank"
													>
														Details
													</ExternalLink>
												</Grid>
											</Grid>
											<Grid container item xs={2} justifyContent="flex-end">
												<Typography variant="subtitle1">
													{formatScandinavianCostString(priceNet)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Divider />
							</Grid>
						);
					},
				)}
		</Grid>
	);
}
