import { Grid, IconButton, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Availability, PriceInfo } from "types";
import { HardwareImage } from "components/Hardware/HardwareImage";
import { QuantityAdjuster } from "components/Common/QuantityAdjuster";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { StockIndicator } from "../../HardwareBundles/StockIndicator";
import { ExternalLink } from "components/Common/Links";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import styles from "./SelectedHardwareListItem.module.scss";
import { useAppSelector } from "hooks/hooks";
import { selectProducts } from "features/hardware/products";

interface SelectedHardwareListItemProps {
	imageUrl: string;
	displayName: string;
	sku: string;
	description: string;
	url: string;
	priceInfo: PriceInfo;
	availability: Availability;
	count: number;
	deleteItem: () => void;
	incrementCount: () => void;
	decrementCount: () => void;
}

export const SelectedHardwareListItem = ({
	imageUrl,
	displayName,
	sku,
	description,
	url,
	priceInfo,
	availability,
	deleteItem,
	decrementCount,
	incrementCount,
	count,
}: SelectedHardwareListItemProps) => {
	const { isLoading: isProductsLoading } = useAppSelector(selectProducts);
	const [inTransition, setTransition] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setTransition(false);
		}, 10);
	}, []);

	if (isProductsLoading) {
		return (
			<Grid container className={styles.skeletonContainer}>
				<Skeleton variant="rectangular" height={200} />
			</Grid>
		);
	}

	return (
		<Grid container className={styles.border}>
			<Grid
				key={sku}
				container
				className={clsx(styles.cartItem, {
					[styles.transition]: inTransition,
				})}
			>
				<IconButton onClick={deleteItem} className={styles.removeIconContainer}>
					<HighlightOffIcon className={styles.removeIcon} />
				</IconButton>
				<Grid item className={styles.imageAndPriceContainer}>
					<HardwareImage productId={sku} imageUrl={imageUrl} size="x_small" />
					{priceInfo && (
						<Grid container>
							<Typography variant="body1" className={styles.price}>
								{formatCostString("", priceInfo.priceNet, ",-")}
							</Typography>
							<Typography variant="description" color="text.secondary">
								eks.mva
							</Typography>
						</Grid>
					)}
				</Grid>
				<Grid container>
					<Grid item>
						<Typography variant="body1" className={styles.truncate}>
							{displayName}
						</Typography>
						<Typography
							variant="description"
							color="text.secondary"
							component="div"
							className={styles.truncate}
						>
							{description.substring(displayName.length)}
						</Typography>
					</Grid>
					<Grid container className={styles.stockAndItemSwapContainer}>
						<StockIndicator
							idToSwap={sku}
							availability={availability}
							requestedQuantity={count}
							variant="horizontal"
						/>
					</Grid>
					<Grid container className={styles.productLink}>
						<ExternalLink href={url} textDecoration="underline" target="_blank">
							View specification
						</ExternalLink>
					</Grid>
					<Grid className={styles.descriptionAndCounterContainer}>
						<Grid container item mt={2}>
							<QuantityAdjuster
								quantity={count}
								up={incrementCount}
								down={decrementCount}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
