import { Grid, TableContainer } from "@mui/material";
import { useState, useRef, FC } from "react";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./ExpandableTableContainer.module.scss";
import { InternalLink } from "components/Common/Links";

const ExpandableTableContainer: FC = ({ children }) => {
	const [isTableFullHeight, setIsTableFullHeight] = useState(false);
	const tableContainerRef = useRef<null | HTMLDivElement>(null);

	const toggleTableHeight = () => {
		if (isTableFullHeight) {
			tableContainerRef.current?.scrollIntoView();
		}
		setTimeout(() => setIsTableFullHeight(!isTableFullHeight));
	};

	return (
		<>
			<TableContainer
				classes={{
					root: clsx(styles.tableContainer, {
						[styles.tableMinimumHeight]: !isTableFullHeight,
						[styles.tableFullHeight]: isTableFullHeight,
					}),
				}}
			>
				{children}
			</TableContainer>
			<InternalLink variant="body2" onClick={toggleTableHeight}>
				{isTableFullHeight ? (
					<Grid container>
						<KeyboardArrowUpIcon />
						Hide table entries
					</Grid>
				) : (
					<Grid container>
						<KeyboardArrowDownIcon />
						Show all table entries
					</Grid>
				)}
			</InternalLink>
			{!isTableFullHeight && <div className={styles.curtain} />}
		</>
	);
};

export { ExpandableTableContainer };
