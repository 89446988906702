import { Grid, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import styles from "./SmallCard.module.scss";
import clsx from "clsx";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectIsLockedDialogOpen, setIsLockedDialogOpen } from "features/lockedDialog";
import { PrimaryDialog } from "../Dialogs/Dialog";

interface SmallCardInputProps {
	containerStyles?: string;
	icon: JSX.Element;
	title: string;
	leftText: string;
	rightText: string;
	link: JSX.Element;
	locked?: boolean;
	onClick: () => void;
}

const SmallCard = ({
	icon,
	title,
	leftText,
	rightText,
	link,
	onClick,
	locked = false,
}: SmallCardInputProps) => {
	if (locked) return <LockedSmallCard title={title} rightText={rightText} link={link} />;
	return (
		<Grid
			container
			className={clsx({ [styles.container]: true, [styles.selectable]: link })}
			onClick={onClick}
		>
			<Grid item xs={12} className={styles.title}>
				<Typography variant="body1">{title}</Typography>
				{icon}
			</Grid>
			<Grid item xs={12} className={styles.textContainer}>
				<Typography variant="body1" fontWeight={500} display="inline">
					{leftText}
				</Typography>
				<Typography variant="description" display="inline" color="text.secondary">
					{rightText}
				</Typography>
			</Grid>
			<Grid item xs={12} className={styles.link}>
				{link}
			</Grid>
		</Grid>
	);
};

const LockedSmallCard = ({
	title,
	rightText,
	link,
}: {
	title: string;
	rightText: string;
	link: JSX.Element;
}) => {
	const { dispatch } = useAuth();

	const openLockedDialog = () => {
		dispatch(setIsLockedDialogOpen(true));
	};
	const isDialogOpen = useAppSelector(selectIsLockedDialogOpen);

	return (
		<>
			<Grid container className={styles.lockedContainer} onClick={openLockedDialog}>
				<div className={styles.blurredOverlay} />
				<LockIcon className={styles.lockIcon} />
				<div className={styles.lockedTitle}>
					<Typography variant="body1">{title}</Typography>
				</div>
				<Grid item xs={12} className={styles.textContainer}>
					<Typography variant="body1" fontWeight={500} display="inline">
						{13000}
					</Typography>
					<Typography variant="description" display="inline" color="text.secondary">
						{rightText}
					</Typography>
				</Grid>
				<Grid item xs={12} className={styles.lockedText}>
					<Typography variant="body1" fontWeight={500} display="inline">
						Locked
					</Typography>
					<Typography variant="description" display="inline">
						{" "}
						- You do not have access to this feature
					</Typography>
				</Grid>
				<Grid item xs={12} className={styles.link}>
					{link}
				</Grid>
			</Grid>
			{isDialogOpen && (
				<PrimaryDialog
					size="small"
					title="Locked feature"
					primaryButtonText="Ok"
					primaryAction={() => dispatch(setIsLockedDialogOpen(false))}
				>
					<Grid item>
						<Typography variant="body1">
							Get access to this feature by upgarding your package.
						</Typography>
						<Typography variant="body1">
							Required packages:
							<b> IT For Your Employees</b>
						</Typography>
					</Grid>
				</PrimaryDialog>
			)}
		</>
	);
};

export { SmallCard };
