import dayjs from "dayjs";

export const extractDate = (fileName: string) =>
	fileName.substring(fileName.lastIndexOf("__") + 2, fileName.length - 3);

export const convertToDate = (date: string) =>
	dayjs(`${date.substring(2, 4)}/${date.substring(0, 2)}/${date.substring(4, 8)}`);

export const extractAndFormatDate = (date: string) =>
	convertToDate(extractDate(date)).format("DD. MMMM YYYY");

export const handleFileNames = (payload: { Path: string }[]) =>
	payload
		.map(({ Path }) => Path)
		.map((fileName) => fileName.replaceAll("/", "__"))
		.filter((path) => !path.endsWith("Latest.md"))
		.sort((a, b) =>
			convertToDate(extractDate(b)).isAfter(convertToDate(extractDate(a))) ? 1 : -1,
		);
