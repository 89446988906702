import { createSlice } from "@reduxjs/toolkit";
import {
	fetchCustomerDocument,
	saveCustomer,
	updateAppRoleAssignments,
	updateCustomerInfo,
} from "actions/customerActions";
import { updateHardwareApprovers } from "actions/hardwareApproversActions";
import { postHardwareBundle } from "actions/hardwareBundlesActions";
import { fetchInvoice } from "actions/invoiceActions";
import { createOrder, createOrderRequest } from "actions/orderActions";
import { escalateSecurityIncident } from "actions/securityIncidentActions";
import { createUser, deleteUser, updateUser } from "actions/userActions";
import { RootState } from "store";

/**
 * Shows an success snackbar.
 * @param state The state object that holds snackbar properties.
 * @param message The message to show, must be no longer than 52 characters because of the snackbar design.
 * @param duration The duration to show the snackbar for.
 */
const showSuccessSnackbar = (state: any, message: string, duration = 6000) => {
	state.open = true;
	state.snackbarText = message;
	state.snackbarSeverity = "success";
	state.duration = duration;
};

/**
 * Shows an error snackbar.
 * @param state The state object that holds snackbar properties.
 * @param message The message to show, must be no longer than 52 characters because of the snackbar design.
 */
const showErrorSnackbar = (state: any, message: string) => {
	state.open = true;
	state.snackbarText = message;
	state.snackbarSeverity = "error";
	state.duration = 6000;
};

const snackbarSlice = createSlice({
	name: "snackbar",
	initialState: {
		open: false,
		duration: 6000,
		snackbarText: "",
		snackbarSeverity: "info" as "info" | "success" | "warning" | "error",
	},
	reducers: {
		setGlobalSnackbarState: (_, { payload }) => {
			const defaultPayload = {
				open: true,
				duration: 6000,
				snackbarText: "",
				snackbarSeverity: "info" as "info" | "success" | "warning" | "error",
			};
			return {
				...defaultPayload,
				...payload,
			};
		},
		setOpen: (state, { payload }) => {
			state.open = payload;
		},
		setDuration: (state, { payload }) => {
			state.duration = payload;
		},
		setSnackbarText: (state, { payload }) => {
			state.snackbarText = payload;
		},
		setSnackbarSeverity: (state, { payload }) => {
			state.snackbarSeverity = payload;
		},
	},
	extraReducers: (builder) => {
		// Customer actions
		builder
			.addCase(updateAppRoleAssignments.fulfilled, (state) => {
				state.open = true;
				showSuccessSnackbar(state, "Role updated successfully");
			})
			.addCase(updateAppRoleAssignments.rejected, (state) => {
				showErrorSnackbar(state, "Role update failed. Please try again");
			});
		builder
			.addCase(updateCustomerInfo.fulfilled, (state) => {
				showSuccessSnackbar(state, "Company information updated successfully");
			})
			.addCase(updateCustomerInfo.rejected, (state) => {
				showErrorSnackbar(state, "Company information update failed. Please try again");
			});
		builder
			.addCase(saveCustomer.fulfilled, (state) => {
				showSuccessSnackbar(state, "Settings saved successfully");
			})
			.addCase(saveCustomer.rejected, (state) => {
				showErrorSnackbar(state, "Settings save failed. Please try again");
			});
		builder
			.addCase(updateHardwareApprovers.rejected, (state) => {
				showErrorSnackbar(state, "Hardware approvers update failed. Please try again");
			})
			.addCase(updateHardwareApprovers.fulfilled, (state) => {
				showSuccessSnackbar(state, "Hardware approvers updated successfully");
			});

		// User actions
		builder
			.addCase(updateUser.fulfilled, (state) => {
				showSuccessSnackbar(state, "User updated successfully");
			})
			.addCase(updateUser.rejected, (state) => {
				showErrorSnackbar(state, "User update failed. Please try again");
			});
		builder
			.addCase(deleteUser.fulfilled, (state) => {
				showSuccessSnackbar(state, "User deleted successfully");
			})
			.addCase(deleteUser.rejected, (state) => {
				showErrorSnackbar(state, "User deletion failed. Please try again");
			});
		builder.addCase(createUser.rejected, (state) => {
			// Only rejected state as the fulfilled state is handled in the component
			showErrorSnackbar(state, "User creation failed. Please try again");
		});

		// Security incident actions
		builder
			.addCase(escalateSecurityIncident.fulfilled, (state) => {
				showSuccessSnackbar(state, "Incident escalated successfully");
			})
			.addCase(escalateSecurityIncident.rejected, (state) => {
				showErrorSnackbar(state, "Escalation failed. Please try again");
			});

		builder
			.addCase(postHardwareBundle.fulfilled, (state) => {
				showSuccessSnackbar(state, "Hardware bundles updated", 3000);
			})
			.addCase(postHardwareBundle.rejected, (state) => {
				showErrorSnackbar(state, "Bundle save failed. Please try again");
			});

		// Downloads
		builder
			.addCase(fetchInvoice.fulfilled, (state, { meta }) => {
				showSuccessSnackbar(state, `Invoice ${meta.arg.id} downloaded successfully`, 3000);
			})
			.addCase(fetchInvoice.rejected, (state) => {
				showErrorSnackbar(state, "Download failed. Please try again");
			});

		builder
			.addCase(fetchCustomerDocument.fulfilled, (state) => {
				showSuccessSnackbar(state, `Document downloaded successfully`, 3000);
			})
			.addCase(fetchCustomerDocument.rejected, (state) => {
				showErrorSnackbar(state, "Download failed. Please try again");
			});

		// Hardware orders
		builder
			.addCase(createOrder.rejected, (state) => {
				showErrorSnackbar(state, "Order creation failed. Please try again");
			})
			.addCase(createOrderRequest.rejected, (state) => {
				showErrorSnackbar(state, "Order request failed. Please try again");
			});
	},
});

export const {
	setGlobalSnackbarState,
	setOpen,
	setDuration,
	setSnackbarText,
	setSnackbarSeverity,
} = snackbarSlice.actions;

export const selectGlobalSnackbarState = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
