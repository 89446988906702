import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import styles from "./ConditionalAccessSection.module.scss";
import _ from "lodash";
import { InternalLink } from "components/Common/Links";

const prettifyHeader = (header) =>
	header[0].toUpperCase() + header.slice(1).replace(/([A-Z])/g, " $1");

const handleListStyle = (value, extract) => {
	const valueCount = _.size(value);

	if (valueCount > 1) return [12, 12, "flex-end"];

	if (valueCount === 0) {
		return [7.5, 4.5, "flex-end"];
	}

	const element = extract(value);

	const text = element?.props?.children ?? element;

	if (text !== "Not configured") return [12, 12, "flex-start"];

	return [7.5, 4.5, "flex-end"];
};

const ConditionalAccessSection = ({ value = {} }) => {
	const { Users, Applications, OtherSettings } = value;

	const handleSection = ({
		list,
		transform = _.identity, // No transformation
		extract = _.first, // Extract the first list element
		handleObject = (value) => (value ? [value] : []), // Put single object values within array
	}) =>
		Object.entries(list).map(([key, value]) => {
			if (!Array.isArray(value)) value = handleObject(value);

			const [headerxs, bodyxs, alignSingleValue] = handleListStyle(value, extract);

			return (
				<Grid
					key={key}
					container
					justifyContent="space-between"
					alignItems="flex-start"
					classes={{ root: styles.row }}
				>
					<Grid item xs={headerxs}>
						<Typography variant="subtitle2">{prettifyHeader(key)}</Typography>
					</Grid>
					<Grid item xs={bodyxs}>
						<List classes={{ root: styles.row }}>
							{_.size(value) > 1 ? (
								transform(value).map((name, idx) => (
									<ListItem
										key={`${name}-${idx}`}
										classes={{ root: styles.listItemWrapper }}
									>
										<ListItemText
											variant="caption"
											classes={{ root: styles.listItem }}
										>
											<Typography variant="caption">{name}</Typography>
										</ListItemText>
									</ListItem>
								))
							) : (
								<Grid
									container
									justifyContent={alignSingleValue}
									alignItems="flex-start"
								>
									<Typography variant="caption">
										{_.isEmpty(value) ? "Not configured" : extract(value)}
									</Typography>
								</Grid>
							)}
						</List>
					</Grid>
				</Grid>
			);
		});

	const userList = handleSection({
		list: Users,
		transform: (value) =>
			value
				.filter(({ DisplayName }) => DisplayName)
				.map(({ DisplayName, URI }) =>
					URI ? <InternalLink href={URI}>{DisplayName}</InternalLink> : DisplayName,
				),
		extract: ([{ DisplayName, URI }]) =>
			URI ? <InternalLink href={URI}>{DisplayName}</InternalLink> : DisplayName,
	});

	const applicationList = handleSection({ list: Applications, extract: _.identity });

	const settingList = handleSection({
		list: OtherSettings,
		handleObject: (list) => handleSection({ list }),
	});

	return (
		<Grid>
			<Typography variant="h6">
				<u>Users and Groups</u>
			</Typography>
			{userList}
			<Typography variant="h6">
				<u>Cloud Apps / Actions</u>
			</Typography>
			{applicationList}
			<Typography variant="h6">
				<u>Conditions</u>
			</Typography>
			{settingList}
		</Grid>
	);
};

export { ConditionalAccessSection };
