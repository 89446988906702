import { Divider, Grid, Typography } from "@mui/material";
import { useAppSelector } from "hooks/hooks";
import { nonNullable } from "utilities/filters";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import styles from "./ProductList.module.scss";
import { selectItemByIds } from "features/hardware/products";
import { ExternalLink } from "components/Common/Links";
import { handleDescription } from "../../HardwareList/Product";
import { StockIndicator } from "../StockIndicator";
import { HardwareImage } from "components/Hardware/HardwareImage";
import { HardwareBundleItem } from "types";

interface Props {
	products: { [key: string]: HardwareBundleItem };
	countBySku?: { [key: string]: number };
}

export const ProductList = ({ countBySku, products }: Props) => {
	const productsWithPriceInfo = useAppSelector(selectItemByIds(Object.keys(products))) || [];

	const skuWithPriceInfoSet = new Set(productsWithPriceInfo.map((priceInfo) => priceInfo?.sku));

	const eolProducts = Object.entries(products).filter(
		([key, _]) => !skuWithPriceInfoSet.has(key),
	);

	const eolProductDetails = eolProducts.map(([_, value]) => ({
		priceInfo: { priceNet: 0 },
		availability: undefined,
		...value,
	})) as any;

	return (
		<Grid container className={styles.container}>
			{[...eolProductDetails, ...productsWithPriceInfo]
				.filter(nonNullable)
				.map(
					(
						{
							imageUrl,
							displayName,
							description,
							sku,
							priceInfo: { priceNet },
							url,
							availability,
						},
						index,
					) => {
						return (
							<Grid
								key={sku}
								container
								className={styles.productContainer}
								style={{ animationDelay: `${index * 0.05}s` }}
							>
								<Grid container className={styles.productElement}>
									<HardwareImage
										imageUrl={imageUrl}
										productId={sku}
										size="large"
									/>
									<Grid
										container
										item
										className={styles.descriptionContainer}
										direction="column"
									>
										<Typography variant="subtitle1" color="text.primary">
											{displayName}
										</Typography>
										<Typography color="text.secondary" variant="description">
											{(description &&
												displayName &&
												handleDescription(description, displayName).join(
													", ",
												)) ||
												" "}
										</Typography>
										<Grid item className={styles.productLink}>
											<ExternalLink
												href={url}
												textDecoration="underline"
												target="_blank"
											>
												View Specification
											</ExternalLink>
										</Grid>
									</Grid>
									<Grid container className={styles.stockContainer}>
										<StockIndicator
											availability={availability}
											requestedQuantity={countBySku && countBySku[sku]}
											variant="vertical"
										/>
									</Grid>
									<Grid container className={styles.priceContainer}>
										<Typography variant="subtitle1">
											{formatCostString("", priceNet, ",-")}
										</Typography>
										<Typography variant="subtitle1">eks.mva</Typography>
									</Grid>
								</Grid>
								<Divider className={styles.divider} />
							</Grid>
						);
					},
				)}
		</Grid>
	);
};
