import { Collaboration } from "components/Collaboration/Collaboration";
import { ContentView } from "components/ContentView/ContentView";
import { CostManagementView } from "components/CostManagement/CostManagementView";
import { CostNavigation } from "components/CostManagement/CostNavigation/CostNavigation";
import { MyPeople } from "components/MyPeople/MyPeople";
import { Grid } from "@mui/material";
import {
	CustomerInfo,
	NavigationName,
	NavigationNames,
	ProductType,
	RoleAccess,
	RolesAccess,
} from "types";
import styles from "../../components/AuthenticatedContent/AuthenticatedContent.module.scss";
import colors from "styles/utilities/_colors.scss";
import { LicenseCostView } from "components/CostManagement/LicenseCostView";
import { ConsultancyCostView } from "components/CostManagement/ConsultancyCost";
import YourEmployeesCostView from "components/CostManagement/YourEmployeesCost/YourEmployeesCostView";
import YourITSystemsCostView from "components/CostManagement/YourITSystemsCost/YourITSystemsCostView";
import { AzureCostView } from "components/CostManagement/AzureCostView/AzureCostView";
import { InvoiceView } from "components/CostManagement/Invoice";
import { Dashboard } from "components/Dashboard";
import { SecurityDashboard } from "components/SecurityDashboard";
import { UserMyProfile } from "components/UserMyProfile";
import { HardwareOrders } from "components/HardwareOrders";
import { Reports } from "components/Reports";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES, REPORT_TYPES } from "./constants";
import { HardwareView } from "components/Hardware/HardwareView";
import { MyCompany } from "components/MyCompany";
import { DefaultNavigation } from "components/AuthenticatedContent/DefaultNavigation";
import RecommendationsView from "components/Recommendations/RecommendationsView";
import { SecurityDashboardInfoBanner } from "components/Common/InfoBanner";
import { LicenseManagementView } from "components/LicenseManagement";
import { DeviceOverview } from "components/ManageDevices";
import { Role } from "./enums";
import GroupsIcon from "@mui/icons-material/Groups";
import SecurityIconOutlined from "@mui/icons-material/SecurityOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { LicenseIcon } from "assets/cost/license_icon";
import { IdeaIcon } from "assets/misc/Idea";

interface ContentViewWrapper {
	navigationName: NavigationName;
	element?: JSX.Element;
	padding?: boolean;
	showLabel?: boolean;
	showDataUpdateInterval?: boolean;
	gridWrap?: boolean;
	children?: JSX.Element | JSX.Element[];
	centralizeHeader?: boolean;
}

interface ElementProps {
	navigationName: NavigationName;
	customerInfo: CustomerInfo;
	department: string;
	username: string;
}

const StandardContentViewWrapper = ({
	navigationName,
	padding = true,
	showLabel = true,
	showDataUpdateInterval = false,
	gridWrap = false,
	centralizeHeader = true,
	children,
}: ContentViewWrapper) => {
	const contentView = (
		<ContentView
			navigationName={navigationName}
			padding={padding}
			showLabel={showLabel}
			showDataUpdateInterval={showDataUpdateInterval}
			centralizeHeader={centralizeHeader}
		>
			{children}
		</ContentView>
	);

	return gridWrap ? <Grid className={styles.background}>{contentView}</Grid> : contentView;
};

interface StandardPageWrapperProps {
	element: JSX.Element;
	gridWrap?: boolean;
	showDataUpdateInterval?: boolean;
	padding?: boolean;
	centralizeHeader?: boolean;
	showLabel?: boolean;
}

export const standardPageWrapper =
	({
		element,
		gridWrap = false,
		showDataUpdateInterval = false,
		padding = true,
		centralizeHeader = true,
		showLabel = true,
	}: StandardPageWrapperProps) =>
	(props: ElementProps) =>
		(
			<StandardContentViewWrapper
				navigationName={props.navigationName}
				showDataUpdateInterval={showDataUpdateInterval}
				padding={padding}
				gridWrap={gridWrap}
				centralizeHeader={centralizeHeader}
				showLabel={showLabel}
			>
				{element}
			</StandardContentViewWrapper>
		);

const costPageWrapper =
	(element: JSX.Element, gridWrap: boolean = true) =>
	(props: ElementProps) =>
		(
			<Grid container direction="column">
				<CostNavigation />
				<StandardContentViewWrapper
					navigationName={props.navigationName}
					padding={false}
					showLabel={false}
					gridWrap={gridWrap}
				>
					{element}
				</StandardContentViewWrapper>
			</Grid>
		);

const inoviceViewPage = (props: ElementProps) => (
	<div className={styles.background}>
		<StandardContentViewWrapper navigationName={props.navigationName}>
			<InvoiceView />
		</StandardContentViewWrapper>
	</div>
);

const securityDashboardPage = (props: ElementProps) => (
	<div className={styles.background}>
		<SecurityDashboardInfoBanner
			infoText="Your Microsoft license is insufficient to populate the security features in this page. In order to view security dashboard please upgrade your Microsoft license"
			styleBanner="infoBanner"
			styleText="infoText"
		/>
		<StandardContentViewWrapper
			navigationName={props.navigationName}
			showDataUpdateInterval
			padding={true}
		>
			<SecurityDashboard />
		</StandardContentViewWrapper>
	</div>
);

const hardwarePageWrapper = (element: JSX.Element) => (props: ElementProps) =>
	(
		<StandardContentViewWrapper navigationName={props.navigationName} showLabel={false}>
			{element}
		</StandardContentViewWrapper>
	);

const ordersPage = (props: ElementProps) => (
	<StandardContentViewWrapper navigationName={props.navigationName} gridWrap>
		<HardwareOrders />
	</StandardContentViewWrapper>
);

const licenseManagementPage = (page: string) => (props: ElementProps) =>
	(
		<StandardContentViewWrapper
			navigationName={props.navigationName}
			gridWrap
			showLabel={false}
			padding={false}
			centralizeHeader={false}
		>
			<LicenseManagementView page={page} />
		</StandardContentViewWrapper>
	);

const defaultNavigation = (props: ElementProps) => <DefaultNavigation />;

const wildcard = () => (
	<ContentView navigationName={""}>The Page you are looking for does not exist.</ContentView>
);

const allRoles = [Role.ADMIN, Role.PORTALREAD, Role.HR, Role.FINANCE, Role.USER];

export const NAVIGATION_NAMES: Readonly<NavigationNames> = {
	MY_PEOPLE: {
		selector: "/myemployees",
		path: "/myemployees",
		value: "MyEmployees",
		label: "Manage Employees",
		accessibleWithProducts: [ProductType.YOUR_EMPLOYEES],
		accessibleByRoles: [Role.ADMIN, Role.HR, Role.PORTALREAD],
		element: standardPageWrapper({
			element: <MyPeople />,
			gridWrap: true,
			centralizeHeader: true,
		}),
		prefixIcon: <GroupsIcon />,
		description: (isActionRole: boolean) =>
			`View, filter, sort and export employee details. ${
				isActionRole ? "Edit, onboard and offboard employees." : ""
			}`,
	},
	DEVICE_OVERVIEW: {
		selector: "/devices",
		path: "/devices",
		value: "Devices",
		label: "Devices",
		accessibleWithProducts: [ProductType.YOUR_EMPLOYEES],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD],
		element: standardPageWrapper({ element: <DeviceOverview />, gridWrap: true }),
		description: () =>
			`View, filter, sort, and export device details including compliance status.`,
		prefixIcon: <LaptopMacOutlinedIcon />,
	},
	COST_MANAGEMENT: {
		selector: "/cost",
		path: "/cost",
		value: "CostManagement",
		label: "Cost Overview",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: costPageWrapper(<CostManagementView />),
		description: () =>
			"Get an overview of your IT-related costs: azure, licenses, hardware, consultancy and more. Export data and view detailed information.",
		prefixIcon: <RequestQuoteOutlinedIcon />,
	},
	LICENSE_COST_DETAILED: {
		selector: "/cost",
		path: "/cost/licenses",
		value: "LicenseCostDetailedTable",
		label: "License cost",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: costPageWrapper(<LicenseCostView />),
	},
	AZURE_COST_DETAILED: {
		selector: "/cost",
		path: "/cost/azure",
		value: "AzureCostDetailedTable",
		label: "Azure cost",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: costPageWrapper(<AzureCostView />),
	},
	CONSULTANCY_COST: {
		selector: "/cost",
		path: "/cost/consultancy",
		value: "ConsultancyCost",
		label: "Consultancy cost",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: costPageWrapper(<ConsultancyCostView />),
	},
	IT_FOR_YOUR_EMPLOYEES_COST: {
		selector: "/cost",
		path: "/cost/youremployees",
		value: "ItForYourEmployeesCost",
		label: `${IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES} cost`,
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: costPageWrapper(<YourEmployeesCostView />),
	},
	YOUR_IT_SYSTEMS_COST: {
		selector: "/cost",
		path: "/cost/youritsystems",
		value: "YourITSystemsCost",
		label: `${IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS} cost`,
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: costPageWrapper(<YourITSystemsCostView />),
	},
	INVOICE: {
		selector: "/invoice",
		path: "/invoice",
		value: "Invoice",
		label: "Invoices",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.FINANCE],
		element: inoviceViewPage,
		description: () => "View invoice amounts, payment statuses and download invoices.",
		prefixIcon: <ReceiptLongOutlinedIcon />,
	},
	LICENSE_MANAGEMENT: {
		selector: "/licenses",
		path: "/licenses",
		value: "ManageLicenses",
		label: "Manage licenses",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.HR],
		element: licenseManagementPage("/licenses"),
		description: (isActionRole: boolean) =>
			isActionRole
				? "Manage your licenses, view, assign, remove licenses, cancel subscriptions."
				: "View your licenses",
		prefixIcon: <LicenseIcon color={colors.grey600} />,
	},
	ASSIGN_LICENSES: {
		selector: "/licenses",
		path: "/licenses/assign",
		value: "AssignLicenses",
		label: "Assign Licenses",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.HR],
		element: licenseManagementPage("/licenses/assign"),
	},
	RECOMMENDATIONS: {
		selector: "/recommendations",
		path: "/recommendations",
		value: "Recommendations",
		label: "Recommendations",
		accessibleWithProducts: [ProductType.DEFAULT],
		// Recommendations are currently allowed for everyone, but is showing "Preview" for those without YE-package
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.HR, Role.FINANCE],
		element: standardPageWrapper({
			element: <RecommendationsView />,
			gridWrap: true,
			showDataUpdateInterval: true,
		}),
		description: (isActionRole: boolean) =>
			`View license recommendations and potential cost savings. ${
				isActionRole ? "Act on recommendations directly from this page." : ""
			}`,
		prefixIcon: <IdeaIcon color={colors.grey200} />,
	},
	SUPPORT: {
		selector: "/support",
		path: "/support",
		value: "Support",
		label: "Support Ticket",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: allRoles,
	},
	LOGOUT: {
		selector: "/logout",
		path: "/logout",
		value: "Logout",
		label: "Logout",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: allRoles,
	},
	DASHBOARD: {
		selector: "/dashboard",
		path: "/dashboard",
		value: "Dashboard",
		label: "Dashboard",
		accessibleWithProducts: [ProductType.YOUR_IT_SYSTEMS, ProductType.YOUR_EMPLOYEES],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD],
		element: standardPageWrapper({ element: <Dashboard />, gridWrap: true }),
		index: true,
		description: () => "Get an overview of security incidents, IT costs and service status.",
		prefixIcon: <DashboardOutlinedIcon />,
	},
	SECURITY_DASHBOARD: {
		selector: "/security",
		path: "/security",
		value: "SecurityDashboard",
		label: "Security Overview",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD],
		element: securityDashboardPage,
		description: () => "Get an overview of your security status, MFA, logins and more.",
		prefixIcon: <SecurityIconOutlined />,
	},
	MY_PROFILE: {
		selector: "/myprofile",
		path: "/myprofile",
		value: "MyProfile",
		label: "Profile",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: allRoles,
		element: standardPageWrapper({ element: <UserMyProfile /> }),
		description: () => "View your profile information.",
		prefixIcon: <PersonIcon />,
	},
	MY_COMPANY: {
		selector: "/mycompany",
		path: "/mycompany",
		value: "MyCompany",
		label: "Company",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD, Role.HR],
		element: standardPageWrapper({
			element: <MyCompany />,
			gridWrap: true,
			showLabel: false,
		}),
		description: (isActionRole: boolean) =>
			`View ${isActionRole ? "and edit" : ""} your company details.`,
		prefixIcon: <WorkOutlineOutlinedIcon />,
	},
	COLLABORATION: {
		selector: "/collaboration",
		path: "/collaboration",
		value: "Collaboration",
		label: "Collaboration",
		accessibleWithProducts: [ProductType.YOUR_EMPLOYEES],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD],
		element: standardPageWrapper({ element: <Collaboration />, gridWrap: true }),
		description: (isActionRole: boolean) =>
			`View ${isActionRole ? "and edit" : ""} collaboration settings.`,
		prefixIcon: <WorkspacesOutlinedIcon />,
	},
	COLLABORATION_REQUEST_FORM: {
		selector: "/collaboration/requestform",
		path: "/collaboration/requestform",
		value: "CollaborationRequestForm",
		label: "Request a collaboration space",
		accessibleWithProducts: [ProductType.YOUR_EMPLOYEES],
		accessibleByRoles: allRoles,
		description: () => "Request a collaboration space for your team.",
		prefixIcon: <WorkspacesOutlinedIcon />,
	},
	HARDWARE: {
		selector: "/hardware",
		path: "/hardware",
		value: "Hardware",
		label: "Buy Hardware",
		accessibleWithProducts: [ProductType.HARDWARE_SOLUTION],
		accessibleByRoles: allRoles,
		element: hardwarePageWrapper(<HardwareView page="hardware" />),
		description: (isActionRole: boolean) =>
			`${
				isActionRole
					? "Buy hardware for you or your company."
					: "Request hardware purchase with approval flow."
			} ${isActionRole ? "Create, edit and view hardware bundles." : ""}`,
		prefixIcon: <ShoppingCartOutlinedIcon />,
	},
	HARDWARE_CHECKOUT: {
		selector: "/hardware",
		path: "/hardware/checkout",
		value: "HardwareCheckout",
		label: "Hardware Checkout",
		accessibleWithProducts: [ProductType.HARDWARE_SOLUTION],
		accessibleByRoles: allRoles,
		element: hardwarePageWrapper(<HardwareView page="hardware-checkout" />),
	},
	HARDWARE_BUNDLES: {
		selector: "/hardware",
		path: "/hardware/bundles",
		value: "HardwareBundles",
		label: "Hardware Bundles",
		accessibleWithProducts: [ProductType.HARDWARE_SOLUTION],
		accessibleByRoles: [Role.ADMIN, Role.HR],
		element: hardwarePageWrapper(<HardwareView page="bundles" />),
	},
	HARDWARE_EDIT_BUNDLE: {
		selector: "/hardware",
		path: "/hardware/bundles/edit-bundle",
		value: "HardwareBundlesEdit",
		label: "Edit Hardware bundles",
		accessibleWithProducts: [ProductType.HARDWARE_SOLUTION],
		accessibleByRoles: [Role.ADMIN],
		element: hardwarePageWrapper(<HardwareView page="edit-bundle" />),
	},
	HARDWARE_CREATE_BUNDLE: {
		selector: "/hardware",
		path: "/hardware/bundles/create-bundle",
		value: "HardwareBundlesCreate",
		label: "Create Hardware Bundle",
		accessibleWithProducts: [ProductType.HARDWARE_SOLUTION],
		accessibleByRoles: [Role.ADMIN],
		element: hardwarePageWrapper(<HardwareView page="create-bundle" />),
	},
	ORDERS: {
		selector: "/orders",
		path: "/orders",
		value: "Orders",
		label: "Order History",
		accessibleWithProducts: [ProductType.YOUR_EMPLOYEES],
		accessibleByRoles: allRoles,
		element: ordersPage,
		description: () => `View hardware order history.`,
		prefixIcon: <HistoryOutlinedIcon />,
	},
	IDENTITY_REPORT: {
		selector: "/reports/identity",
		path: "/reports/identity",
		value: "IdentityReport",
		label: "Identity Report",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: [Role.ADMIN, Role.PORTALREAD],
		element: standardPageWrapper({
			element: <Reports reportType={REPORT_TYPES.IDENTITY_REPORT} />,
			padding: false,
			showLabel: false,
		}),
	},
	ROOT: {
		selector: "/",
		path: "/",
		value: "root",
		label: "root",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: allRoles,
		element: defaultNavigation,
	},
	WILDCARD: {
		selector: "*",
		path: "*",
		value: "*",
		label: "*",
		accessibleWithProducts: [ProductType.DEFAULT],
		accessibleByRoles: allRoles,
		element: wildcard,
	},
};

export const NAVIGATION_NAMES_BY_PATH: Readonly<Record<string, NavigationName>> =
	Object.fromEntries(Object.entries(NAVIGATION_NAMES).map(([key, value]) => [value.path, value]));

const adminRole: Readonly<RoleAccess> = {
	PAGES: Object.values(NAVIGATION_NAMES),
	DEFAULT_PAGE: NAVIGATION_NAMES.DASHBOARD,
	PRODUCTS: {}, // to be added later
};

const userRole: Readonly<RoleAccess> = {
	PAGES: Object.values(NAVIGATION_NAMES).filter((navigationName) =>
		navigationName.accessibleByRoles.includes(Role.USER),
	),
	DEFAULT_PAGE: NAVIGATION_NAMES.MY_PROFILE,
	PRODUCTS: {}, // to be added later
};

const portalReadRole: Readonly<RoleAccess> = {
	PAGES: Object.values(NAVIGATION_NAMES).filter((navigationName) =>
		navigationName.accessibleByRoles.includes(Role.PORTALREAD),
	),
	DEFAULT_PAGE: NAVIGATION_NAMES.DASHBOARD,
	PRODUCTS: {}, // to be added later
};

const hrRole: Readonly<RoleAccess> = {
	PAGES: Object.values(NAVIGATION_NAMES).filter((navigationName) =>
		navigationName.accessibleByRoles.includes(Role.HR),
	),
	DEFAULT_PAGE: NAVIGATION_NAMES.MY_PEOPLE,
	PRODUCTS: {}, // to be added later
};

const financeRole: Readonly<RoleAccess> = {
	PAGES: Object.values(NAVIGATION_NAMES).filter((navigationName) =>
		navigationName.accessibleByRoles.includes(Role.FINANCE),
	),
	DEFAULT_PAGE: NAVIGATION_NAMES.COST_MANAGEMENT,
	PRODUCTS: {}, // to be added later
};

export const ROLES_ACCESS: Readonly<RolesAccess> = {
	ADMIN: adminRole,
	USER: userRole,
	PORTALREAD: portalReadRole,
	HR: hrRole,
	FINANCE: financeRole,
};
