import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { DATA_LAKE_REPORT_PATHS } from "utilities/constants/constants";
import _ from "lodash";
import { fetchIdentityReportFileNames, fetchReport, fetchReportData } from "actions/reportActions";
import { handleFileNames } from "utilities/features/reports";

interface Report {
	folderPath: string;
	data: any;
	isLoading: boolean;
	isFetching: boolean;
}

const reportsAdapter = createEntityAdapter<Report>({
	selectId: ({ folderPath }) => folderPath,
});

interface InitialState {
	identityReportFileNames: string[];
}

const reportsSlice = createSlice({
	name: "reports",
	initialState: reportsAdapter.getInitialState({
		identityReportFileNames: [],
	} as InitialState),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(
				fetchReportData.pending,
				(
					state,
					{
						meta: {
							arg: { folderPath },
						},
					},
				) => {
					reportsAdapter.upsertOne(state, {
						folderPath,
						data: {},
						isFetching: true,
						isLoading: !(folderPath in state.entities),
					});
				},
			)
			.addCase(
				fetchReportData.fulfilled,
				(
					state,
					{
						meta: {
							arg: { folderPath, jsonPath },
						},
						payload,
					},
				) => {
					const data = _.get(payload, jsonPath) ?? [];
					reportsAdapter.upsertOne(state, {
						folderPath,
						data,
						isFetching: false,
						isLoading: false,
					});
				},
			);
		builder
			.addCase(
				fetchReport.pending,
				(
					state,
					{
						meta: {
							arg: { folderPath },
						},
					},
				) => {
					reportsAdapter.upsertOne(state, {
						folderPath,
						data: "",
						isFetching: true,
						isLoading: !(folderPath in state.entities),
					});
				},
			)
			.addCase(
				fetchReport.fulfilled,
				(
					state,
					{
						meta: {
							arg: { folderPath },
						},
						payload,
					},
				) => {
					reportsAdapter.upsertOne(state, {
						folderPath,
						data: payload.join("\n"),
						isFetching: false,
						isLoading: false,
					});
				},
			);

		builder.addCase(fetchIdentityReportFileNames.fulfilled, (state, { payload }) => {
			state.identityReportFileNames = handleFileNames(payload);
		});
	},
});

export const reportSelectors = reportsAdapter.getSelectors((state: RootState) => state.reports);

export const selectIdentityReport = (state: RootState) =>
	reportSelectors.selectById(state, DATA_LAKE_REPORT_PATHS.IDENTITY) ?? {
		isLoading: true,
		isFetching: false,
	};

export const selectIdentityReportFileNames = (state: RootState) =>
	state.reports.identityReportFileNames;

export default reportsSlice.reducer;
