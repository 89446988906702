import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { IdTokenClaims } from "types";

const rolesSlice = createSlice({
	name: "roles",
	initialState: [] as string[],
	reducers: {
		setRoles(_, { payload }) {
			const tokenClaims = payload as IdTokenClaims;
			const allRoles = (tokenClaims?.roles as string[]) ?? ["User"];
			return allRoles;
		},
	},
});

export const { setRoles } = rolesSlice.actions;

export const selectRoles = (state: RootState) => state.roles;

export default rolesSlice.reducer;
