import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchLicenseSubscriptionRecommendations } from "actions/recommendationActions";
import dayjs from "dayjs";
import { RootState } from "store";
import {
	IdleUsersDetails,
	LicenseAction,
	LicenseSubscriptionRecommendation,
	PreviewCostData,
} from "types";
import { licenseActionsSelector } from "./scheduledActions";
import {
	BillingCycle,
	LicenseActionStatus,
	LicenseActionTargetType,
} from "utilities/constants/enums";
import { caculateTotalCostSavings } from "utilities/licenseUtils/costSavingsUtils";

const recommendationsAdapter = createEntityAdapter<LicenseSubscriptionRecommendation>({
	selectId: (licenseSubscriptionRecommendation) =>
		licenseSubscriptionRecommendation.subscriptionSkuID,
});

const recommendationsSlice = createSlice({
	name: "recommendationsSlice",
	initialState: recommendationsAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		previewCostData: {} as PreviewCostData,
		totalYearlyCostSavings: 0,
		subscriptionExpirationThreshold: 0,
		idleUsersDetails: [] as IdleUsersDetails[],
		setConfirmationMailContent: {} as any,
		recommendationActionBodies: [] as LicenseAction[],
		isScheduledActionsDialogOpen: false,
	}),
	reducers: {
		setConfirmationMailContent: (state, { payload }) => {
			state.setConfirmationMailContent = payload;
		},
		setRecommendationActionBodies: (state, { payload }) => {
			state.recommendationActionBodies = payload;
		},
		setIsScheduledActionsDialogOpen: (state, { payload }) => {
			state.isScheduledActionsDialogOpen = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLicenseSubscriptionRecommendations.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchLicenseSubscriptionRecommendations.fulfilled, (state, { payload }) => {
				const subscriptionExpirationThreshold = payload.subscriptionExpirationThreshold;
				const recommendations = mapRecommendations(
					payload.licenses,
					subscriptionExpirationThreshold,
				);
				recommendationsAdapter.upsertMany(state, recommendations);
				state.isFetching = false;
				state.isLoading = false;
				state.totalYearlyCostSavings = Math.abs(payload.totalCostChange * 12) as number;
				const monthlyCostSavings = Math.abs(payload.totalCostChange);
				state.previewCostData = {
					monthlyCost: payload.currentTotalCost,
					monthlySavings: monthlyCostSavings,
					monthlyCostAfterSavings: payload.currentTotalCost - monthlyCostSavings,
					yearlySavings: Math.abs(payload.totalCostChange_Yearly),
				};
				state.subscriptionExpirationThreshold = subscriptionExpirationThreshold;
				state.idleUsersDetails =
					(payload.idleUsers_Details?.map((user) => ({
						...user,
						onPremisesSyncEnabled: user.onPremisesSyncEnabled ?? false,
					})) as IdleUsersDetails[]) ?? [];
			})
			.addCase(fetchLicenseSubscriptionRecommendations.rejected, (state) => {
				state.isLoading = false;
				state.isFetching = false;
			});
	},
});

export const {
	setConfirmationMailContent,
	setRecommendationActionBodies,
	setIsScheduledActionsDialogOpen,
} = recommendationsSlice.actions;

export const selectConfirmationMailContent = (state: RootState) =>
	state.recommendations.setConfirmationMailContent;
export const selectRecommendationActionBodies = (state: RootState) =>
	state.recommendations.recommendationActionBodies;
export const selectIsScheduledActionsDialogOpen = (state: RootState) =>
	state.recommendations.isScheduledActionsDialogOpen;
export const selectSubscriptionExpirationThreshold = (state: RootState) =>
	state.recommendations.subscriptionExpirationThreshold;
export const selectRecommendations = (state: RootState) => state.recommendations;

export const makeSelectIdleUsersDetail = (skuId: string) =>
	createSelector(
		[(state: RootState) => state.recommendations.idleUsersDetails as IdleUsersDetails[]],
		(idle_userDetails: IdleUsersDetails[]) =>
			idle_userDetails.filter((detail) => detail.skusToRemove?.includes(skuId)),
	);

export const recommendationsSelector = recommendationsAdapter.getSelectors(selectRecommendations);

export const selectCostSavingRecommendations = createSelector(
	[recommendationsSelector.selectAll, licenseActionsSelector.selectAll],
	(recommendations, licenseActions) => {
		const userRemovalActions = licenseActions.filter(
			(action) =>
				action.TargetType === LicenseActionTargetType.User &&
				action.Status === LicenseActionStatus.Scheduled,
		);

		const relevantLicenseActions = licenseActions.reduce((acc, curr) => {
			if (
				curr.TargetType === LicenseActionTargetType.Subscription &&
				curr.Status === LicenseActionStatus.Scheduled
			) {
				acc[curr.SkuGUID] = [...(acc[curr.SkuGUID] ?? []), curr];
			}

			return acc;
		}, {} as Record<string, LicenseAction[]>);

		const costSavingRecommendations = recommendations.filter(
			({ costChange }) => costChange !== null,
		);

		const recommendationsWithScheduledActionsTakenIntoAccount = costSavingRecommendations.map(
			(recommendation) => {
				const relatedLicenseActions =
					relevantLicenseActions[recommendation.subscriptionSkuID] ?? [];

				const numUsersScheduledForRemoval = userRemovalActions.filter(
					(userRemovalAction) =>
						recommendation.idleUsers_Details.filter(
							({ id }) => userRemovalAction.TargetGUID === id,
						).length > 0,
				).length;

				const scheduledRemoval =
					relatedLicenseActions.reduce(
						(acc, curr) => acc + Math.abs(curr.QuantityChange),
						0,
					) ?? 0;
				const maxRemoveableQuantityBasedOnScheduledActions =
					Math.min(
						recommendation.maxRemoveableQuantity,
						recommendation.current_Idle + recommendation.current_Unassigned,
					) - scheduledRemoval;

				let currentIdle = recommendation.current_Idle;
				let currentUnassigned = recommendation.current_Unassigned;
				if (recommendation.maxRemoveableQuantity > 0) {
					// If max removeable quantity is NOT above 0, it means that the subscription is already at its minimum
					// a user scheduled for removal will then NOT have an effect on the current idle and unassigned counts
					//
					// The body below decrements the current idle if a user has been scheduled for removal
					// This will make sure that a removal of a user on another subscription will decrement the idle user count
					// on all subscriptions that the user is a part of
					// In that case, the current unassigned count will be incremented by the same amount
					currentIdle = recommendation.current_Idle - numUsersScheduledForRemoval;
					currentUnassigned =
						recommendation.current_Unassigned + numUsersScheduledForRemoval;
				}

				return {
					...recommendation,
					current_Idle: currentIdle,
					current_Unassigned: currentUnassigned,
					maxRemoveableQuantityBasedOnScheduledActions,
				};
			},
		);
		return recommendationsWithScheduledActionsTakenIntoAccount;
	},
);

export const selectPreviewCostData = (state: RootState) => state.recommendations.previewCostData;
const selectTotalYearlyCostSavingsProperty = (state: RootState) =>
	state.recommendations.totalYearlyCostSavings;

export const selectTotalyearlyCostSavings = createSelector(
	[selectCostSavingRecommendations, selectTotalYearlyCostSavingsProperty],
	(costSavingRecommendations, totalYearlyCostSavings) => {
		let totalPossibleSavings = costSavingRecommendations.reduce(
			(acc, curr) => acc + (caculateTotalCostSavings(curr).unused ?? 0) * 12,
			0,
		);

		if (totalPossibleSavings <= 0) {
			totalPossibleSavings = totalYearlyCostSavings;
		}

		return totalPossibleSavings;
	},
);

export const selectNumberOfRecommendations = createSelector(
	recommendationsSelector.selectAll,
	(recommendations) =>
		recommendations.flatMap((recommendation) =>
			recommendation.recommendation_Types.filter(
				(type) => type !== "CHANGE_TERMDURATIONRATIO",
			),
		).length,
);

export default recommendationsSlice.reducer;

const mapRecommendations = (
	recommendations: LicenseSubscriptionRecommendation[],
	subscriptionExpirationThreshold: number,
) => {
	const licenseRecommendations =
		(recommendations?.map((licenseRecommendation) => {
			const maxExpirationEndDate = dayjs().add(subscriptionExpirationThreshold, "day");
			const maxRemoveableQuantity = licenseRecommendation.subscriptions.reduce(
				(acc, curr) => {
					const subscriptionEndsWithinMaxRemovalDate = dayjs(
						curr.commitmentEndDate,
					).isBefore(maxExpirationEndDate);
					if (subscriptionEndsWithinMaxRemovalDate) {
						return acc + curr.quantity;
					}
					return acc;
				},
				0,
			);

			const subscriptionWithUnitPricesAdjustedForBillingCycle =
				licenseRecommendation.subscriptions.map((subscription) => {
					const unitPriceAdjustedForBillingCycle =
						subscription.billingCycle?.toUpperCase() === BillingCycle.Annual
							? subscription.unitPriceAfterDiscount / 12
							: subscription.unitPriceAfterDiscount;
					return {
						...subscription,
						unitPriceAfterDiscount: unitPriceAdjustedForBillingCycle,
					};
				});

			return {
				...licenseRecommendation,
				subscriptions: subscriptionWithUnitPricesAdjustedForBillingCycle,
				maxRemoveableQuantity,
			};
		}) as LicenseSubscriptionRecommendation[]) ?? [];
	const sorted = licenseRecommendations.sort((a, b) => (a.costChange > b.costChange ? 1 : -1));
	return sorted;
};
