import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchReport = createAsyncThunk(
	"reports/fetchReport",
	apiGet<string[]>(
		(authConfig, { folderPath }) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(folderPath),
		[SCOPES.DATA_LAKE_READ],
	),
);

export const fetchReportData = createAsyncThunk(
	"reports/fetchReportData",
	apiGet(
		(authConfig, { folderPath }) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(folderPath),
		[SCOPES.DATA_LAKE_READ],
	),
);

export const fetchIdentityReportFileNames = createAsyncThunk(
	"reports/fetchIdentityReportFileNames",
	apiGet<{ Path: string }[]>(
		(authConfig) => authConfig.DATA_LAKE_API_ENDPOINTS.GET_IDENTITY_REPORT_FILENAMES(),
		[SCOPES.DATA_LAKE_READ],
	),
);
