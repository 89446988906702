import { AuthConfig } from "types";
import { SCOPES } from "utilities/constants/constants";
import { get } from "utilities/rest";

interface GetServicePrincipalProps {
	appId: string;
	instance: any;
	account: any;
	authConfig: AuthConfig;
}

type ServicePrincipal = {
	id: string;
	appId: string;
	displayName: string;
	appRoles: AppRole[];
};

type AppRole = {
	id: string;
	displayName: string;
	description: string;
	value: string;
};

// We will return null in the cases: (A), (B) and (C)
// When returning null here, we will set anyPortalAdmins to false, as well as the portalIsInTenant to false
const getServicePrincipal = async ({
	instance,
	account,
	authConfig,
	appId,
}: GetServicePrincipalProps) => {
	const token = await instance.acquireTokenSilent({
		scopes: [SCOPES.GRAPH_READ],
		account,
	});

	// (A) - We receive an invalid_grant error from Azure AD, i.e the user has not granted consent to the portal
	if (token.invalid_grant) {
		return null;
	}

	const endpoints = authConfig.MS_GRAPH_API_ENDPOINTS;

	const servicePrincipal = await get(
		endpoints.GET_SERVICE_PRINCIPAL(),
		token.tokenType,
		token.accessToken,
		false,
		"application/json",
		undefined,
		{
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
			"Cache-Control": "no-cache",
		},
		`appId=${appId}`,
	);

	// (B) - We receive a 401 from Microsoft Graph API, i.e the portal has no access
	// (C) - We receive a 204 from Microsoft Graph API, i.e the service principal does not exist
	if (servicePrincipal?.status === 204) {
		return null;
	}

	if (servicePrincipal?.status === 200) {
		return servicePrincipal.data as ServicePrincipal;
	}

	return null;
};

export { getServicePrincipal };
