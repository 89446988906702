import {
	createEntityAdapter,
	createSelector,
	createSlice,
	Dictionary,
	EntityState,
} from "@reduxjs/toolkit";
import { fetchHardwareBundles, postHardwareBundle } from "actions/hardwareBundlesActions";
import { RootState } from "store";
import { HardwareBundle, HardwareProduct, HardwareProductExtended } from "types";
import _ from "lodash";
import { productsSelectors } from "./products";
import { bundleCountBySku } from "components/Hardware/SelectHardware/HardwareBundles/ProductList/util";

const hardwareBundlesAdapter = createEntityAdapter<HardwareBundle>();

interface HardwareBundleState extends EntityState<HardwareBundle>, BundleState {
	isLoading: boolean;
	isFetching: boolean;
}

interface BundleState {
	bundle: {
		countBySku: { [sku: string]: number };
		addedAtBySku: { [sku: string]: number };
		activeBundle?: HardwareBundle | null;
	};
}

const hardwareBundlesSlice = createSlice({
	name: "hardwareBundles",
	initialState: hardwareBundlesAdapter.getInitialState({
		bundle: {
			countBySku: {},
			addedAtBySku: {},
			activeBundle: undefined,
		},
		isLoading: true,
		isFetching: false,
	}),
	reducers: {
		// TODO: Only multiple items? More scalable,e.t.c
		addItemToBundle(state: HardwareBundleState, { payload: sku }) {
			const productAlreadyInBundle = state.bundle.countBySku[sku] !== undefined;
			state.bundle.countBySku[sku] = productAlreadyInBundle
				? state.bundle.countBySku[sku] + 1
				: 1;
			if (!productAlreadyInBundle) {
				state.bundle.addedAtBySku[sku] = Date.now();
			}
			return state;
		},
		addItemsfromPurchaseCart(
			state: HardwareBundleState,
			{ payload: purchaseCartState }: { payload: { [sku: string]: number } },
		) {
			state.bundle.activeBundle = null;
			state.bundle.countBySku = purchaseCartState;
			state.bundle.addedAtBySku = Object.keys(purchaseCartState).reduce(
				(acc, sku) => ({ ...acc, [sku]: Date.now() }),
				{},
			);
			return state;
		},
		editBundle(state: HardwareBundleState, { payload: bundle }: { payload: HardwareBundle }) {
			const newBundleCountBySku = bundleCountBySku(bundle);
			state.bundle.activeBundle = bundle;
			state.bundle.countBySku = newBundleCountBySku;
			return state;
		},
		removeItemFromBundle(state: HardwareBundleState, { payload: sku }) {
			const count = state.bundle.countBySku[sku];
			if (count === undefined) return state;
			if (count === 1) {
				delete state.bundle.countBySku[sku];
				return state;
			}
			state.bundle.countBySku[sku]--;
			return state;
		},
		deleteBundleItem(state: HardwareBundleState, { payload: sku }) {
			delete state.bundle.countBySku[sku];
			return state;
		},
		clearBundleItems(state: HardwareBundleState) {
			state.bundle.countBySku = {};
			state.bundle.activeBundle = null;
			return state;
		},
		setBundleInfo(state: HardwareBundleState, { payload: bundleInfo }) {
			const newBundle = {
				...state.bundle.activeBundle,
				...bundleInfo,
			};
			state.bundle.activeBundle = newBundle;
			return state;
		},
		swapBundleItems(
			state: HardwareBundleState,
			{ payload: { productIdToSwap, productId, count } },
		) {
			delete state.bundle.countBySku[productIdToSwap];
			state.bundle.countBySku[productId] = count;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchHardwareBundles.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchHardwareBundles.fulfilled, (state, { payload }) => {
				if (payload) {
					// Make sure all published bundles are marked as published, remove this if we want to show unpublished bundles at some point
					const publishedBundles = Object.values(payload).map((bundle) => ({
						...bundle,
						published: true,
					}));
					hardwareBundlesAdapter.setAll(state, publishedBundles);
				}
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(fetchHardwareBundles.rejected, (state) => {
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(
				postHardwareBundle.fulfilled,
				(
					state,
					{
						meta: {
							arg: { body },
						},
					},
				) => {
					const bundleResponse = body as { [key: string]: HardwareBundle };
					hardwareBundlesAdapter.setAll(state, bundleResponse);
				},
			);
	},
});

export const {
	addItemToBundle,
	addItemsfromPurchaseCart,
	removeItemFromBundle,
	clearBundleItems,
	deleteBundleItem,
	editBundle,
	setBundleInfo,
	swapBundleItems,
} = hardwareBundlesSlice.actions;

export const selectHardwareBundles = (state: RootState) => state.hardwareBundles;

export const hardwareBundlesSelectors = hardwareBundlesAdapter.getSelectors(selectHardwareBundles);

export const selectAddedAtBySku = (state: RootState) => state.hardwareBundles.bundle.addedAtBySku;
export const selectBundleCountBySku = (state: RootState) => state.hardwareBundles.bundle.countBySku;

export const selectActiveEditedBundle = (state: RootState) =>
	state.hardwareBundles.bundle.activeBundle;

export const selectBundleItems = createSelector(
	selectBundleCountBySku,
	selectAddedAtBySku,
	selectActiveEditedBundle,
	productsSelectors.selectEntities,
	(bundleCountBySku, addedAtBySku, activeBundle, productsById) => {
		const products = Object.keys(bundleCountBySku).map((sku) => {
			let product = productsById[sku] as HardwareProduct;

			if (!product) {
				// If product is not found in productsById, it means it's probably EOL - no longer available from Komplett
				// However, we still want to show it in the bundle, so that the user can remove it from the bundle,
				// so we'll use the product that has been persisted in the bundle config file
				product = (activeBundle?.products[sku] ??
					({} as HardwareProduct)) as HardwareProduct;
			}

			return product;
		});
		return products.sort((a, b) => {
			const addedAtA = addedAtBySku[a?.sku || ""];
			const addedAtB = addedAtBySku[b?.sku || ""];

			// First sort by last edited
			if (addedAtA && addedAtB) return addedAtB - addedAtA;
			if (addedAtA && !addedAtB) return -1;
			if (!addedAtA && addedAtB) return 1;

			// Check for out-of-stock items
			const aOutOfStock = Number(a?.availability?.quantity || 0) === 0;
			const bOutOfStock = Number(b?.availability?.quantity || 0) === 0;

			if (aOutOfStock && !bOutOfStock) return -1;
			if (!aOutOfStock && bOutOfStock) return 1;

			if (aOutOfStock && bOutOfStock) {
				// If both out of stock, sort by availableDate, those available LAST should be first
				return (
					new Date(b?.availability?.availableDate || 0).getTime() -
					new Date(a?.availability?.availableDate || 0).getTime()
				);
			}

			// If both are in stock sort by price
			return (b?.priceInfo?.priceNet || 0) - (a?.priceInfo?.priceNet || 0);
		});
	},
);

export const selectBundleCount = createSelector(
	selectBundleCountBySku,
	(countBySku: { [sku: string]: number }): number => _.sum(Object.values(countBySku)),
);

const calculateBundlePriceFunc = (
	countBySku: { [sku: string]: number },
	hardwareBundlesById: Dictionary<HardwareProductExtended>,
): number =>
	Math.round(
		_.sum(
			Object.entries(countBySku).map(
				([sku, count]) => (hardwareBundlesById[sku]?.priceInfo.priceNet ?? 0) * count,
			),
		),
	);

export const selectBundlePriceTotal = createSelector(
	selectBundleCountBySku,
	productsSelectors.selectEntities,
	calculateBundlePriceFunc,
);

export const selectBundlePriceTotalBySkuCount = (countBySku: { [sku: string]: number }) =>
	createSelector(productsSelectors.selectEntities, (hardwareBundlesById): number =>
		calculateBundlePriceFunc(countBySku, hardwareBundlesById),
	);

export const selectHardwareBundlesSortedByCreatedAt = createSelector(
	hardwareBundlesSelectors.selectAll,
	(hardwareBundles) =>
		hardwareBundles.sort((a, b) => {
			const aTime = a.createdAt ? new Date(a.createdAt).getTime() : -Infinity;
			const bTime = b.createdAt ? new Date(b.createdAt).getTime() : -Infinity;
			return bTime - aTime;
		}),
);

export default hardwareBundlesSlice.reducer;
