import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "hooks/hooks";
import { selectBundlePriceTotalBySkuCount } from "features/hardware/hardwareBundles";
import styles from "./CalculatedTotalCost.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

interface Props {
	className?: string;
	countBySku?: { [key: string]: number };
}

export const CalculatedTotalCost = ({ countBySku, className }: Props) => {
	const totalCost = useAppSelector(selectBundlePriceTotalBySkuCount(countBySku ?? {}));

	return (
		<Grid container className={!className ? styles.totalCostContainer : className}>
			<Grid container className={styles.priceContainer}>
				<Typography variant="subtitle1">{formatCostString("", totalCost, ",-")}</Typography>
				<Typography variant="subtitle1">eks.mva</Typography>
			</Grid>
		</Grid>
	);
};
