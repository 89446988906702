import {
	Autocomplete,
	Checkbox,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@mui/material";

import styles from "./SelectLicensesAndGroups.module.scss";
import { useMemo, useState } from "react";
import {
	LicenseAssignmentAndPurchase,
	LicenseAssignmentConflict,
	LicenseAutoCompleteType,
	LicenseGroup,
} from "types";
import { CheckBox, CheckBoxOutlineBlank, WarningOutlined } from "@mui/icons-material";
import { SelectedItemChip } from "../SelectedItemChip";
import { useAppSelector } from "hooks/hooks";
import {
	licenseGroupsSelectors,
	licenseSubscriptionsSelectors,
	selectLicenseGroups,
	selectLicenseSubscriptions,
	subscribedSkusSelectors,
} from "features/licenses/licenses";
import clsx from "clsx";
import { Dictionary } from "@reduxjs/toolkit";
import { LicenseAppliesTo, LicenseAssignmentType } from "utilities/constants/enums";
import { usersSelectors } from "features/users";
import { HoverTooltip } from "components/Common/Tooltips";
import { getPotentialConflicts } from "utilities/licenseUtils/licenseAssignmentUtils";

interface SelectLicensesAndGroupsProps {
	options: LicenseAutoCompleteType[];
	selectedValues: LicenseAutoCompleteType[];
	purchaseAndAssignments: LicenseAssignmentAndPurchase;
	handleToggleSelectLicense: (type?: string, value?: string) => void;
}

export const SelectLicensesAndGroups = ({
	options,
	selectedValues,
	purchaseAndAssignments,
	handleToggleSelectLicense,
}: SelectLicensesAndGroupsProps) => {
	const subscribedSkus = useAppSelector(subscribedSkusSelectors.selectEntities);
	const licenseGroups = useAppSelector(licenseGroupsSelectors.selectEntities);
	const [searchValue, setSearchValue] = useState("");

	const onHandleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	// Selected items is a combination of:
	// - selected groups
	// - selected licenses
	// - licenses selected through groups
	const selectedLicensesAndGroups = useMemo(() => {
		const selectedGroups = selectedValues.filter(
			(value) => value.type === LicenseAssignmentType.Group,
		);
		const selectedLicenses = selectedValues.filter(
			(value) => value.type === LicenseAssignmentType.Direct,
		);
		const licensesSelectedThroughGroups = selectedValues
			.filter((value) => value.type === LicenseAssignmentType.Group)
			.flatMap(
				(value) =>
					licenseGroups[value.id]?.licenses.map((license) => ({
						type: LicenseAssignmentType.Direct,
						assignedBy: licenseGroups[value.id]?.groupName,
						id: license.skuId,
						name: license.displayName,
					})) ?? [],
			);
		return [...selectedGroups, ...selectedLicenses, ...licensesSelectedThroughGroups];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValues]);

	const { isLoading: isLicenseGroupsLoading } = useAppSelector(selectLicenseGroups);
	const { isLoading: isLicenseSubscriptionsLoading } = useAppSelector(selectLicenseSubscriptions);

	const shownOptions = useMemo(() => {
		const shownGroups = isLicenseGroupsLoading
			? [{ id: "loading", name: "Loading...", type: LicenseAssignmentType.Group }]
			: options.filter(
					(option) =>
						option.type === LicenseAssignmentType.Group &&
						option.name.toLowerCase().includes(searchValue.toLowerCase()),
			  );
		const shownLicenses = isLicenseSubscriptionsLoading
			? [{ id: "loading", name: "Loading...", type: LicenseAssignmentType.Direct }]
			: options.filter(
					(option) =>
						option.type === LicenseAssignmentType.Direct &&
						option.name.toLowerCase().includes(searchValue.toLowerCase()) &&
						subscribedSkus[option.id]?.appliesTo?.toUpperCase() ===
							LicenseAppliesTo.User,
			  );
		return [...shownGroups, ...shownLicenses];
	}, [
		options,
		searchValue,
		isLicenseGroupsLoading,
		isLicenseSubscriptionsLoading,
		subscribedSkus,
	]);

	return (
		<Grid container item xs={11} className={styles.selectLicenseContainer}>
			<Typography variant="body1" mb={1}>
				Select licenses / groups
			</Typography>
			<Autocomplete
				data-testid="autocomplete-select-license-and-group"
				id="autocomplete-select-license-and-group"
				multiple
				limitTags={0}
				groupBy={(option) => {
					if (option.type === LicenseAssignmentType.Group) {
						return "License Groups";
					} else {
						return "Licenses";
					}
				}}
				value={selectedLicensesAndGroups}
				options={shownOptions}
				disableCloseOnSelect
				isOptionEqualToValue={(option, value) => option.id === value.id}
				fullWidth
				onChange={(event, newValue) => {
					handleToggleSelectLicense();
					setSearchValue("");
				}}
				renderTags={() => []} // Hide the default tags, since we are rendering our own below
				getOptionLabel={(option) => option.name}
				renderOption={(props, option, { selected }) => {
					const licenseIsSelectedThroughGroup =
						option.type === LicenseAssignmentType.Direct &&
						Object.values(licenseGroups).some(
							(group) =>
								group?.licenses.some((license) => license.skuId === option.id) &&
								selectedLicensesAndGroups.some(
									(selected) => selected.id === group.groupID,
								),
						);

					// Show loading only if the option is the same type as the loading state
					if (
						(isLicenseGroupsLoading && option.type === LicenseAssignmentType.Group) ||
						(isLicenseSubscriptionsLoading &&
							option.type === LicenseAssignmentType.Direct)
					) {
						return <LoadingOption key={Math.random().toString(16).substring(2, 10)} />;
					}

					return (
						<DropdownOption
							key={`${option.id}-${option.type}`}
							option={option}
							selected={selected}
							handleToggleSelectLicense={handleToggleSelectLicense}
							setSearchValue={setSearchValue}
							licenseIsSelectedThroughGroup={licenseIsSelectedThroughGroup}
							licenseGroups={licenseGroups}
						/>
					);
				}}
				renderInput={(params) => (
					<TextField
						data-testid="autocomplete.input"
						{...params}
						variant="outlined"
						size="small"
						placeholder={"Search for license / group"}
						onChange={onHandleSearch}
						className={styles.textField}
						InputProps={{
							style: {
								fontSize: 15,
								minHeight: 50,
							},
							...params.InputProps,
						}}
					/>
				)}
			/>
			<Typography variant="caption" mt={1}>
				Selected licenses / groups
			</Typography>
			<Grid container className={styles.selectedSection}>
				{selectedValues.length > 0 ? (
					<Grid container className={styles.selected}>
						{selectedValues.map((selected) => (
							<SelectedItemChip
								key={selected.id}
								text={selected.name}
								id={selected.id}
								deselect={() =>
									handleToggleSelectLicense(selected.type, selected.id)
								}
								type={selected.type}
							/>
						))}
					</Grid>
				) : (
					<Grid container className={styles.noneSelected}>
						<Typography>No licenses selected</Typography>
					</Grid>
				)}
			</Grid>
			<ConflictingLicenseAssigments purchaseAndAssignments={purchaseAndAssignments} />
		</Grid>
	);
};

interface DropdownOptionProps {
	option: LicenseAutoCompleteType;
	selected: boolean;
	handleToggleSelectLicense: (type?: string, value?: string) => void;
	setSearchValue: (value: string) => void;
	licenseIsSelectedThroughGroup: boolean;
	licenseGroups: Dictionary<LicenseGroup>;
}

const DropdownOption = ({
	option,
	selected,
	handleToggleSelectLicense,
	setSearchValue,
	licenseIsSelectedThroughGroup,
	licenseGroups,
}: DropdownOptionProps) => {
	const getLicenseInGroup = (groupID: string) => {
		const licenses = licenseGroups[groupID]?.licenses ?? [];
		return {
			names: licenses.map((license) => license.displayName).join(", "),
			ids: licenses.map((license) => license.skuId),
		};
	};

	let names = "";
	if (option.type === LicenseAssignmentType.Group) {
		names = getLicenseInGroup(option.id).names;
	}
	return (
		<Grid
			container
			data-testid="dropdown.option"
			onClick={() => {
				setSearchValue("");
				handleToggleSelectLicense(option.type, option.id);
			}}
			className={clsx(styles.dropDownOption, {
				[styles.disabled]: licenseIsSelectedThroughGroup,
			})}
		>
			{option.type === LicenseAssignmentType.Direct ? (
				<div className={styles.dropDownItem}>
					<Checkbox
						icon={
							<CheckBoxOutlineBlank className={styles.unchecked} fontSize="small" />
						}
						checkedIcon={<CheckBox className={styles.checked} fontSize="small" />}
						checked={selected}
						disabled={licenseIsSelectedThroughGroup}
					/>
					<Typography variant="caption">{option.name}</Typography>
				</div>
			) : (
				<Grid container className={styles.groupDropdownItem}>
					<Checkbox
						icon={
							<CheckBoxOutlineBlank className={styles.unchecked} fontSize="small" />
						}
						checkedIcon={<CheckBox className={styles.checked} fontSize="small" />}
						checked={selected}
					/>
					<Grid container className={styles.groupDropdownItemText}>
						<Typography variant="caption">{option.name}</Typography>
						<Typography variant="caption" className={styles.licensesInGroupText}>
							{names}
						</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

const LoadingOption = () => {
	return (
		<Grid container className={styles.loading}>
			<CircularProgress size={20} />
		</Grid>
	);
};

const ConflictingLicenseAssigments = ({
	purchaseAndAssignments,
}: {
	purchaseAndAssignments: LicenseAssignmentAndPurchase;
}) => {
	const users = useAppSelector(usersSelectors.selectEntities);
	const licenses = useAppSelector(licenseSubscriptionsSelectors.selectEntities);
	const groups = useAppSelector(licenseGroupsSelectors.selectEntities);
	const allLicensesByGroup = useMemo(() => {
		const allLicensesByGroup: { [key: string]: string[] } = {};
		Object.values(groups).forEach((group) => {
			if (!group) return;
			const licensesInGroup = group?.licenses.map((license) => license.skuId) ?? [];
			allLicensesByGroup[group.groupID] = licensesInGroup;
		});
		return allLicensesByGroup;
	}, [groups]);

	const conflicts = useMemo(() => {
		const potentialConflicts = getPotentialConflicts(
			purchaseAndAssignments,
			users,
			licenses,
			allLicensesByGroup,
		);
		return potentialConflicts;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purchaseAndAssignments, users, licenses]) as LicenseAssignmentConflict[];

	const anyConflicts = conflicts.length > 0;

	return anyConflicts ? (
		<HoverTooltip
			title={""}
			description={
				<Grid item>
					<Typography variant="caption" className={styles.tooltiptext}>
						Assigning selected licenses to certain users might cause conflicts with
						other licenses already assigned to the same user.
					</Typography>
					{conflicts.map(({ userId, skuIds }) => (
						<Grid item mt={1}>
							<Typography
								variant="caption"
								className={styles.tooltiptext}
								fontWeight={500}
							>
								{users[userId]?.displayName}
							</Typography>
							<ul>
								{skuIds.map((conflictingSkuId) => (
									<li key={conflictingSkuId}>
										<Typography
											variant="caption"
											className={styles.tooltiptext}
										>
											{licenses[conflictingSkuId]?.friendlyName}
										</Typography>
									</li>
								))}
							</ul>
						</Grid>
					))}
				</Grid>
			}
		>
			<Grid container className={styles.conflictingLicenseAssigments}>
				<WarningOutlined className={styles.warningIcon} />
				<Typography variant="caption">Possible license assignment conflicts</Typography>
			</Grid>
		</HoverTooltip>
	) : (
		<></>
	);
};
