import {
	Alert,
	Button,
	Collapse,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Skeleton,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "./Reports.module.scss";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import remarkEmoji from "remark-emoji";
import _ from "lodash";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { REPORT_TYPES } from "utilities/constants/constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Doughnut } from "react-chartjs-2";
import { LogFileTable } from "./LogFileTable/LogFileTable";
import clsx from "clsx";
import { useReactToPrint } from "react-to-print";
import { SecondaryButton } from "components/Common/Buttons/Button";
import PrintIcon from "@mui/icons-material/Print";
import { ExpandableTableContainer } from "components/Reports/ExpandableTableContainer";
import { SortableTable } from "components/Reports/SortableTable";
import colors from "styles/utilities/_colors.scss";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { createTicket } from "actions/customerActions";
import { usersSelectors } from "features/users";
import { reportSelectors, selectIdentityReportFileNames } from "features/reports";
import { fetchIdentityReportFileNames, fetchReport } from "actions/reportActions";
import { extractAndFormatDate } from "utilities/features/reports";
import { LegendItem } from "components/Common/LegendItem";

const handleIdForTableOfContents = (props) => {
	const {
		children: [label],
	} = props;

	return _.kebabCase(label.toLowerCase().replace(/[^a-zæøå0-9 ]/g, ""));
};

const Reports = ({ reportType }) => {
	const {
		auth: {
			account: { localAccountId },
		},
	} = useAuth();

	const { mail, userPrincipalName } =
		useAppSelector((state) => usersSelectors.selectById(state, localAccountId)) ?? {};

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const isIdentityReport = reportType === REPORT_TYPES.IDENTITY_REPORT;

	const { dispatch, auth } = useAuth();

	const identityReportFileNames = useAppSelector(selectIdentityReportFileNames);

	useApiOnce(fetchIdentityReportFileNames, {
		isLoading: !identityReportFileNames.length,
	});

	const [reportFileName, setReportFileName] = useState();
	const [reportFileNames, setReportFileNames] = useState([]);

	const report = useAppSelector((state) => reportSelectors.selectById(state, reportFileName)) ?? {
		data: "",
		isLoading: true,
	};

	useEffect(() => {
		if (reportFileName === undefined || !report.isLoading) return;

		dispatch(fetchReport({ auth, folderPath: reportFileName }));
	}, [dispatch, auth, reportFileName, report.isLoading]);

	useEffect(() => {
		const reportFileNames = identityReportFileNames;
		setReportFileName(_.head(reportFileNames));
		setReportFileNames(reportFileNames);
	}, [reportFileNames, reportType, identityReportFileNames]);

	const createSupportTicket = async () =>
		await dispatch(
			createTicket({
				auth,
				body: {
					name: "Walkthrough request",
					description: `A customer has requested a ${
						isIdentityReport ? "identity report" : "device report"
					} walkthrough`,
					subject: "Walkthrough request",
					category: "Other",
					type: "Incident",
					priority: 2,
					status: 2,
					email: mail || userPrincipalName,
					tags: ["ConvertToSR"],
					source: "1001",
					agreement: "Platform",
				},
			}),
		);

	const handleWalkthroughRequest = async () => {
		setIsSubmitting(true);
		await createSupportTicket();
		setIsSubmitting(false);
		setShowConfirmDialog(false);
		setOpenSnackbar(true);
	};

	function CollapsedList({ label, text }) {
		const [open, setOpen] = useState(false);

		const handleClick = () => {
			setOpen(!open);
		};

		const noErrorText = text === "No errors" ? text : "";

		const hasNoErrors = !noErrorText;

		return (
			<Grid item classes={{ root: styles[hasNoErrors ? "dropdown" : "disabledDropdown"] }}>
				<ListItemButton disabled={text === "No errors"} onClick={handleClick}>
					<Grid container>
						<Grid item xs={3}>
							<ListItemText primary={<strong>{label}</strong>} />
						</Grid>
						<Grid item xs={1}>
							<Typography variant="caption">{noErrorText}</Typography>
						</Grid>
						{hasNoErrors && (
							<Grid
								container
								justifyContent="flex-end"
								alignItems="center"
								item
								xs={8}
							>
								View
								{open ? <ExpandLess /> : <ExpandMore />}
							</Grid>
						)}
					</Grid>
				</ListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem sx={{ pl: 4 }}>
							<ListItemText primary={`- ${text}`} />
						</ListItem>
					</List>
				</Collapse>
			</Grid>
		);
	}

	/* Build an object containing unordered list labels with their corresponding values
	 *
	 * ------------- Example -----------------
	 *
	 * Parsing the following markdown:
	 *
	 *  Errormessages:
	 *  - Adobe Reader DC 21.1792
	 *      - Access is denied.
	 *  - EastCoast Check-in
	 *      - An app update is available. Available apps can be updated using Company Portal and required apps will auto-update on device sync.
	 *  - Epson label printer
	 *      - The system cannot find the path specified.
	 *  - FileZilla 3.48.0
	 *      - The application was not detected after installation completed successfully
	 *  - Exerp Kiosk System
	 *  - FortinetClient VPN
	 *  - Google Chrome vLatest x64
	 *
	 * ------------- Returns -----------------
	 *
	 *  {
	 *    "Adobe Reader DC 21.1792": "Access is denied.",
	 *    "EastCoast Check-in": "An app update is available. Available apps can be updated using Company Portal and required apps will auto-update on device sync.",
	 *    "Epson label printer": "The system cannot find the path specified.",
	 *    "FileZilla 3.48.0": "The application was not detected after installation completed successfully",
	 *    "Exerp Kiosk System": "No errors",
	 *    "FortinetClient VPN": "No errors",
	 *    "Google Chrome vLatest x64": "No errors"
	 *  }
	 *
	 * ----------------------------------------
	 * */
	const getCollapsedListValuesByLabels = ({ children: nestedChildren }) => {
		const items = nestedChildren
			.filter(({ tagName }) => tagName === "li")
			.flatMap(({ children }) => children)
			.filter(({ type, value, tagName }) => (type === "text" && value !== "\n") || tagName);

		let i;
		let current;
		let next;

		const values = {};

		for (i = 0; i < items.length - 1; i++) {
			current = items[i];
			next = items[i + 1];

			if (current.type === "element") continue;

			values[current.value] =
				current.type !== next.type
					? next?.children.find(({ tagName }) => tagName)?.children[0].value
					: "No errors";
		}

		if (next?.type === "text") values[next.value] = "No errors";

		return values;
	};

	function CollapsedListContainer({ children, node }) {
		const values = getCollapsedListValuesByLabels(node);

		/*
		 * We define our collapsed lists to be lists with nested children
		 *
		 * Example with nested children (produces collapsed list):
		 * - Executive Summary
		 *   - Striving to provide value in our products and services, Ironstone intends
		 *
		 * Example without nested children (normal list):
		 *
		 * - Created: 23.11.2022 14:50:06
		 * - Company Name: Ironstone Norway Test Company
		 * */
		const isNormalListSection = Object.values(values).includes(undefined);

		const isSelfServicePasswordResetSection = Object.keys(values).some((label) =>
			label.includes("SSPR"),
		);

		return _.isEmpty(values) || isNormalListSection || isSelfServicePasswordResetSection ? (
			<ul>{children}</ul> // Ensures that lists will work as usual if not the ones we want to map
		) : (
			<Grid item classes={{ root: styles.dropdownWrapper }}>
				<List component="nav">
					{Object.entries(values).map(([label, text]) => (
						<CollapsedList key={label} label={label} text={text} />
					))}
				</List>
			</Grid>
		);
	}

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: `
      @page {
        margin: 40px;
      }

      @recommended {
        width: 1100px;
      }
    `,
	});

	if (report.isLoading)
		return (
			<Grid pl={4} pt={0.25} mt={3} className={styles.loadingSkeletonWrapper}>
				<Grid container className={styles.loadingSkeletonContainer}>
					<Grid my={2}>
						<Skeleton variant="rectangular" width={1285} height={85} />
					</Grid>
					<Typography variant="h2" my={2}>
						Version History
					</Typography>
					<Divider />
					<Grid container direction="column" my={2}>
						{Array.from(Array(3)).map((_, i) => (
							<Grid key={i} container my={1}>
								<Grid item xs={0.3} pr={4}>
									<Skeleton variant="circular" width={20} height={20} />
								</Grid>
								<Grid item xs={10} container direction="column">
									<Skeleton variant="text" width={300} height={20} />
								</Grid>
							</Grid>
						))}
					</Grid>
					<Divider />
					<Typography variant="h2" my={2}>
						Response from Ironstone TAM/Expert
					</Typography>
					<Skeleton
						variant="rectangular"
						width={1000}
						height={isIdentityReport ? 250 : 640}
					/>
					{isIdentityReport &&
						Array.from(Array(4)).map((_, i) => (
							<Grid key={i} my={2}>
								<Skeleton variant="rectangular" width={750} height={50} />
							</Grid>
						))}
				</Grid>
			</Grid>
		);

	return (
		<Grid container classes={{ root: styles.wrapper }}>
			<Grid container className={styles.reportContainer}>
				<Snackbar
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={() => setOpenSnackbar(false)}
				>
					<Alert elevation={6} variant="filled" severity={"success"}>
						Walkthrough request successfully submitted!
					</Alert>
				</Snackbar>
				{showConfirmDialog && (
					<PrimaryDialog
						title="Request walkthrough?"
						size="small"
						primaryButtonText="Yes, request walkthrough"
						secondaryButtonText="Cancel"
						isLoading={isSubmitting}
						onLeaveAction={() => setShowConfirmDialog(false)}
						primaryAction={handleWalkthroughRequest}
					/>
				)}
				<div ref={componentRef} className={styles.reportWrapper}>
					<ReactMarkdown
						className="line-break contain-width"
						children={report.data}
						remarkPlugins={[
							remarkGfm,
							remarkDirective,
							remarkDirectiveRehype,
							remarkEmoji,
						]}
						components={{
							ul: CollapsedListContainer,
							code: (props) => {
								const [dataString] = props.children;

								const separateTitleFromJson = dataString.indexOf("{");

								const title = dataString.substring(0, separateTitleFromJson);
								const json = JSON.parse(
									dataString.substring(separateTitleFromJson),
								);

								const palette = [
									colors.blueDark,
									colors.blueTint6,
									colors.yellowPrimary,
									colors.yellowTint4,
								];

								const backgroundColor = [];
								const labels = [];
								const data = [];

								let idx = 0;
								for (const [label, value] of Object.entries(json)) {
									labels.push(label);
									data.push(value);
									backgroundColor.push(palette[idx++]);
								}

								const total = _.sum(data);

								return (
									<Grid container item classes={{ root: styles.chartWrapper }}>
										<Grid item className={styles.doughnut}>
											<Doughnut
												options={{
													animation: {
														duration: 0,
													},
													plugins: {
														legend: {
															display: false,
														},
													},
												}}
												data={{
													labels,
													datasets: [
														{
															backgroundColor,
															data,
														},
													],
												}}
											/>
										</Grid>
										<Divider
											orientation="vertical"
											flexItem
											className={styles.divider}
										/>
										<Grid
											container
											direction="column"
											item
											rowSpacing={2}
											pl={2}
											pt={2}
											className={styles.labels}
										>
											<Grid>
												<Typography variant="h2">{title}</Typography>
											</Grid>
											{_.zip(labels, backgroundColor, data).map(
												([label, color, value]) => (
													<Grid
														container
														className={styles.label}
														key={label}
													>
														<LegendItem
															mainLabel={label}
															subLabel={`${value}  (${Math.round(
																(value / total) * 100,
															)}%)`}
															color={color}
															noPadding
															round
														/>
													</Grid>
												),
											)}
										</Grid>
									</Grid>
								);
							},

							section: ({ children, id }) => {
								if (id !== "recommendations") return <>{children}</>;

								return (
									<Grid
										mt={2}
										pt={2}
										px={4}
										pb={4}
										classes={{ root: styles.recommended }}
										container
										direction="column"
										item
									>
										{children}
										{children.length === 1 && (
											<Grid mt={2}>
												<Typography variant="body1">
													This is an autogenerated report. <br />
													Click the button below if you want us to walk
													you through it and give recommendations on how
													to secure your environment.
												</Typography>
											</Grid>
										)}
										<Button
											variant="outlined"
											color="primary"
											className={styles.button}
											onClick={() => setShowConfirmDialog(true)}
										>
											<p>Request walkthrough with Ironstone</p>
										</Button>
									</Grid>
								);
							},
							table: (props) => {
								const [
									{
										props: {
											children: [
												{
													props: { children: headerChildren },
												},
											],
										},
									},
									{
										props: { children: rowChildren },
									},
								] = props.children;

								const headers = headerChildren.flatMap(
									({ props: { children } }) => children,
								);

								const rows = rowChildren.map(({ props: { children } }) => {
									return children?.reduce(
										(acc, { props: { children: values = [] } }, idx) => {
											const header = headers[idx];

											let element = values;
											let sortValue = values;

											if (Array.isArray(values)) {
												sortValue = values[0];
												const prettyValues = values.map((value) => {
													if (typeof value === "object") {
														return value.props.children;
													} else {
														return value;
													}
												});

												element = prettyValues.join(", ");
											}

											return {
												...acc,
												[header]: {
													element,
													sortValue,
												},
											};
										},
										{},
									);
								});

								return (
									<>
										{rowChildren.length === 1 ? (
											<Grid className={styles.tableWrapper}>
												<Table
													classes={{
														root: clsx(styles.table, styles.border),
													}}
													{...props}
												/>
											</Grid>
										) : rowChildren.length < 10 ? (
											<Grid className={styles.tableWrapper}>
												<SortableTable
													classes={{
														root: clsx(styles.table, styles.border),
													}}
													{...{ headers, rows }}
												/>
											</Grid>
										) : (
											<Grid className={styles.tableTopSpacing}>
												<ExpandableTableContainer>
													<Grid className={styles.expandableTableWrapper}>
														<SortableTable
															classes={{
																root: clsx(
																	styles.table,
																	styles.border,
																),
															}}
															{...{ headers, rows }}
														/>
													</Grid>
												</ExpandableTableContainer>
											</Grid>
										)}
									</>
								);
							},
							h1: (props) => (
								<Grid container mb={2} classes={{ root: styles.banner }}>
									<Grid item>
										<Typography variant="h1" {...props} />
									</Grid>
									<Grid item container className={styles.dropdownContainer}>
										<Select
											size="small"
											id="report-select"
											value={reportFileName}
											onChange={({ target: { value } }) => {
												setReportFileName(value);
											}}
											classes={{ select: styles.picker }}
										>
											{reportFileNames.map((fileName) => (
												<MenuItem key={fileName} value={fileName}>
													{extractAndFormatDate(fileName)}
												</MenuItem>
											))}
										</Select>
										<Grid>
											<SecondaryButton
												text={
													<Grid container alignItems="center">
														<Grid item pt={0.5} mr={1}>
															<PrintIcon />
														</Grid>
														{"Print report"}
													</Grid>
												}
												size="medium"
												isDisabled={isSubmitting}
												action={handlePrint}
												variantStyle="outlined"
											/>
										</Grid>
									</Grid>
								</Grid>
							),
							h2: (props) => (
								<Grid container direction="column" my={2}>
									<Typography
										id={handleIdForTableOfContents(props)}
										variant="h2"
										{...props}
									/>
									<Divider />
								</Grid>
							),
							h3: (props) => (
								<Typography
									mt={2}
									id={handleIdForTableOfContents(props)}
									variant="h3"
									{...props}
								/>
							),
							h4: (props) => (
								<Typography
									mt={2}
									id={handleIdForTableOfContents(props)}
									variant="h4"
									{...props}
								/>
							),
							h5: (props) => (
								<Typography
									mt={2}
									id={handleIdForTableOfContents(props)}
									variant="h5"
									{...props}
								/>
							),
							h6: LogFileTable,
							tfoot: (props) => <TableFooter {...props} />,
							thead: (props) => <TableHead {...props} />,
							tbody: (props) => <TableBody {...props} />,
							th: ({ isHeader, ...props }) => <TableCell {...props} />,
							td: ({ isHeader, ...props }) => <TableCell {...props} />,
							tr: ({ isHeader, ...props }) => <TableRow {...props} />,
							p: (props) => {
								if (props.children.find(({ key }) => key?.startsWith("em"))) {
									return <Grid {...props} />; // Do not nest "Risk Level" divs inside <p></p>
								}
								return <Typography variant="body1" {...props} />;
							},
							em: ({ children: [value] }) => {
								if (value.startsWith("Risk Level")) {
									return (
										<Grid
											mb={2}
											container
											justifyContent="center"
											alignItems="center"
											classes={{
												root: clsx(styles.risk, {
													[styles.highRisk]: value.endsWith("High"),
													[styles.mediumRisk]: value.endsWith("Medium"),
												}),
											}}
										>
											{value}
										</Grid>
									);
								}
								return <em>{value}</em>;
							},
						}}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export { Reports };
