import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { MyPeopleSearch } from "./MyPeopleSearch/MyPeopleSearch";
import { MyPeopleTable } from "./MyPeopleTable";
import { AddEmployeeDialog } from "./AddEmployeeDialog/AddEmployeeDialog";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import {
	useApiOnce,
	useApiOnceWithParams,
	useAppDispatch,
	useAppSelector,
	useFetchLicensePrices,
} from "hooks/hooks";
import { fetchMailDomains } from "actions/mailDomainActions";
import { fetchDepartments } from "actions/departmentActions";
import { fetchUsers } from "actions/userActions";
import { fetchLicenseGroups, fetchSubscribedSkus } from "actions/licenseActions";
import {
	selectUserFilters,
	selectUsers,
	setUserActivityFilter,
	setUserTypeFilter,
	usersSelectors,
} from "features/users";
import { selectMailDomains } from "features/mailDomains";
import { selectDepartments } from "features/departments";
import { clearCartItems } from "features/hardware/products";
import { selectLicenseGroups, selectSubscribedSkus } from "features/licenses/licenses";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { fetchLicenseNames } from "actions/licenseNameActions";
import { selectlicenseNames } from "features/licenses/licenseNames";
import { UserActivityType, UserType } from "utilities/constants/enums";
import { selectUserDrawerState } from "features/user";
import { ListFilter } from "../Common/ListFilter";
import clsx from "clsx";
import { fetchCustomerCountries } from "actions/customerActions";
import { selectCustomerCountries } from "features/customer";
import { Download } from "@mui/icons-material";

import styles from "./MyPeople.module.scss";
import useRole from "utilities/roleUtils/roleCheck";
import { csvDownload } from "components/Common/DownloadableLink";
import dayjs from "dayjs";

const MyPeople = () => {
	const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);
	const { isActionRole } = useRole();

	const { isLoading: userStateIsLoading } = useAppSelector(selectUsers);
	const allUserTypes = [UserType.Member, UserType.ExternalMember, UserType.Guest];
	const allUserActivityTypes = [
		UserActivityType.Active,
		UserActivityType.Inactive,
		UserActivityType.NeverSignedIn,
		UserActivityType.AccountDisabled,
		UserActivityType.NewUser,
	];
	const userFilters = useAppSelector(selectUserFilters);

	useApiOnceWithParams(fetchUsers, useAppSelector(selectUsers), { noExternalUsers: true });
	useApiOnce(fetchMailDomains, useAppSelector(selectMailDomains));
	useApiOnce(fetchDepartments, useAppSelector(selectDepartments));
	useApiOnce(fetchLicenseGroups, useAppSelector(selectLicenseGroups));
	useApiOnce(fetchSubscribedSkus, useAppSelector(selectSubscribedSkus));
	useApiOnce(fetchLicenseNames, useAppSelector(selectlicenseNames));
	useApiOnce(fetchCustomerCountries, useAppSelector(selectCustomerCountries));
	const dispatch = useAppDispatch();
	useFetchLicensePrices();

	const allUsers = useAppSelector(usersSelectors.selectAll);

	const [searchValue, setSearchValue] = useState("");

	const { isDrawerOpen } = useAppSelector(selectUserDrawerState);

	useEffect(() => {
		// Hide Userback widget when drawer is open
		try {
			isDrawerOpen ? window?.Userback?.hide() : window?.Userback?.show();
		} catch (error) {}
	}, [isDrawerOpen]);

	const onResetFilters = () => {
		dispatch(setUserTypeFilter([]));
		dispatch(setUserActivityFilter([]));
	};

	useEffect(() => {
		return () => {
			onResetFilters();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const headerRef = useRef<HTMLDivElement>(null);
	const [shadow, setShadow] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			if (!headerRef.current) return;
			const topPosition = headerRef.current.getBoundingClientRect().top;
			setShadow(topPosition <= 0);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleDownloadAllUsers = () => {
		csvDownload(dispatch, allUsers, `Users: ${dayjs().format("YYYY-MM-DD")}.csv`, ";", true);
	};

	const showTableFooter =
		userFilters.userTypeFilter.length > 0 ||
		userFilters.userActivityFilter.length > 0 ||
		searchValue.length > 0;

	return (
		<Grid container className={clsx(styles.wrapper, { [styles.extraMargin]: showTableFooter })}>
			<Grid container alignItems="center">
				<Grid
					ref={headerRef}
					container
					item
					className={clsx({ [styles.toolbarShadow]: shadow }, styles.topSearchBar)}
				>
					<Grid container gap={2}>
						<Grid item className={styles.searchFieldContainer}>
							<MyPeopleSearch
								searchValue={searchValue}
								setSearchValue={setSearchValue}
							/>
						</Grid>

						<Grid item className={styles.filtersAndButtonContainer}>
							<Grid item className={styles.filters}>
								<ListFilter
									id="userTypeSelector-select"
									filterValues={userFilters.userTypeFilter}
									filterOptions={allUserTypes}
									updateFilter={(value) => dispatch(setUserTypeFilter(value))}
									noneSelectedLabel="All User Types"
								/>
								<ListFilter
									id="userActivityStateSelector-select"
									filterValues={userFilters.userActivityFilter}
									filterOptions={allUserActivityTypes}
									updateFilter={(value) => dispatch(setUserActivityFilter(value))}
									noneSelectedLabel="All User Activity"
								/>
							</Grid>
							<Grid item className={styles.buttons}>
								<SecondaryButton
									text="Export all"
									size="small"
									variantStyle="outlined"
									action={handleDownloadAllUsers}
									icon={<Download className={styles.downloadIcon} />}
									className={styles.exportButton}
								/>
								<PrimaryButton
									text="Add employee"
									icon={<PersonAddIcon />}
									size="small"
									variantStyle="contained"
									action={() => setIsAddDialogOpened(true)}
									isDisabled={userStateIsLoading || !isActionRole}
									disabledDescription={
										userStateIsLoading
											? ""
											: "You do not have permission to add employees"
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} className={styles.tableWrapper}>
					<MyPeopleTable
						userTypeFilter={userFilters.userTypeFilter}
						userActivityFilter={userFilters.userActivityFilter}
						onResetFilters={onResetFilters}
						shouldShowTableFooter={showTableFooter}
					/>
				</Grid>

				{isAddDialogOpened && (
					<AddEmployeeDialog
						onClose={() => {
							setIsAddDialogOpened(false);
							dispatch(clearCartItems());
						}}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export { MyPeople };
