import config from "../authConfig";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "../theme";
import { LoginView } from "./LoginView";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedContent } from "./AuthenticatedContent";
import { store } from "store";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";

/**
 * Main Application Entry Point
 */
const App = () => {
	const msalInstance = new PublicClientApplication(config.MS_AL_CONFIG);
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<MsalProvider instance={msalInstance}>
					<Provider store={store}>
						<UnauthenticatedTemplate>
							<LoginView allowedTenants={new config.AuthConfig().ALLOWED_TENANTS} />
						</UnauthenticatedTemplate>
						<AuthenticatedTemplate>
							<AuthenticatedContent />
						</AuthenticatedTemplate>
					</Provider>
				</MsalProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
