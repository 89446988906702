import { Divider, Grid, Typography } from "@mui/material";
import type { ReactElement } from "react";
import { Loaders, NewEmployeeFormFields } from "types";
import { SummaryCart } from "../Summary/SummaryCart";
import styles from "./Receipt.module.scss";
import ErrorIcon from "@mui/icons-material/Error";
import { DeliveryOption, OnboardingStatus } from "utilities/constants/enums";
import { DELIVERY_TIME } from "utilities/constants/constants";

export interface ReceiptProps {
	loaders: Loaders;
	newEmployeeData: NewEmployeeFormFields;
}

export function Receipt({
	loaders,
	newEmployeeData: {
		givenName,
		surname,
		mailPrefix,
		mailSuffix,
		privateEmail,
		streetAddress,
		department,
		country,
		mobilePhone,
		city,
		postalCode,
		chosenLicenses,
		deliveryOption,
	},
}: ReceiptProps): ReactElement {
	return (
		<Grid container mt={18}>
			<Grid
				item
				xs={12}
				container
				direction="column"
				alignItems="center"
				classes={{ root: styles.receiptHeader }}
				mb={2}
			>
				<Typography variant="h1" mb={1}>
					Onboarding complete!
				</Typography>
				{privateEmail && ( // If no private email is given, the user will not receive an email with the details
					<Typography variant="body1">
						{givenName} {surname} will receive a confirmation email with all the details
					</Typography>
				)}
			</Grid>
			<Grid container justifyContent={"center"}>
				<Grid item width={500} mt={1} mb={2}>
					{Object.values(loaders)
						.filter(({ status }) => status === OnboardingStatus.Failed)
						.map(({ error, suggestion }) => (
							<Grid container classes={{ root: styles.errorContainer }}>
								<Grid container p={1}>
									<Grid item xs={0.75}>
										<ErrorIcon color="error" />
									</Grid>
									<Grid item xs={11.25} container direction="column">
										<Typography variant="body1" color="error">
											{error}
										</Typography>
										<Typography variant="body1">
											The problem is on our end and we will look into it
										</Typography>
										<Typography variant="body1">{suggestion}</Typography>
										<Typography variant="body1">
											If the problem persists please contact us at:
											support@ironstoneit.com
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						))}
				</Grid>
			</Grid>
			<Grid container direction="row" justifyContent="space-around">
				<Grid item classes={{ root: styles.receiptUserContainer }}>
					<Typography variant="h2" p={2}>
						User Details
					</Typography>
					<Divider />
					<Grid p={2}>
						<Typography variant="body1" fontWeight={500} mb={1}>
							Shipping Address
						</Typography>
						<Typography variant="body1" display="inline">
							{deliveryOption === DeliveryOption.B2B ? "Office" : "Home"}
						</Typography>
						<Typography variant="description" display="inline" color="text.secondary">
							{" "}
							(
							{deliveryOption === DeliveryOption.B2B
								? DELIVERY_TIME.OFFICE
								: DELIVERY_TIME.HOME}
							)
						</Typography>
						<Typography variant="body1">{streetAddress}</Typography>
						<Typography variant="body1">
							{postalCode} {city}
						</Typography>
					</Grid>
					<Divider />
					<Grid p={2}>
						{Object.entries({
							"First Name": givenName,
							"Last Name": surname,
							"Created email": `${mailPrefix.toLowerCase()}@${mailSuffix}`,
							"Private email": privateEmail,
							Phone: mobilePhone,
							Department: department,
							Country: country,
						}).map(([title, name], key) => (
							<Grid container key={key}>
								<Typography
									variant="body1"
									fontWeight={500}
									classes={{ root: styles.onboardedText }}
								>
									{title}:
								</Typography>
								<Typography variant="body1">{name}</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction="column"
					classes={{ root: styles.receiptCartContainer }}
					mb={18}
				>
					<SummaryCart
						chosenLicenses={chosenLicenses}
						isOnboardingReceipt={true}
						shouldShowProducts={
							(loaders.order && loaders.order.status !== OnboardingStatus.Failed) ||
							false
						}
						shouldShowLicenses={
							chosenLicenses.length > 0 &&
							loaders.licenses &&
							loaders.licenses.status !== OnboardingStatus.Failed
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
