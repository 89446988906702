import { Dictionary, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import {
	fetchLicenseGroups,
	fetchLicenseSubscriptions,
	adjustLicenseSubscriptionQuantities,
	fetchSubscribedSkus,
	fetchLicenseSubscription,
	removeLicenses,
} from "actions/licenseActions";
import { RootState } from "store";
import {
	LicenseGroup,
	SubscriptionVariant,
	SubscriptionVariantGroup,
	SubscribedSku,
	LicenseAction,
	LicenseActionEffect,
	LicenseName,
	DirectAssignmentChange,
	GroupAssignmentChange,
} from "types";
import {
	LicenseActionStatus,
	LicenseAdjustmentDrawerType,
	TermDuration,
} from "utilities/constants/enums";
import {
	calculateLicenseActionEffects,
	selectLicenseActionsWithSkuGUIDKeys,
} from "../scheduledActions";
import { assignLicenses } from "actions/licenseActions";
import { licenseNameSelectors } from "./licenseNames";

export const licenseGroupsAdapter = createEntityAdapter<LicenseGroup>({
	selectId: (licenseGroup) => licenseGroup.groupID,
});

const subscribedSkusAdapter = createEntityAdapter<SubscribedSku>({
	selectId: (subscribedSku) => subscribedSku.skuId,
});

const licenseSubscriptionsAdapter = createEntityAdapter<SubscriptionVariantGroup>({
	selectId: (subscription) => subscription.provisioningId,
});

const licenseSlice = createSlice({
	name: "license",
	initialState: {
		groups: licenseGroupsAdapter.getInitialState({
			isLoading: true,
			isFetching: false,
		}),
		licenseSubscriptions: licenseSubscriptionsAdapter.getInitialState({
			isLoading: true,
			isFetching: false,
		}),
		subscribedSkus: subscribedSkusAdapter.getInitialState({
			isLoading: true,
			isFetching: false,
			isError: false,
		}),
		isLoadingLicensesForDetailedView: false,
		activeLicenseManagementId: "", // License currently being managed in the license management view
		licenseAssignmentErrorDialogIsOpen: false,
		licenseAdjustmentDrawerState: {
			isOpen: false,
			drawerType: LicenseAdjustmentDrawerType.BUY_LICENSES,
			showConfirmationPage: false,
		},
		licensedUsersDialog: {
			isOpen: false,
			licenseId: "",
		},
		licenseAssignmentState: {
			showLoadingDialog: false,
			showReceiptPage: false,
			preSelectedUserIdForAssignment: "",
		},
	},
	reducers: {
		setActiveLicenseManagementId: (state, { payload }) => {
			state.activeLicenseManagementId = payload;
		},
		setLicenseAdjustmentDrawerState: (state, { payload }) => {
			state.licenseAdjustmentDrawerState = {
				...state.licenseAdjustmentDrawerState,
				...payload,
			};
		},
		setLicensedUsersDialogState: (state, { payload }) => {
			state.licensedUsersDialog = {
				...state.licensedUsersDialog,
				...payload,
			};
		},
		setLicenseAssignmentState: (state, { payload }) => {
			state.licenseAssignmentState = {
				...state.licenseAssignmentState,
				...payload,
			};
		},
		setPreselectedUserIdForAssignment: (state, { payload }) => {
			state.licenseAssignmentState.preSelectedUserIdForAssignment = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLicenseGroups.pending, (state) => {
				state.groups.isFetching = true;
			})
			.addCase(fetchLicenseGroups.fulfilled, (state, { payload }) => {
				const currentState = licenseGroupsAdapter.getSelectors().selectAll(state.groups);
				const updated = payload
					.map((group) => {
						const currentGroup = currentState.find((g) => g.groupID === group.groupID);
						if (currentGroup) {
							return {
								...group,
								licenses: currentGroup.licenses.map((license) => {
									const currentLicense = group.licenses.find(
										(l) => l.skuId === license.skuId,
									);
									if (currentLicense) {
										return {
											...license,
											unusedLicenses: currentLicense.unusedLicenses,
											usedLicenses: currentLicense.usedLicenses,
										};
									}

									return license;
								}),
							};
						}
						return group;
					})
					.sort((a, b) => b.memberCount - a.memberCount);

				licenseGroupsAdapter.upsertMany(state.groups, updated);
				state.groups.isLoading = false;
				state.groups.isFetching = false;
			});
		builder
			.addCase(fetchLicenseSubscriptions.pending, (state) => {
				state.licenseSubscriptions.isFetching = true;
			})
			.addCase(fetchLicenseSubscriptions.fulfilled, (state, { payload }) => {
				const mapped = payload.map((subscription) => {
					const variantsWithCostIdentifier = subscription.variants.map(mapCostIdentifier);

					const preferredVariant = getPreferredVariant(subscription);

					return {
						...subscription,
						variants: variantsWithCostIdentifier,
						preferredVariantId: preferredVariant.subscriptionId,
					};
				});
				licenseSubscriptionsAdapter.setAll(state.licenseSubscriptions, mapped);
				state.licenseSubscriptions.isLoading = false;
				state.licenseSubscriptions.isFetching = false;
			});
		builder
			.addCase(fetchLicenseSubscription.pending, (state, { meta }) => {
				state.isLoadingLicensesForDetailedView = true;
			})
			.addCase(fetchLicenseSubscription.fulfilled, (state, { payload, meta }) => {
				const provisioningId = meta.arg.skuId;
				const subscription = state.licenseSubscriptions.entities[provisioningId];

				const allExceptCurrentVariant = subscription?.variants.filter(
					(variant) => variant.subscriptionId !== payload.subscriptionId,
				) as SubscriptionVariant[];

				const payloadAsSubscriptionVariant = {
					...payload,
					provisioningId,
				} as SubscriptionVariant;

				const newVariants = [...allExceptCurrentVariant, payloadAsSubscriptionVariant].map(
					mapCostIdentifier,
				);

				const mapped = {
					...subscription,
					variants: newVariants,
				} as SubscriptionVariantGroup;

				licenseSubscriptionsAdapter.upsertOne(state.licenseSubscriptions, mapped);
				state.isLoadingLicensesForDetailedView = false;
			});
		builder.addCase(
			adjustLicenseSubscriptionQuantities.fulfilled,
			(
				state,
				{
					meta: {
						arg: { subscriptionVariants },
					},
				},
			) => {
				const allLicenseSubscriptions = licenseSubscriptionsAdapter
					.getSelectors()
					.selectEntities(state.licenseSubscriptions);

				// Find all subscriptionVariantGroups that are affected by the adjustment
				const subscriptionVariantGroups = subscriptionVariants.map(
					(variant: SubscriptionVariant) =>
						allLicenseSubscriptions[variant.provisioningId],
				);

				// If none are found, return
				if (!subscriptionVariantGroups || subscriptionVariantGroups.length === 0) {
					return;
				}

				// Increment the quantity of the affected subscription variants
				const updatedSubscriptionVariants = subscriptionVariants.map(
					(variant: SubscriptionVariant) => ({
						...variant,
						quantity: variant.quantity + 1,
					}),
				);

				// Update the subscriptionVariantGroups with the updated variants
				const updatedSubscriptionVariantGroups = subscriptionVariantGroups.map(
					(subscriptionVariantGroup: SubscriptionVariantGroup) => {
						const updatedVariants = subscriptionVariantGroup.variants.map(
							(variant: SubscriptionVariant) => {
								const updatedVariant = updatedSubscriptionVariants.find(
									(v: SubscriptionVariant) =>
										v.subscriptionId === variant.subscriptionId,
								);
								if (!updatedVariant) {
									return variant;
								}
								return updatedVariant;
							},
						);
						return {
							...subscriptionVariantGroup,
							variants: updatedVariants,
						};
					},
				);

				// Finally, update the state with the new subscriptionVariantGroups
				licenseSubscriptionsAdapter.upsertMany(
					state.licenseSubscriptions,
					updatedSubscriptionVariantGroups,
				);
			},
		);
		builder
			.addCase(fetchSubscribedSkus.pending, (state) => {
				state.subscribedSkus.isFetching = true;
			})
			.addCase(fetchSubscribedSkus.fulfilled, (state, { payload }) => {
				subscribedSkusAdapter.setAll(state.subscribedSkus, payload);
				state.subscribedSkus.isLoading = false;
				state.subscribedSkus.isFetching = false;
			})
			.addCase(fetchSubscribedSkus.rejected, (state, { payload }) => {
				state.subscribedSkus.isLoading = false;
				state.subscribedSkus.isFetching = false;
				state.subscribedSkus.isError = true;
			});
		builder
			.addCase(assignLicenses.pending, (state) => {
				state.licenseAssignmentState.showLoadingDialog = true;
			})
			.addCase(assignLicenses.fulfilled, (state, { payload, meta }) => {
				const { directAssignments, groupAssignments } = payload.assignments;
				const numAssignmentsBySkuId = {} as { [skuId: string]: number };
				const licenseGroups = meta.arg.licenseGroups as LicenseGroup[];
				const licensesByGroupId = licenseGroups?.reduce((acc, group) => {
					acc[group.groupID] = group.licenses.map((license) => license.skuId);
					return acc;
				}, {} as Record<string, string[]>);

				directAssignments.forEach(({ skuIds }) => {
					skuIds.forEach((skuId) => {
						const currentQuantity = numAssignmentsBySkuId[skuId] ?? 0;
						numAssignmentsBySkuId[skuId] = currentQuantity + 1;
					});
				});

				groupAssignments.forEach(({ groupIds }) => {
					groupIds.forEach((groupId) => {
						const licensesInGroup = licensesByGroupId[groupId];
						if (!licensesInGroup) return;
						licensesInGroup.forEach((skuId) => {
							const currentQuantity = numAssignmentsBySkuId[skuId] ?? 0;
							numAssignmentsBySkuId[skuId] = currentQuantity + 1;
						});
					});
				});

				const updatedSkus = Object.entries(numAssignmentsBySkuId).reduce(
					(acc, [skuId, quantity]) => {
						const sku = state.subscribedSkus.entities[skuId];
						if (!sku) return acc;
						const newConsumed = sku.consumedUnits + quantity;
						const updatedSku = {
							...sku,
							consumedUnits: newConsumed,
						};
						acc.push(updatedSku);
						return acc;
					},
					[] as SubscribedSku[],
				);

				subscribedSkusAdapter.upsertMany(state.subscribedSkus, updatedSkus);
			});
		builder.addCase(removeLicenses.fulfilled, (state, { payload, meta }) => {
			const numRemovalsBySkuId = {} as { [skuId: string]: number };

			const licenseGroups = meta.arg.licenseGroups as LicenseGroup[];
			const licensesByGroupId = licenseGroups.reduce((acc, { groupID, licenses }) => {
				acc[groupID] = licenses.map(({ skuId }) => skuId);
				return acc;
			}, {} as Record<string, string[]>);

			const { directRemovals, groupRemovals } = payload as any as {
				directRemovals: DirectAssignmentChange[];
				groupRemovals: GroupAssignmentChange[];
			};

			directRemovals.forEach(({ skuIds }) => {
				skuIds.forEach((skuId) => {
					const currentQuantity = numRemovalsBySkuId[skuId] ?? 0;
					numRemovalsBySkuId[skuId] = currentQuantity + 1;
				});
			});

			groupRemovals.forEach(({ groupIds }) => {
				groupIds.forEach((groupId) => {
					const licensesInGroup = licensesByGroupId[groupId];
					if (!licensesInGroup) return;
					licensesInGroup.forEach((skuId) => {
						const currentQuantity = numRemovalsBySkuId[skuId] ?? 0;
						numRemovalsBySkuId[skuId] = currentQuantity + 1;
					});
				});
			});

			const updatedSkus = Object.entries(numRemovalsBySkuId).reduce(
				(acc, [skuId, quantity]) => {
					const sku = state.subscribedSkus.entities[skuId];
					if (!sku) return acc;
					const newConsumed = sku.consumedUnits - quantity;
					const updatedSku = {
						...sku,
						consumedUnits: newConsumed,
					};
					acc.push(updatedSku);
					return acc;
				},
				[] as SubscribedSku[],
			);

			subscribedSkusAdapter.upsertMany(state.subscribedSkus, updatedSkus);
		});
	},
});

export const {
	setActiveLicenseManagementId,
	setLicenseAdjustmentDrawerState,
	setLicensedUsersDialogState,
	setLicenseAssignmentState,
	setPreselectedUserIdForAssignment,
} = licenseSlice.actions;

export const selectLicenseAdjustmentDrawerState = (state: RootState) =>
	state.licenses.licenseAdjustmentDrawerState;

export const selectLicensedUsersDialogState = (state: RootState) =>
	state.licenses.licensedUsersDialog;

export const selectActiveLicenseManagementId = (state: RootState) =>
	state.licenses.activeLicenseManagementId;

export const selectLicenseAssignmentState = (state: RootState) =>
	state.licenses.licenseAssignmentState;

export const selectPreselectedUserIdForAssignment = (state: RootState) =>
	state.licenses.licenseAssignmentState.preSelectedUserIdForAssignment;

export const selectLicenseSubscriptions = (state: RootState) => state.licenses.licenseSubscriptions;
export const selectAllLicenseSubscriptionVariants = createSelector(
	selectLicenseSubscriptions,
	(subscriptions) =>
		Object.values(subscriptions.entities)
			.flatMap((subscription) => subscription?.variants)
			.reduce((acc, variant) => {
				if (!variant) return acc;
				acc[variant.subscriptionId] = variant;
				return acc;
			}, {} as Record<string, SubscriptionVariant>),
);

export const selectSubscribedSkus = (state: RootState) => state.licenses.subscribedSkus;
export const selectLicenseGroups = (state: RootState) => state.licenses.groups;
export const selectEnrichedLicenseGroups = createSelector(
	(state: RootState) => state.licenses.groups,
	selectLicenseSubscriptions,
	(groups, subscriptions) => {
		const licenseGroups = licenseGroupsAdapter.getSelectors().selectAll(groups);
		const subscriptionVariants = licenseSubscriptionsAdapter
			.getSelectors()
			.selectAll(subscriptions);

		return licenseGroups.reduce((acc, licenseGroup) => {
			const enrichedLicenseGroup = mapSubscriptionToLicenseGroup(
				licenseGroup,
				subscriptionVariants,
			);
			return {
				...acc,
				[licenseGroup.groupID]: enrichedLicenseGroup,
			};
		}, {} as Record<string, LicenseGroup>);
	},
);

export const licenseGroupsSelectors = licenseGroupsAdapter.getSelectors(
	(state: RootState) => state.licenses.groups,
);

export const licenseSubscriptionsSelectors = licenseSubscriptionsAdapter.getSelectors(
	selectLicenseSubscriptions,
);
export const subscribedSkusSelectors = subscribedSkusAdapter.getSelectors(selectSubscribedSkus);

export const selectAvailableLicenses = createSelector(
	selectEnrichedLicenseGroups,
	(licenseGroups) => Object.values(licenseGroups).flatMap(({ licenses }) => licenses),
);

export const selectLicenseSubscriptionsWithConsumptionDetails = createSelector(
	licenseSubscriptionsSelectors.selectAll,
	subscribedSkusSelectors.selectEntities,
	licenseNameSelectors.selectEntities,
	(subscriptions, subscribedSkus, licenseNames) =>
		matchSubscribedSkusAndSubscriptions(
			subscribedSkus,
			subscriptions,
			{} as Record<string, LicenseAction[]>,
			licenseNames,
		)
			.filter((subscription) => subscription.provisioningId !== "")
			.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName)),
);

export const selectActiveLicenseCompleteDetails = createSelector(
	licenseSubscriptionsSelectors.selectAll,
	subscribedSkusSelectors.selectEntities,
	selectActiveLicenseManagementId,
	selectLicenseActionsWithSkuGUIDKeys,
	licenseNameSelectors.selectEntities,
	(subscriptions, subscribedSkus, provisioningId, licenseActions, licenseNames) => {
		const allSubsOnProvisioningId = subscriptions.filter(
			(subscription) => subscription.provisioningId === provisioningId,
		);
		const matches = matchSubscribedSkusAndSubscriptions(
			subscribedSkus,
			allSubsOnProvisioningId,
			licenseActions,
			licenseNames,
		);

		// Will only be one match on provisioningId as this is the grouper for subVariantGroups
		const match = matches[0];

		// Find out why certain licenses are not shown in the overview
		if (!match || !provisioningId) {
			return {
				variants: [] as SubscriptionVariant[],
				preferredVariantId: "",
				consumedQuantity: 0,
				totalQuantity: 0,
				totalAvailableForRemovalAfterScheduledRemovals: 0,
				friendlyName: "",
				provisioningId: "",
				costIdentifiers: {
					P1M: "",
					P1Y: "",
				},
			};
		}
		return match;
	},
);

const matchSubscribedSkusAndSubscriptions = (
	subscribedSkus: Dictionary<SubscribedSku>,
	subscriptions: SubscriptionVariantGroup[],
	licenseActions: Record<string, LicenseAction[]>,
	licenseNames: Dictionary<LicenseName>,
) => {
	return (
		subscriptions.map((subscription) => {
			const subscriptionSkuId = subscription.provisioningId as string;
			const subscribedSku = subscribedSkus[subscriptionSkuId];
			const actionsForSku = licenseActions[subscriptionSkuId] ?? [];
			const effectsOnSubs = calculateLicenseActionEffects(actionsForSku);

			const numRemovalsScheduled = actionsForSku.reduce((acc, { Status, QuantityChange }) => {
				if (Status === LicenseActionStatus.Scheduled) {
					return acc + Math.abs(QuantityChange);
				}
				return acc;
			}, 0);

			const newVariants = updateCurrentVariantsWithInProgessActions(
				subscription,
				effectsOnSubs[subscriptionSkuId] ?? {},
			);
			const sortedVariants = sortVariants(newVariants);
			const preferredVariant = getPreferredVariant(subscription);
			// totalIronstoneQuantity = total quantity that Ironstone (CSP) provides
			// consumedQuantity = total quantity that is consumed by the customer (regardless of CSP)
			const totalIronstoneQuantity = newVariants.reduce(
				(total, variant) => total + variant.quantity,
				0,
			);
			const consumedQuantity = calculateConsumedQuanity(
				subscription,
				subscribedSku,
				totalIronstoneQuantity,
			);
			const nonConsumedQuantity =
				Number(subscribedSku?.prepaidUnits?.enabled ?? 0) - consumedQuantity;

			// Might look complex, but here's what's happening:
			// CompleteLicenseDetails.nonConsumedQuantity is the fact from the subscribedSku in Azure (regardless of who provisioned it)
			// totalIronstoneQuantity is the sum of all the quantities that Ironstone currently provides
			// numRemovalsScheduled is the sum of all the removals scheduled for the subscriptions that always needs to be subtracted from the total available for removal
			// Why Math.min? We (Ironstone) *cannot* remove more licenses than we provision for the customer, so we need to make sure we don't exceed that number
			const totalAvailableForRemovalAfterScheduledRemovals =
				Math.min(nonConsumedQuantity, totalIronstoneQuantity) - numRemovalsScheduled;

			const friendlyName =
				licenseNames[subscriptionSkuId]?.licenseDisplayName ??
				subscription.friendlyName ??
				"";

			return {
				...subscription,
				friendlyName: friendlyName,
				variants: sortedVariants,
				preferredVariantId: preferredVariant.subscriptionId,
				consumedQuantity,
				totalQuantity: totalIronstoneQuantity,
				totalAvailableForRemovalAfterScheduledRemovals,
			};
		}) ?? []
	);
};

const TENANT_WIDE_LICENSES = [
	"AZURE ACTIVE DIRECTORY",
	"MICROSOFT ENTRA ID",
	"MICROSOFT DEFENDER",
	"OFFICE 365 EXTRA FILE STORAGE",
];

const calculateConsumedQuanity = (
	subscription: SubscriptionVariantGroup,
	subscribedSku: SubscribedSku | undefined,
	totalQuantity: number,
) => {
	if (!subscribedSku) {
		// If subscirbedSkus is not available, we need to use the exact total quantity
		// as we don't want users to see that they have consumed much less than they actually have
		// This might lead to issues regarding removal / assingment
		return totalQuantity;
	}

	// Tenant-wide licenses should be considered fully consumed as we don't have any information
	// about how many users are actually using the license
	const isTenantWideLicense = TENANT_WIDE_LICENSES.some((licenseName) =>
		subscription.friendlyName.toUpperCase().includes(licenseName),
	);
	if (isTenantWideLicense) {
		return totalQuantity;
	}

	return subscribedSku.consumedUnits;
};

// This is a helper function to update the current variants with the effects of in progress actions
// This is needed to make sure that the user sees the correct quantity and autoRenewEnabled state
const updateCurrentVariantsWithInProgessActions = (
	subscription: SubscriptionVariantGroup,
	licenseActionEffect: Record<string, LicenseActionEffect>,
) => {
	return subscription.variants.map((variant) => {
		const subscriptionId = variant.subscriptionId as string;
		const effects = licenseActionEffect[subscriptionId];
		if (!effects) {
			return variant;
		}
		let quantity = variant.quantity;
		let autoRenewEnabled = variant.autoRenewEnabled;

		if (effects.quantity.modified) {
			quantity += effects.quantity.changeQuantity;
		}

		if (effects.autoRenewal.modified) {
			autoRenewEnabled = effects.autoRenewal.newValue;
		}

		return {
			...variant,
			quantity,
			autoRenewEnabled,
		};
	});
};

export default licenseSlice.reducer;

const mapSubscriptionToLicenseGroup = (
	licenseGroup: LicenseGroup,
	subscriptions: SubscriptionVariantGroup[],
): LicenseGroup => {
	const licenses = licenseGroup.licenses.map((license) => {
		const subscription = subscriptions.find((subscription) =>
			subscription.variants.some((variant) => variant.provisioningId === license.skuId),
		) as SubscriptionVariantGroup;

		const variantsWithCostIdentifier = subscription?.variants.map(mapCostIdentifier);

		const preferredVariant = subscription && getPreferredVariant(subscription);

		return {
			...license,
			needsProvisioning: license.unusedLicenses <= 0,
			chosenVariant: preferredVariant ?? { cost: {} },
			subscriptionVariants: variantsWithCostIdentifier,
		};
	});

	return {
		...licenseGroup,
		licenses,
	};
};

export const sortVariants = (variants: SubscriptionVariant[]) =>
	// Current sorting prioritizes termDuration, then creationDate, to ensure a stable sort
	// so that our users can predict the order of the variants
	variants.sort((a, b) => {
		if (a.termDuration !== b.termDuration) {
			if (a.termDuration === TermDuration.P1M) return -1;
			if (b.termDuration === TermDuration.P1M) return 1;
		}

		if (a.creationDate !== b.creationDate) {
			return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
		}

		return b.billingCycle.localeCompare(a.billingCycle);
	});

// Typically the preferred variant for adjusting the quantity upwards
export const getPreferredVariant = (subscription: SubscriptionVariantGroup) => {
	// Description: Selects the preferred variant from a subscription variant group
	// based on the following criteria:
	// 1. The one with autoRenewEnabled = true
	// 2. The one with the lowest termDuration
	// 3. The one with the latest creationDate
	// 4. The one with the lowest billingCycle
	const sortedVariants = [...subscription.variants].sort((a, b) => {
		// Compare autoRenewEnabled
		if (a.autoRenewEnabled && !b.autoRenewEnabled) return -1;
		if (!a.autoRenewEnabled && b.autoRenewEnabled) return 1;

		// Compare termDuration
		if (a.termDuration !== b.termDuration) {
			if (a.termDuration === TermDuration.P1M) return -1;
			if (b.termDuration === TermDuration.P1M) return 1;
		}

		// Compare creationDate
		if (a.creationDate !== b.creationDate) {
			return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
		}

		// Compare billingCycle
		return b.billingCycle.localeCompare(a.billingCycle);
	});

	// Return the first variant in the sorted list
	return sortedVariants[0];
};

const mapCostIdentifier = (variant: SubscriptionVariant) => {
	const costIdentifier = `${variant.offerId.split(":")[0]}:${variant.offerId.split(":")[1]}:${
		variant.termDuration
	}`;

	return {
		...variant,
		costIdentifier,
	};
};
