import { Autocomplete, Checkbox, Grid, Skeleton, TextField, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PeopleIcon from "@mui/icons-material/People";
import { HardwareApprover, HardwareApproverObject } from "types";
import { usersSelectors } from "features/users";
import { capitalize } from "lodash";
import { ApproverType } from "utilities/constants/enums";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import { HoverTooltip } from "components/Common/Tooltips";
import clsx from "clsx";
import useRole from "utilities/roleUtils/roleCheck";
import { useAppSelector } from "hooks/hooks";

import styles from "./ApproverRow.module.scss";
import { useMemo } from "react";
import { selectManagerStructure } from "features/customer";

interface ApproverRowProps {
	isLoading: boolean;
	approverType: ApproverType;
	selectedApprovers: HardwareApproverObject;
	handleSetSelectedApprovers: (type: ApproverType, approvers: HardwareApprover[]) => void;
}

const ApproverRow = ({
	isLoading,
	approverType,
	selectedApprovers,
	handleSetSelectedApprovers,
}: ApproverRowProps) => {
	// Get manager structure here so that we can display approver for manager approvers
	const users = useAppSelector(usersSelectors.selectAll);
	const managerStructure = useAppSelector(selectManagerStructure);

	const { isAdmin } = useRole();

	const selectedTypeApprovers =
		selectedApprovers[approverType.toLowerCase() as keyof HardwareApproverObject];

	const isOptionDisabledDueToRoleConflict = (option: HardwareApprover) => {
		if (approverType === ApproverType.Global) {
			return selectedApprovers.manager.some((manager) => manager.id === option.id);
		} else if (approverType === ApproverType.Manager) {
			return selectedApprovers.global.some((global) => global.id === option.id);
		}
		return false;
	};

	const approverOptions = useMemo(() => {
		return users
			.map((user) => {
				const selected = selectedTypeApprovers.some(
					(selectedUser) => selectedUser.id === user.id,
				);
				const group = selected ? `${capitalize(approverType)} hardware approvers` : "Other";
				return {
					id: user.id,
					mail: user.mail,
					userPrincipalName: user.userPrincipalName,
					phone: user.mobilePhone,
					displayName: user.displayName,
					approverType: approverType,
					group,
					selected,
				};
			})
			.sort((a, b) => {
				if (a.selected && !b.selected) return -1;
				if (!a.selected && b.selected) return 1;
				const aInManagerStructure = managerStructure.managers[a.id];
				const bInManagerStructure = managerStructure.managers[b.id];

				const aNumReports =
					aInManagerStructure?.indirect + aInManagerStructure?.direct || 0;
				const bNumReports =
					bInManagerStructure?.indirect + bInManagerStructure?.direct || 0;

				return bNumReports - aNumReports;
			}) as HardwareApprover[];
	}, [users, approverType, selectedTypeApprovers, managerStructure]);

	return (
		<Grid container className={styles.row}>
			<Grid item className={styles.approverTypeCell}>
				<Typography variant="body1">{capitalize(approverType)}</Typography>
			</Grid>
			<Grid item className={styles.autocompleteCell}>
				{!isLoading ? (
					<Autocomplete
						fullWidth
						options={approverOptions}
						multiple
						value={selectedTypeApprovers}
						onChange={(_, newValue) => {
							handleSetSelectedApprovers(approverType, newValue);
						}}
						disableClearable={!isAdmin}
						clearOnBlur
						handleHomeEndKeys
						isOptionEqualToValue={(option, value) => {
							if (value === undefined) return false;
							return option.id === value.id;
						}}
						groupBy={(option) =>
							selectedTypeApprovers.find(({ id }) => id === option.id)
								? `${capitalize(approverType)} hardware approvers`
								: "Other"
						}
						classes={{
							paper: styles.dropdownStyle,
						}}
						getOptionLabel={(option) => option?.displayName ?? ""}
						disableCloseOnSelect
						renderOption={(props, option, { selected }) => {
							const alreadyHasAntoherRole = isOptionDisabledDueToRoleConflict(option);
							const optionHasNoMail = !option.mail;
							// show if user already has another role or if the logged in user is not an action role
							const disabledOption =
								alreadyHasAntoherRole || !isAdmin || optionHasNoMail;
							let tooltipDescription = `Please remove the ${
								approverType === ApproverType.Global ? "manager" : "global"
							} hardware approver role from ${
								option.displayName
							} before assigning this role.`;
							let tooltipTitle = `${option.displayName} already has a role`;

							if (!isAdmin) {
								tooltipTitle = "You do not have permission to assign roles.";
								tooltipDescription = "";
							} else if (optionHasNoMail) {
								tooltipTitle = "User has no email address";
								tooltipDescription =
									"If you want to assign this user as a hardware approver, please make sure they have an email address.";
							}

							return (
								<HoverTooltip
									title={tooltipTitle}
									description={tooltipDescription}
									hide={!disabledOption}
									placement="left-start"
								>
									<li
										{...props}
										key={option.id}
										className={clsx(styles.option, {
											[styles.selected]: selected,
											[styles.disabledOption]: disabledOption,
										})}
										onClick={disabledOption ? undefined : props.onClick}
									>
										<Checkbox
											icon={
												<CheckBoxOutlineBlankIcon
													className={styles.unchecked}
													fontSize="small"
												/>
											}
											checkedIcon={
												<CheckBoxIcon
													className={styles.checked}
													fontSize="small"
												/>
											}
											checked={selected}
											disableRipple={alreadyHasAntoherRole}
										/>
										<Grid item className={styles.userNameContainer}>
											<Typography variant="description">
												{tripleDotTruncateString(option.displayName, 33)}
											</Typography>
											<Typography variant="caption" color={"text.secondary"}>
												{tripleDotTruncateString(
													option.mail ?? option.id,
													45,
												)}
											</Typography>
										</Grid>
									</li>
								</HoverTooltip>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder={
									Object.values(selectedTypeApprovers).length > 0
										? ""
										: `Select ${approverType.toLowerCase()} approvers`
								}
								InputProps={{
									style: {
										height: 40,
										alignContent: "center",
									},
									...params.InputProps,
									type: "search",
								}}
							/>
						)}
						renderTags={(value, getTagProps) => (
							<div className={styles.tagContainer}>
								<PeopleIcon className={styles.icon} />
								<Typography variant="body1" fontWeight={500}>
									{value.length}
								</Typography>
							</div>
						)}
					/>
				) : (
					<Skeleton variant="rectangular" className={styles.autocompleteSkeleton} />
				)}
			</Grid>
			<Grid item className={styles.approverForCell}>
				<Typography variant="body1">
					{approverType === ApproverType.Global
						? "All users"
						: "Users with selected managers"}
				</Typography>
			</Grid>
		</Grid>
	);
};

export { ApproverRow };
