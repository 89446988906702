import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { ConditionalAccessSection } from "./ConditionalAccessSection";
import clsx from "clsx";
import { InternalLink } from "components/Common/Links";
import styles from "./FindingsTable.module.scss";

const ComplexTableCellValue = ({ header, value }) => {
	if (header === "Conditions") return <ConditionalAccessSection value={value} />;

	if (header === "Grant Controls") {
		return (
			<Typography variant="caption">{value?.builtInControls.join(value.operator)}</Typography>
		);
	}

	if (header === "Sessions Controls") {
		const {
			signInFrequency,
			applicationEnforcedRestrictions,
			cloudAppSecurity,
			persistentBrowser,
		} = value;

		value = {
			"Sign In Frequency": signInFrequency || "Not configured",
			"CloudApp Security Type": applicationEnforcedRestrictions || "Not configured",
			"Application Enforced Restrictions": cloudAppSecurity || "Not configured",
			"Persistent Browser": persistentBrowser || "Not configured",
		};
	}

	return Object.entries(value ?? {}).map(([nestedHeader, nestedValue]) => (
		<Grid key={`${nestedHeader}-${nestedValue}`}>
			<Typography variant="subtitle1">{nestedHeader}</Typography>
			<Typography variant="caption">{nestedValue}</Typography>
		</Grid>
	));
};

const FindingsTable = ({ headers, values, fixed }) => {
	return (
		<Grid mt={2}>
			<TableContainer className={styles.tableContainer}>
				<Table stickyHeader className={clsx({ [styles.fixedTableLayout]: fixed })}>
					<TableHead classes={styles.tableHead}>
						<TableRow>
							{headers.map((item, key) => {
								return (
									<TableCell
										key={`${item}-${key}`}
										className={styles.tableHeadCell}
									>
										{item}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{values.map((item, key) => {
							return (
								<TableRow key={`TableRow-${item}-${key}`}>
									{headers.map((property) => {
										let value = item[property];
										let urlIndex = -1;
										let stateStartIndex = -1;
										let stateEndIndex = -1;

										const isStringValue = typeof value === "string";
										const isBooleanValue = typeof value === "boolean";

										if (isBooleanValue) value = value.toString();

										if (isStringValue || isBooleanValue) {
											stateStartIndex = value.indexOf("(");
											stateEndIndex = value.indexOf(")") + 1;
											urlIndex = value.indexOf("http");
										}

										return (
											<TableCell
												key={`TableCell-${property}-${key}`}
												classes={{
													root: styles.tableCell,
												}}
											>
												{isStringValue || isBooleanValue ? (
													urlIndex === -1 ? (
														value
													) : (
														<Typography variant="subtitle1">
															<InternalLink
																href={value.substring(urlIndex)}
															>
																{value.substring(
																	0,
																	stateStartIndex !== -1
																		? stateStartIndex
																		: urlIndex,
																)}
															</InternalLink>
															<br />
															{stateStartIndex !== -1 &&
																value.substring(
																	stateStartIndex,
																	stateEndIndex,
																)}
														</Typography>
													)
												) : (
													<ComplexTableCellValue
														value={value}
														header={property}
													/>
												)}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

export { FindingsTable };
