import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { userFormValidation } from "utilities/userFormValidation";
import styles from "./ShippingDetails.module.scss";
import { HoverTooltip } from "components/Common/Tooltips";
import { ShippingCountryAlternative } from "types";
import { DeliveryOption } from "utilities/constants/enums";
import { DELIVERY_TIME } from "utilities/constants/constants";
import clsx from "clsx";

interface Props {
	formProps: UseFormReturn;
	xs?: number;
	required?: boolean;
	countryAlternatives: ShippingCountryAlternative[];
	isOnboarding?: boolean;
}

const ShippingDetails = ({
	formProps: {
		register,
		watch,
		formState: { errors },
		control,
	},
	countryAlternatives,
	xs = 4,
	required = true,
	isOnboarding = false,
}: Props) => {
	const multipleCountryAlternatives =
		countryAlternatives.filter((alternative) => alternative.countryKomplettId !== "").length >
		1;

	return (
		<Grid
			container
			item
			lg={xs}
			md={12}
			classes={{ root: styles.shippingDetails }}
			justifyContent="flex-start"
		>
			<Grid item xs={6} className={clsx({ [styles.hidden]: true })}>
				{/* TODO: Remove when we have confirmed with Komplett that we can ship to other countries */}
				<HoverTooltip
					title={"Country restrictions"}
					description={
						"We currently only ship to addresses in Norway. We're working on a solution for deliveries to other nordic countries."
					}
					hide={multipleCountryAlternatives}
				>
					<div>
						<InputLabel id="country-label">
							<Typography>Country</Typography>
						</InputLabel>
						<FormControl fullWidth size="small" disabled={!multipleCountryAlternatives}>
							<Controller
								name="country"
								defaultValue="Norway"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<Select
										{...field}
										labelId="country-label"
										error={!!error}
										defaultValue="Norway"
										value={field.value || "Norway"}
										classes={{ select: styles.formSelectInput }}
									>
										{countryAlternatives.map((alternative) => (
											<MenuItem
												key={alternative.countryKomplettId}
												value={alternative.name}
											>
												{alternative.name}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
					</div>
				</HoverTooltip>
			</Grid>
			<Grid item xs={12}>
				<InputLabel>
					<Typography variant="body1" display="inline">
						Street address
					</Typography>
					{isOnboarding && (
						<>
							{watch("deliveryOption") === DeliveryOption.B2CHomeDelivery ? (
								<Typography
									variant="caption"
									color="text.secondary"
									display="inline"
								>
									{" "}
									(Received at <b>home address</b> between {DELIVERY_TIME.HOME})
								</Typography>
							) : (
								<Typography
									variant="caption"
									color="text.secondary"
									display="inline"
								>
									{" "}
									(Received at <b>office address</b> between{" "}
									{DELIVERY_TIME.OFFICE})
								</Typography>
							)}{" "}
						</>
					)}
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					InputProps={{
						classes: {
							root: styles.streetAddress,
							input: styles.formTextInput,
						},
					}}
					error={!!errors.streetAddress}
					helperText={userFormValidation.streetAddress.getHelperText(errors)}
					{...register(
						"streetAddress",
						userFormValidation.streetAddress.getValidationProps(required),
					)}
				/>
			</Grid>
			<Grid item xs={2.75} mr={3}>
				<InputLabel>
					<Typography variant="body1">Postal code</Typography>
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					error={!!errors.postalCode}
					InputProps={{
						classes: {
							input: styles.formTextInput,
						},
					}}
					helperText={userFormValidation.postalCode.getHelperText(errors)}
					{...register(
						"postalCode",
						userFormValidation.postalCode.getValidationProps(required),
					)}
				/>
			</Grid>
			<Grid item xs={2.75}>
				<InputLabel>
					<Typography variant="body1">City</Typography>
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					InputProps={{
						classes: {
							input: styles.formTextInput,
						},
					}}
					error={!!errors.city}
					helperText={userFormValidation.city.getHelperText(errors)}
					{...register("city", userFormValidation.city.getValidationProps(required))}
				/>
			</Grid>
		</Grid>
	);
};

export { ShippingDetails };
