import { Grid, Typography } from "@mui/material";
import { PriceInfo } from "types";

import styles from "./ProductPrice.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

interface ProductPriceProps {
	priceInfo: PriceInfo | undefined;
}

export const HardwareProductPrice = ({ priceInfo }: ProductPriceProps) => {
	const { priceNet, priceNetExcludingCustomerDiscount, discountPercentage } =
		priceInfo ?? ({ priceNet: 0, priceNetExcludingCustomerDiscount: 0 } as PriceInfo);
	const isDiscounted = discountPercentage > 1;

	return (
		<Grid container direction="row">
			{isDiscounted ? (
				<Grid container item justifyContent={"space-between"}>
					<Grid item className={styles.discountCircle}>
						<Typography className={styles.discountText}>
							- {discountPercentage}%
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h2" className={styles.discountedPrice}>
							{formatCostString("", priceNetExcludingCustomerDiscount, " ,-")}
						</Typography>
						<Typography variant="h2">
							{formatCostString("", priceNet, " ,-")}
						</Typography>
						<Typography variant="description" className={styles.exMvaMargins}>
							eks.mva
						</Typography>
					</Grid>
				</Grid>
			) : (
				<Grid item xs={12} display={"flex"} direction="row" justifyContent={"flex-end"}>
					<Grid item>
						<Typography variant="h2">
							{formatCostString("", priceNetExcludingCustomerDiscount, " ,-")}
						</Typography>
						<Typography variant="description" className={styles.exMvaMargins}>
							eks.mva
						</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
