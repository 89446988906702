import {
	TableContainer,
	TableHead,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Skeleton,
	Typography,
	Grid,
	TableSortLabel,
} from "@mui/material";

import styles from "./MyPeopleTable.module.scss";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectSearchedUsers, selectUsers } from "features/users";
import useInfiniteLoader from "hooks/useInfiniteLoader";
import { fetchLicenseGroups, fetchLicenseSubscriptions } from "actions/licenseActions";
import { selectLicenseGroups, selectLicenseSubscriptions } from "features/licenses/licenses";
import clsx from "clsx";
import { setActiveTab, setUserDrawerOpen } from "features/user";
import { User } from "types";
import { UserActivityType, UserType } from "utilities/constants/enums";
import { fetchManager } from "actions/userActions";
import { licenseNameSelectors, selectlicenseNames } from "features/licenses/licenseNames";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import { useEffect, useMemo, useState } from "react";
import { TABS as USER_DRAWER_TABS } from "../UserDrawer/UserDrawerTabSelector";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { SignInActivity } from "./SignInActivity";
import SummaryTableFooter from "components/Common/SummaryTableFooter/SummaryTableFooter";

interface MyPeopleTableProps {
	userTypeFilter: UserType[];
	userActivityFilter: UserActivityType[];
	onResetFilters: () => void;
	shouldShowTableFooter: boolean;
}

const MyPeopleTable = ({
	userTypeFilter,
	userActivityFilter,
	onResetFilters,
	shouldShowTableFooter,
}: MyPeopleTableProps) => {
	const { isLoading } = useAppSelector(selectUsers);
	const licenseGroupState = useAppSelector(selectLicenseGroups);
	const { isLoading: isLoadingLicenseNames } = useAppSelector(selectlicenseNames);
	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const users = (useAppSelector(selectSearchedUsers) ?? []) as User[];
	const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" }>({
		key: "displayName",
		direction: "asc",
	});

	useApiOnce(fetchLicenseGroups, licenseGroupState);
	useApiOnce(fetchLicenseSubscriptions, useAppSelector(selectLicenseSubscriptions));

	const { dispatch, auth } = useAuth();

	const handleRowClicked = (userId: string) => {
		if (!userId) return;
		dispatch(setUserDrawerOpen({ isDrawerOpen: true, activeUserId: userId }));
		dispatch(fetchManager({ auth, id: userId }));
	};

	useEffect(() => {
		dispatch(setActiveTab(USER_DRAWER_TABS.USER_DETAILS));
		return () => {
			dispatch(setActiveTab(USER_DRAWER_TABS.USER_DETAILS));
		};
	}, [dispatch]);

	const requestSort = (key: string) => {
		let direction: "asc" | "desc" = "desc";
		if (sortConfig.key === key && sortConfig.direction === "desc") {
			direction = "asc";
		}
		setSortConfig({ key, direction });
	};

	// Sorting and filtering applied together
	const shownUsers = useMemo(() => {
		const filtered = users.filter((user) =>
			filterUsers(user, userTypeFilter, userActivityFilter),
		);
		const sorted = sortUsers(filtered, sortConfig);
		return sorted;
	}, [users, userTypeFilter, userActivityFilter, sortConfig]);

	const { loaderRef, renderCount } = useInfiniteLoader({
		total: shownUsers.length,
	});

	const columns = [
		{ id: "displayName", label: "Name", width: "20%", skeletonWidth: 350 },
		{ id: "userType", label: "User Type", width: "10%", skeletonWidth: 70 },
		{ id: "licenses", label: "Licenses", width: "50%", skeletonWidth: 500 },
		{ id: "lastSignInDateTime", label: "User activity", width: "15%", skeletonWidth: 100 },
	];

	const calculatedSummaryCells = useMemo(
		() => calculateSummaryCells(shownUsers, userTypeFilter, userActivityFilter),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[shownUsers],
	);

	return (
		<>
			<TableContainer className={styles.tableContainer}>
				<Table aria-label="My People">
					<TableHead className={styles.tableHeader}>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} width={column.width}>
									<TableSortLabel
										active={sortConfig.key === column.id}
										direction={
											sortConfig.key === column.id
												? sortConfig.direction
												: "asc"
										}
										onClick={() => requestSort(column.id)}
									>
										{column.label}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							Array.from(Array(15)).map((_, i) => (
								<TableRow
									key={i}
									hover={true}
									classes={{
										root: styles.tableRow,
										hover: styles.tableRowHover,
									}}
								>
									{columns.map((column) => (
										<TableCell key={column.id}>
											<Skeleton
												variant="text"
												width={column.skeletonWidth}
												height={25}
											/>
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<>
								{shownUsers?.length > 0 ? (
									shownUsers.slice(0, renderCount).map((user, key) => (
										<TableRow
											key={key}
											hover={true}
											classes={{
												root: styles.tableRow,
												hover: styles.tableRowHover,
											}}
											onClick={() => handleRowClicked(user?.id)}
										>
											<TableCell
												component="th"
												scope="row"
												className={styles.cellTopAlign}
											>
												<TruncatableTypography
													variant="body1"
													maxCharLength={30}
													tooltipPlacement="right-end"
												>
													{user.displayName}
												</TruncatableTypography>
												<TruncatableTypography
													variant="description"
													color="text.secondary"
													maxCharLength={30}
													tooltipPlacement="right-end"
												>
													{user.mail ?? user.userPrincipalName}
												</TruncatableTypography>
											</TableCell>
											<TableCell className={styles.cellTopAlign}>
												{user.userType}
											</TableCell>
											<TableCell width="30%" className={styles.cellTopAlign}>
												{isLoadingLicenseNames ? (
													<Skeleton
														variant="text"
														width={100}
														height={25}
													/>
												) : (
													<Grid className={styles.licenseGrid}>
														{user.licenses?.map((license, index) => (
															<Grid item key={index}>
																<Typography
																	className={styles.chipContainer}
																>
																	{licenseNames[license.skuId]
																		?.licenseDisplayName ??
																		tripleDotTruncateString(
																			license.skuId,
																			10,
																		)}
																</Typography>
															</Grid>
														))}
													</Grid>
												)}
											</TableCell>
											<TableCell className={styles.cellTopAlign}>
												<SignInActivity {...user} />
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											className={clsx({
												[styles.noItemsCell]: true,
											})}
											colSpan={4}
											align="center"
										>
											No employees found
										</TableCell>
									</TableRow>
								)}
							</>
						)}
					</TableBody>
					<SummaryTableFooter
						padding={false}
						onResetFilters={onResetFilters}
						summaryCells={[
							{
								label: "Total employees",
								value: shownUsers.filter(
									(user) => user.mail?.toUpperCase() !== "NO MATCH",
								).length,
							},
							...calculatedSummaryCells,
						]}
						visible={shouldShowTableFooter}
					/>
				</Table>
				<div ref={loaderRef} className="loaderRef" />
			</TableContainer>
		</>
	);
};

export { MyPeopleTable };

const calculateSummaryCells = (
	users: User[],
	userTypeFilter: UserType[],
	userActivityFilter: UserActivityType[],
) => {
	let summaryCells = [] as { label: string; value: number }[];

	if (userTypeFilter.length === 0 && userActivityFilter.length === 0) {
		return [];
	}

	if (userTypeFilter.length > 0) {
		const userTypes = users.reduce((acc, user) => {
			if (userTypeFilter.includes(user.userType as UserType)) {
				acc[user.userType] = (acc[user.userType] || 0) + 1;
			}
			return acc;
		}, {} as Record<string, number>);

		summaryCells = Object.entries(userTypes).map(([key, value]) => ({
			label: key,
			value,
		}));
	}

	if (userActivityFilter.length > 0) {
		const userActivities = users.reduce((acc, user) => {
			if (userActivityFilter.includes(user.signInActivityStatus)) {
				acc[user.signInActivityStatus] = (acc[user.signInActivityStatus] || 0) + 1;
			}
			return acc;
		}, {} as Record<string, number>);

		const userActivitySummaryCells = Object.entries(userActivities).map(([key, value]) => ({
			label: key,
			value,
		}));

		summaryCells = [...summaryCells, ...userActivitySummaryCells];
	}

	return summaryCells;
};

const filterUsers = (
	user: User,
	userTypeFilter: UserType[],
	userActivityFilter: UserActivityType[],
) => {
	let userTypeMatch = userTypeFilter.length === 0;
	let userActivityMatch = userActivityFilter.length === 0;

	const allFiltersDefault = userTypeMatch && userActivityMatch;

	// If all filters are default (accepting any user type and activity), return true
	if (allFiltersDefault) return true;

	// Check if user's type is included in the selected user type filter
	if (!userTypeMatch) {
		userTypeMatch = userTypeFilter.includes(user.userType as UserType);
	}

	if (!userActivityMatch) {
		userActivityMatch = userActivityFilter.some(
			(activityType) => activityType === user.signInActivityStatus,
		);
	}

	return userTypeMatch && userActivityMatch;
};

const sortUsers = (users: User[], sortConfig: { key: string; direction: "asc" | "desc" }) => {
	return users.sort((a, b) => {
		const sortConfigKey = sortConfig.key as keyof User;
		const aCell = a[sortConfigKey] ?? "";
		const bCell = b[sortConfigKey] ?? "";
		if (sortConfig.direction === "asc") {
			return aCell > bCell ? 1 : -1;
		} else {
			return aCell < bCell ? 1 : -1;
		}
	});
};
