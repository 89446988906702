/*
    Many of the pages in the portal is based on data that is created at regular time intervals. (e.g. daily, weekly, monthly)
    This data is used to show our customers security statuses, cost management, device mgmt etc. 
    When the customer first logs in, this data is not available, and we need to kick off a process to create it.
*/

import { ActionLoadingStatus, DataGenerationTypes } from "./constants/enums";
import { DataGeneration, DataGenerationStatuses } from "types";

const LOCAL_STORAGE_REPORT_PROGRESS_STATUS_KEY = "dataGenerationProgressStatus";
const DATA_GENERATION_MAX_DURATION_IN_MINUTES = 5;

// This function is used to determine if the report data is available or not.
const getDataGenerationStatus = (): DataGeneration => {
	const reportInfoFromLocalStorage = localStorage.getItem(
		LOCAL_STORAGE_REPORT_PROGRESS_STATUS_KEY,
	);

	if (reportInfoFromLocalStorage) {
		return JSON.parse(reportInfoFromLocalStorage) as DataGeneration;
	}

	return {
		kickstartedDataGeneration: false,
		kickstartedAt: undefined,
		dataGenerationStatuses: {
			aggregatedData: ActionLoadingStatus.NOT_STARTED,
			identityReport: ActionLoadingStatus.NOT_STARTED,
			office365Cost: ActionLoadingStatus.NOT_STARTED,
			azureCost: ActionLoadingStatus.NOT_STARTED,
			azureReservedInstancesCost: ActionLoadingStatus.NOT_STARTED,
			azureMarketplaceItemsCost: ActionLoadingStatus.NOT_STARTED,
		} as DataGenerationStatuses,
	} as DataGeneration;
};

const updateDataGenerationStatus = (
	dataGenerationStatusType: DataGenerationTypes,
	dataGenerationStatus: ActionLoadingStatus,
) => {
	const reportInfoFromLocalStorage = getDataGenerationStatus();

	const updated = {
		...reportInfoFromLocalStorage,
		dataGenerationStatuses: {
			...reportInfoFromLocalStorage.dataGenerationStatuses,
			[dataGenerationStatusType]: dataGenerationStatus,
		},
	};

	setDataGenerationStatuses(updated);
};

const setDataGenerationStatuses = ({
	kickstartedDataGeneration,
	kickstartedAt,
	dataGenerationStatuses,
}: DataGeneration) => {
	localStorage.setItem(
		LOCAL_STORAGE_REPORT_PROGRESS_STATUS_KEY,
		JSON.stringify({
			kickstartedDataGeneration,
			kickstartedAt,
			dataGenerationStatuses,
		}),
	);
};

const INITIAL_DATA_GENERATION_STATUSES: DataGenerationStatuses = {
	office365Cost: ActionLoadingStatus.IN_PROGRESS,
	azureCost: ActionLoadingStatus.IN_PROGRESS,
	aggregatedData: ActionLoadingStatus.IN_PROGRESS,
	identityReport: ActionLoadingStatus.IN_PROGRESS,
	azureMarketplaceItemsCost: ActionLoadingStatus.IN_PROGRESS,
	azureReservedInstancesCost: ActionLoadingStatus.IN_PROGRESS,
};

export {
	getDataGenerationStatus,
	setDataGenerationStatuses,
	updateDataGenerationStatus,
	INITIAL_DATA_GENERATION_STATUSES,
	DATA_GENERATION_MAX_DURATION_IN_MINUTES,
};
