import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DownloadableLink } from "components/Common/DownloadableLink";
import _ from "lodash";
import { FindingsTable } from "../FindingsTable";
import { PrimaryButton } from "components/Common/Buttons/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { capitalize, toCapitalizedWords } from "utilities/stringFormattingUtility";
import { useAppSelector, useAuth } from "hooks/hooks";
import { reportSelectors } from "features/reports";
import { fetchReportData } from "actions/reportActions";

const prettifyEmptyAzureADGroupsData = (values) => {
	const azureAdGroupUrlBase =
		"https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/";
	return values.map(({ displayName, id, description }) => ({
		"Display Name": `${displayName} ${azureAdGroupUrlBase}${id}`,
		Description: description,
	}));
};

const prettifyConditionalAccessData = (values) =>
	values.map(({ displayName, state, URI, Conditions, grantControls, sessionControls }) => ({
		"Policy (State)": `${displayName} (${state}) ${URI}`,
		Conditions,
		"Grant Controls": grantControls,
		"Sessions Controls": sessionControls,
	}));

const prettifyUsersNotSignedInLastNinetyDaysData = (values) => {
	const azureIdentityUrlBase =
		"https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/";
	return values.map(({ Enabled, User, LastSeen: { DateTime } }) => {
		const userPrincipalName = User.substring(User.indexOf("(") + 1, User.length - 1);
		return {
			User: `${User} ${azureIdentityUrlBase}${userPrincipalName}`,
			Enabled: capitalize(Enabled.toString()),
			"Last Seen": new Date(DateTime).toLocaleString(),
		};
	});
};

const prettifyAzureAdBasicConfigurationData = (values) =>
	values.map(({ Description, Policy, Setting }) => ({
		Policy,
		Description,
		Setting,
	}));

const prettifyDomainProtectionData = (values) =>
	values.map(
		({
			Domain,
			Capabilities,
			SPF,
			DKIM,
			DMARC,
			Status: { DMARC: DMARC_STATUS, SPF: SPF_STATUS, DKIM: DKIM_STATUS },
		}) => ({
			Domain,
			Capabilities,
			Status: { SPF: SPF_STATUS, DKIM: DKIM_STATUS, DMARC: DMARC_STATUS },
			SPF,
			DKIM,
			DMARC,
		}),
	);

const prettifyRiskData = (values) => {
	const azureIdentityUrlBase =
		"https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/";
	return values.map(
		({
			riskLevel,
			riskState,
			userId,
			userDisplayName,
			userPrincipalName,
			riskEventType,
			detectedDateTime,
			lastUpdatedDateTime,
			riskLastUpdatedDateTime,
		}) => {
			const value = {
				"Risk Level": capitalize(riskLevel),
				"Risk State": toCapitalizedWords(riskState),
				User: `${userDisplayName} (${userPrincipalName}) ${azureIdentityUrlBase}${userId}`,
			};

			if (riskEventType) value["Risk Type"] = toCapitalizedWords(riskEventType ?? "");

			if (detectedDateTime)
				value["Detected Date Time"] = new Date(detectedDateTime).toLocaleString();

			value["Last Update Date"] = new Date(
				lastUpdatedDateTime || riskLastUpdatedDateTime,
			).toLocaleString();

			return value;
		},
	);
};

const prettifyInactiveDevicesData = (values) => {
	const now = new Date().getTime();
	const secondsPerDay = 1000 * 3600 * 24;
	return values
		.filter(({ approximateLastSignInDateTime }) => {
			const then = new Date(approximateLastSignInDateTime).getTime();
			const daysBetween = (now - then) / secondsPerDay;
			return daysBetween > 30;
		})
		.map(
			({
				displayName,
				operatingSystem,
				approximateLastSignInDateTime,
				trustType,
				accountEnabled,
				isManaged,
				registrationDateTime,
				registeredOwners,
			}) => ({
				"Display Name": displayName,
				"Operating System": operatingSystem,
				"Approximate Last Sign In": new Date(
					approximateLastSignInDateTime,
				).toLocaleString(),
				"Trust Type": trustType,
				"Account Enabled": capitalize(accountEnabled),
				"Is Managed": capitalize(isManaged),
				"Registration Time": new Date(registrationDateTime).toLocaleString(),
				"Registered Owner": registeredOwners,
			}),
		);
};

const LogFileTable = (props) => {
	const {
		children: [target],
	} = props;

	// const [filePath, setFilePath] = useState("");

	const [isVisible, setIsVisible] = useState(false);

	// Detect lines with paths for finding log data for tables (e.g., ###### CheckLogFile {CloudReports__LogData__RiskyUsers__22112022-RiskyUsers.json;CheckValues.RiskDetails})
	const isLogFile = (str) => str.startsWith("CheckLogFile");

	// Returns the paths for extracting table data from log files
	const extractLogFilePaths = (str) =>
		str.substring(str.indexOf("{") + 1, str.indexOf("}")).split(";");

	const { dispatch, auth } = useAuth();

	let filePath = "",
		jsonPath = "";
	if (isLogFile(target)) [filePath, jsonPath] = extractLogFilePaths(target);

	const tableData = useAppSelector((state) => reportSelectors.selectById(state, filePath));

	useEffect(() => {
		if (tableData?.isLoading === false || tableData?.isFetching === true) return;

		dispatch(fetchReportData({ auth, folderPath: filePath, jsonPath }));
	}, [dispatch, auth, tableData?.isLoading, tableData?.isFetching, filePath, jsonPath]);

	if (!isLogFile(target)) return <Typography variant="h6" {...props} />;

	const isLoading = tableData?.data === undefined;

	if (!isLoading && _.size(tableData?.data) === 0)
		return <Typography variant="caption">No log file data available</Typography>;

	let values = tableData?.data ?? [];

	if (!Array.isArray(values)) values = Object.values(values);

	const ids = values.map((obj) => _.pickBy(obj, (_, key) => key.toLowerCase().endsWith("id")));

	if (filePath.endsWith("Groups.json")) values = prettifyEmptyAzureADGroupsData(values);
	else if (filePath.endsWith("ConditionalAccess.json"))
		values = prettifyConditionalAccessData(values);
	else if (filePath.endsWith("LastSignIn.json"))
		values = prettifyUsersNotSignedInLastNinetyDaysData(values);
	else if (filePath.endsWith("AuthorizationPolicy.json"))
		values = prettifyAzureAdBasicConfigurationData(values);
	else if (filePath.endsWith("DomainProtection.json"))
		values = prettifyDomainProtectionData(values);
	else if (filePath.endsWith("RiskDetections.json") || filePath.endsWith("RiskyUsers.json"))
		values = prettifyRiskData(values);
	else if (filePath.endsWith("Devices.json")) values = prettifyInactiveDevicesData(values);

	const csvValues = _.zip(values, ids)
		.filter(([a]) => a)
		.map(([a, b]) => _.merge({}, a, b));

	return (
		<Grid container direction="column" alignItems="flex-start" mt={1}>
			<Grid>
				<DownloadableLink
					data={csvValues}
					fileName={`${filePath}-data.csv`}
					shouldFormatNumbers={true}
				/>
				<PrimaryButton
					text={`${isVisible ? "Hide" : "Show"} findings table`}
					size="small"
					variantStyle="contained"
					isLoading={isLoading}
					marginLeft={3}
					action={() => setIsVisible(!isVisible)}
					endIcon={isVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				/>
			</Grid>
			{isVisible && (
				<FindingsTable
					headers={Object.keys(values[0] ?? {})}
					values={values}
					isLoading={isLoading}
					fixed={target.includes("DomainProtection")}
				/>
			)}
		</Grid>
	);
};

export { LogFileTable };
