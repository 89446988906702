import { Typography } from "@mui/material";
import config from "authConfig";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { useAuth } from "hooks/hooks";
import { IRONSTONE_SUPPORT_MAIL } from "utilities/constants/constants";
import { handleLogout } from "utilities/handleLogout";
import { capitalize } from "utilities/stringFormattingUtility";

export const TenantNotAllowedDialog = () => {
	const {
		auth: { account, instance },
	} = useAuth();

	const allowedTenants = new config.AuthConfig().ALLOWED_TENANTS;

	const showDialog = account && !allowedTenants.includes(account.tenantId);

	const tryGetTenantName = () => {
		try {
			const name = account.username.split("@")[1].split(".")[0];
			return capitalize(name);
		} catch (e) {
			return "your company";
		}
	};

	return showDialog ? (
		<PrimaryDialog
			title={`Access denied for ${tryGetTenantName()}`}
			primaryAction={() => handleLogout({ account, instance })}
			primaryButtonText="Logout"
			size="medium"
		>
			<Typography display="inline">It looks like your account </Typography>
			<Typography display="inline" color="text.secondary">
				({account.username}){" "}
			</Typography>
			<Typography display="inline">
				is associated with a company that hasn't yet set up access to the Ironstone portal.
			</Typography>
			<Typography variant="h3" fontWeight="500" color="text.primary" mt={3}>
				Need Access to the Ironstone Portal?
			</Typography>
			<Typography fontWeight="400" color="text.primary" display="inline">
				No worries! You can easily request access by reaching out to our support team at{" "}
				{<a href={`mailto:${IRONSTONE_SUPPORT_MAIL}`}>{IRONSTONE_SUPPORT_MAIL}</a>}.
			</Typography>
		</PrimaryDialog>
	) : null;
};
