import { Grid, TableSortLabel, Typography } from "@mui/material";
import {
	devicesSelectors,
	resetFilters,
	selectChosenDeviceFilters,
	selectDevices,
	selectFilterIds,
} from "features/devices";
import { selectUsers, usersSelectors } from "features/users";
import { useAppSelector, useAuth, useWindowSize } from "hooks/hooks";

import styles from "./ManageDevicesTable.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import useInfiniteLoader from "hooks/useInfiniteLoader";
import SummaryTableFooter from "components/Common/SummaryTableFooter/SummaryTableFooter";
import { calculateSummaryCells } from "utilities/filterDeviceData";
import { csvDownload } from "components/Common/DownloadableLink";
import dayjs from "dayjs";
import { theme } from "theme";
import { Device } from "types";
import { DeviceRow, LoadingDeviceRow } from "./DeviceRow/DeviceRow";
import { Dictionary } from "@reduxjs/toolkit";

interface ManageDevicesTableProps {
	searchValue: string;
	onResetFilters: () => void;
}

const ManageDevicesTable = ({ searchValue, onResetFilters }: ManageDevicesTableProps) => {
	const { dispatch } = useAuth();
	const devices = useAppSelector(devicesSelectors.selectAll);
	const deviceEntities = useAppSelector(devicesSelectors.selectEntities);
	const filterIds = useAppSelector(selectFilterIds);
	const chosenFilters = useAppSelector(selectChosenDeviceFilters);

	const { isLoading: isUsersLoading } = useAppSelector(selectUsers);
	const users = useAppSelector(usersSelectors.selectEntities);
	const { isLoading: isDevicesLoading } = useAppSelector(selectDevices);

	const handleDownloadExcel = () => {
		csvDownload(
			dispatch,
			shownDevices,
			`Devices: ${dayjs().format("YYYY-MM-DD")}.csv`,
			";",
			true,
			`Filters: ${JSON.stringify(
				Object.entries(chosenFilters).reduce((acc, [key, value]) => {
					acc[key] = value.join(", ");
					return acc;
				}, {} as Record<string, string>),
			)}`,
		);
	};

	const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" }>({
		key: "displayName",
		direction: "asc",
	});

	const handleSort = (key: string) => {
		const newDirection =
			sortConfig.key === key && sortConfig.direction === "desc" ? "asc" : "desc";
		setSortConfig({ key, direction: newDirection });
	};

	const shownDevices = useMemo(() => {
		return filterAndSortDevices(deviceEntities, filterIds, searchValue, sortConfig);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceEntities, filterIds, searchValue, sortConfig]);

	const shouldShowSummaryFooter =
		chosenFilters.osFilters.length > 0 ||
		chosenFilters.complianceFilters.length > 0 ||
		chosenFilters.intuneManagementFilters.length > 0 ||
		shownDevices.length !== devices.length;

	const shadowRef = useRef<HTMLDivElement>(null);
	const [shadow, setShadow] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			if (!shadowRef.current) return;
			const topPosition = shadowRef.current.getBoundingClientRect().top;
			setShadow(topPosition <= 200);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const { width: windowWidth } = useWindowSize();
	const showFilterResultsAndExport = windowWidth > theme.breakpoints.values.md;

	// Now with the labels as keys
	const columns = {
		Device: {
			key: "displayName",
			width: "18%",
			sortable: true,
		},
		User: {
			key: "deviceOwnerInfo",
			width: "19%",
			sortable: true,
		},
		"Enrollment Status": {
			key: "enrollmentState",
			width: "18%",
			sortable: false,
		},
		"Device Status": {
			key: "deviceStatuses",
			width: "12%",
			sortable: true,
		},
		Explanation: {
			key: "complianceInfo",
			width: "20%",
			sortable: true,
		},
		"Last active": {
			key: "approximateLastSignInDateTime",
			width: "13%",
			sortable: true,
		},
	};

	const { loaderRef, renderCount } = useInfiniteLoader({
		total: shownDevices.length,
	});

	const summaryCells = useMemo(
		() => calculateSummaryCells(shownDevices, chosenFilters),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[shownDevices, chosenFilters, searchValue],
	);

	useEffect(() => {
		return () => {
			dispatch(resetFilters());
		};
	}, [dispatch]);

	return (
		<Grid container>
			<Grid
				ref={shadowRef}
				container
				className={clsx({ [styles.tableHeaderShadow]: shadow }, styles.tableHeader)}
			>
				{Object.entries(columns).map(([label, { width, key, sortable }]) => (
					<Grid item width={width} key={label}>
						{sortable && (
							<TableSortLabel
								active={sortConfig.key === key}
								direction={sortConfig.key === key ? sortConfig.direction : "asc"}
								onClick={() => handleSort(key)}
							>
								{label}
							</TableSortLabel>
						)}
						{!sortable && <Typography variant="body1">{label}</Typography>}
					</Grid>
				))}
			</Grid>
			{!isDevicesLoading &&
				shownDevices.slice(0, renderCount).map((device) => {
					const { deviceId, approximateLastSignInDateTime } = device;
					let sinceLastSignedIn = "Unknown";

					// If present and not from year, as microsoft returns 0001-01-01T00:00:00Z for devices that have never signed in
					if (
						approximateLastSignInDateTime &&
						dayjs(approximateLastSignInDateTime).year() > 1
					) {
						sinceLastSignedIn = dayjs(approximateLastSignInDateTime).fromNow();
					} else if (approximateLastSignInDateTime) {
						sinceLastSignedIn = "Never signed in";
					}

					const inactiveForAbove30Days = dayjs(approximateLastSignInDateTime).isBefore(
						dayjs().subtract(30, "days"),
					);
					return (
						<DeviceRow
							key={deviceId}
							device={device}
							sinceLastSignedIn={sinceLastSignedIn}
							inactiveForAbove30Days={inactiveForAbove30Days}
							columns={columns}
							windowWidth={windowWidth}
							users={users}
							isUsersLoading={isUsersLoading}
						/>
					);
				})}
			{isDevicesLoading &&
				Array.from({ length: 10 }).map((_, index) => (
					<LoadingDeviceRow key={index} columns={columns} windowWidth={windowWidth} />
				))}
			<SummaryTableFooter
				padding={false}
				summaryCells={showFilterResultsAndExport ? summaryCells : []}
				totalNumberOfItems={shownDevices.length}
				onResetFilters={onResetFilters}
				onDownloadExcel={showFilterResultsAndExport ? handleDownloadExcel : undefined}
				visible={shouldShowSummaryFooter}
			/>
			<div ref={loaderRef} />
		</Grid>
	);
};

export { ManageDevicesTable };

const filterAndSortDevices = (
	deviceEntities: Dictionary<Device>,
	filterIds: string[],
	searchValue: string,
	sortConfig: { key: string; direction: "asc" | "desc" },
) => {
	const devicesForFilter = filterIds.map((id) => deviceEntities[id] as Device);

	const filteredDevices = devicesForFilter.filter((device) => {
		if (!device) return false;
		const searchValueLower = searchValue.toLowerCase();
		const deviceNameLower = device.displayName.toLowerCase();
		const userNameLower = device.deviceOwnerInfo?.displayName.toLowerCase();
		const serialNumberLower = device.serialNumber?.toLowerCase();

		return (
			deviceNameLower?.includes(searchValueLower) ||
			userNameLower?.includes(searchValueLower) ||
			serialNumberLower?.includes(searchValueLower)
		);
	});

	const sorted = filteredDevices.sort((a, b) => {
		const sortConfigKey = sortConfig.key as keyof Device;
		const aCell = a[sortConfigKey] ?? "";
		const bCell = b[sortConfigKey] ?? "";
		if (sortConfig.direction === "asc") {
			return aCell > bCell ? 1 : -1;
		} else {
			return aCell < bCell ? 1 : -1;
		}
	});

	return sorted as Device[];
};
