import {
	createEntityAdapter,
	createSelector,
	createSlice,
	Dictionary,
	EntityState,
} from "@reduxjs/toolkit";
import { fetchProducts } from "actions/productActions";
import { RootState } from "store";
import {
	HardwareProduct,
	HardwareProductExtended,
	HardwareProductExtended as Product,
	ProductsMetadata,
} from "types";
import { LCSubStr } from "utilities/longestCommonSubstring";
import _ from "lodash";
import { mapToProducts } from "utilities/features/products";

const productsAdapter = createEntityAdapter<Product>({
	selectId: (product) => product.sku,
});

interface ProductState extends EntityState<Product> {
	cart: {
		countBySku: { [sku: string]: number };
		lastAdded: string;
	};
	productsMetadata: ProductsMetadata;
	isLoading: boolean;
	isFetching: boolean;
}

const initialCartState = {
	cart: {
		countBySku: {} as { [sku: string]: number },
		lastAdded: "",
	},
};

const productsSlice = createSlice({
	name: "products",
	initialState: productsAdapter.getInitialState({
		cart: {
			countBySku: {},
			lastAdded: "",
		},
		productsMetadata: {} as ProductsMetadata,
		isLoading: true,
		isFetching: false,
	}),
	reducers: {
		addItemToCart(state: ProductState, { payload: sku }) {
			state.cart.lastAdded = sku;
			state.cart.countBySku[sku] = (state.cart.countBySku[sku] ?? 0) + 1;
			return state;
		},
		addItemsToCart(
			state: ProductState,
			{ payload: countBySku }: { payload: { [sku: string]: number } },
		) {
			const skuArray = Object.keys(countBySku);
			skuArray.forEach((sku) => {
				state.cart.lastAdded = sku;
				state.cart.countBySku[sku] = (state.cart.countBySku[sku] ?? 0) + countBySku[sku];
			});

			return state;
		},
		removeItemFromCart(state: ProductState, { payload: sku }) {
			const count = state.cart.countBySku[sku];
			if (count === undefined) return state;
			if (count === 1) {
				delete state.cart.countBySku[sku];
				return state;
			}
			state.cart.countBySku[sku]--;
			return state;
		},
		deleteCartItem(state: ProductState, { payload: sku }) {
			delete state.cart.countBySku[sku];
			return state;
		},
		clearCartItems(state: ProductState) {
			return { ...state, ...initialCartState };
		},
		swapCartItems(state: ProductState, { payload: { productIdToSwap, productId, count } }) {
			delete state.cart.countBySku[productIdToSwap];
			state.cart.countBySku[productId] = count;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProducts.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchProducts.fulfilled, (state, { payload }) => {
				if (payload) {
					const products = mapToProducts(payload.products);
					productsAdapter.setAll(state, products);
					state.productsMetadata = payload.metadata;
				}
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const {
	addItemToCart,
	addItemsToCart,
	removeItemFromCart,
	clearCartItems,
	deleteCartItem,
	swapCartItems,
} = productsSlice.actions;

export const selectProducts = (state: RootState) => state.products;
export const selectProductsMetadata = (state: RootState) => state.products.productsMetadata;

export const productsSelectors = productsAdapter.getSelectors(selectProducts);

export const selectCartCountBySku = (state: RootState) => state.products.cart.countBySku;

export const selectCartItems = createSelector(
	selectCartCountBySku,
	productsSelectors.selectEntities,
	(cartCountBySku, productsById) =>
		Object.values(_.pick(productsById, Object.keys(cartCountBySku))).sort((a, b) =>
			Number(a?.availability?.quantity) > Number(b?.availability?.quantity) ? 1 : -1,
		),
);

export const selectItem = createSelector(
	selectCartCountBySku,
	productsSelectors.selectEntities,
	(cartCountBySku, productsById) =>
		Object.values(_.pick(productsById, Object.keys(cartCountBySku))),
);

export const selectItemByIds = (ids: string[]) =>
	createSelector(productsSelectors.selectEntities, (productsById) =>
		Object.values(_.pick(productsById, ids)),
	);

export const selectCartCount = createSelector(
	selectCartCountBySku,
	(countBySku: { [sku: string]: number }): number => _.sum(Object.values(countBySku)),
);

export const selectCartPriceTotal = createSelector(
	selectCartCountBySku,
	productsSelectors.selectEntities,
	(countBySku: { [sku: string]: number }, productsById: Dictionary<HardwareProduct>): number =>
		Math.round(
			_.sum(
				Object.entries(countBySku).map(
					([sku, count]) => (productsById[sku]?.priceInfo.priceNet ?? 0) * count,
				),
			),
		),
);

export const selectCartPriceTotalDiscount = createSelector(
	selectCartCountBySku,
	productsSelectors.selectEntities,
	(countBySku: { [sku: string]: number }, productsById: Dictionary<HardwareProduct>): number =>
		Math.round(
			_.sum(
				Object.entries(countBySku).map(
					([sku, count]) => Number(productsById[sku]?.priceInfo.discount) * count,
				),
			),
		),
);

export const selectLastAddedCartItem = (state: RootState) =>
	productsSelectors.selectById(state, state.products.cart.lastAdded);

type FilterFunction = (product: Product, productToSwap: Product) => boolean;
type SortFunction = (a: Product, b: Product, productToSwap: Product) => number;

export const selectSwappableProducts = (productIdToSwap: string, swapCount: number) =>
	createSelector(productsSelectors.selectEntities, (products) => {
		if (!productIdToSwap || !products) return [];
		const productToSwap = products[productIdToSwap] as Product;

		let filteredProducts = Object.values(products) as HardwareProductExtended[];
		const filter = ["availability", "subGroup", "priceDifference"];
		const sort = ["priceDifference", "manufacturer", "nameSimilarity"];

		for (const filterCriterion of filter) {
			const filterFunc = filterFunctions[filterCriterion];
			if (filterCriterion === "availability") {
				filteredProducts = filteredProducts.filter((product) =>
					availabilityFilterFunction(product as Product, swapCount),
				);
			} else if (filterFunc) {
				filteredProducts = filteredProducts.filter((product) =>
					filterFunc(product as Product, productToSwap),
				);
			}
		}

		for (const sortCriterion of sort) {
			const sortFunc = sortFunctions[sortCriterion];
			if (sortFunc) {
				filteredProducts.sort((a, b) => sortFunc(a, b, productToSwap));
			}
		}

		return filteredProducts as HardwareProductExtended[];
	});

const availabilityFilterFunction = (product: HardwareProduct, swapCount: number) =>
	Number(product?.availability.quantity) >= swapCount;
const filterFunctions: Record<string, FilterFunction> = {
	subGroup: (product, productToSwap) => product?.subGroup === productToSwap.subGroup,
	priceDifference: (product, productToSwap) => {
		const priceDifference =
			Number(productToSwap.priceInfo.priceNet) - Number(product.priceInfo.priceNet);
		const newProductIsMoreExpensive = priceDifference < 0;
		const priceDiffAbs = Math.abs(priceDifference);
		const priceDiffIsAbovePercentageThreshold =
			priceDiffAbs / Number(productToSwap.priceInfo.priceNet) > 0.5;
		const priceDiffIsAboveAbsoluteThreshold = priceDiffAbs > 5000;

		// Cheaper products are always allowed,
		// more expensive products are allowed if they are not too much more expensive
		if (
			newProductIsMoreExpensive &&
			(priceDiffIsAbovePercentageThreshold || priceDiffIsAboveAbsoluteThreshold)
		)
			return false;
		return true;
	},
};

const sortFunctions: Record<string, SortFunction> = {
	manufacturer: (a, b, productToSwap) => {
		if (
			productToSwap.manufacturer === a.manufacturer &&
			productToSwap.manufacturer !== b.manufacturer
		)
			return -1;
		if (
			productToSwap.manufacturer !== a.manufacturer &&
			productToSwap.manufacturer === b.manufacturer
		)
			return 1;
		return 0;
	},
	nameSimilarity: (a, b, productToSwap) =>
		LCSubStr(productToSwap.displayName, b.displayName) -
		LCSubStr(productToSwap.displayName, a.displayName),
	priceDifference: (a, b, productToSwap) => {
		const priceDifferenceA = Math.abs(
			Number(productToSwap.priceInfo.priceNet) - Number(a.priceInfo.priceNet),
		);
		const priceDifferenceB = Math.abs(
			Number(productToSwap.priceInfo.priceNet) - Number(b.priceInfo.priceNet),
		);
		return priceDifferenceA - priceDifferenceB;
	},
};

export default productsSlice.reducer;
