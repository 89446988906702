import { Grid, Skeleton, Typography } from "@mui/material";

import styles from "./LicenseAdjustmentCard.module.scss";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import {
	selectActiveLicenseManagementId,
	selectActiveLicenseCompleteDetails,
	setLicenseAdjustmentDrawerState,
	selectSubscribedSkus,
	setLicensedUsersDialogState,
} from "features/licenses/licenses";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { LicenseAdjustmentDrawerType } from "utilities/constants/enums";
import { fetchUsers } from "actions/userActions";
import { selectUsers } from "features/users";
import { selectCustomerDetails } from "features/customer";
import { selectScheduledLicenseActionsForSku } from "features/scheduledActions";
import { selectNumErrorsBySku } from "features/licenses/licenseAssignmentErrors";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useAppNavigate } from "hooks/useAppNavigate";
import { InternalLink } from "components/Common/Links";
import useRole from "utilities/roleUtils/roleCheck";
import { LicensedUsersDialog } from "../LicensedUsersDialog";

import clsx from "clsx";

const LicenseAdjustmentCard = () => {
	useApiOnce(fetchUsers, useAppSelector(selectUsers));
	const users = useAppSelector(selectUsers);
	const { isError: isSubscribedSkusError } = useAppSelector(selectSubscribedSkus);
	const customerInfo = useAppSelector(selectCustomerDetails);
	const activeLicenseManagementId = useAppSelector(selectActiveLicenseManagementId);
	const numLicenseAssignmentErrors = useAppSelector(
		selectNumErrorsBySku(activeLicenseManagementId),
	);
	const { dispatch } = useAuth();
	const handleOpenLicenseAdjustmentDrawer = (type: LicenseAdjustmentDrawerType) => {
		dispatch(setLicenseAdjustmentDrawerState({ isOpen: true, drawerType: type }));
	};
	const handleOpenLicensedUsersDialog = () => {
		dispatch(
			setLicensedUsersDialogState({ isOpen: true, licenseId: activeLicenseManagementId }),
		);
	};
	const { navigateToPage } = useAppNavigate();

	const handleNavigateToPage = () => {
		navigateToPage(NAVIGATION_NAMES.ASSIGN_LICENSES.path);
	};

	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const schduledActions = useAppSelector(
		selectScheduledLicenseActionsForSku(activeLicenseManagementId),
	);
	const numRemovalsForSku = Object.values(schduledActions).reduce(
		(acc, curr) => acc + Math.abs(curr.removals),
		0,
	);
	const totalQuantity = completeLicenseDetails?.totalQuantity ?? 0;
	const totalConsumedQuantity = completeLicenseDetails?.consumedQuantity ?? 0;

	const { isActionRole } = useRole();

	const licenseRemovalDisabled =
		customerInfo?.isLoading || !customerInfo?.yourEmployeesEnabled || !isActionRole;

	return (
		<Grid container className={styles.card}>
			<Grid container className={styles.textSectionContainer}>
				<TruncatableTypography variant="h2" maxCharLength={40}>
					{completeLicenseDetails?.friendlyName ?? ""}
				</TruncatableTypography>
				<Grid container className={styles.titleContainer}>
					<Typography variant="h3">Total licenses</Typography>
					<Typography variant="h3">{totalQuantity}</Typography>
				</Grid>
				{!isSubscribedSkusError && (
					<>
						<Grid
							container
							className={styles.textContainer}
							onClick={handleOpenLicensedUsersDialog}
						>
							<Grid container className={styles.standardWidthTextContainer}>
								<Typography variant="body1">Licenses assigned</Typography>
								<Typography variant="body1">{totalConsumedQuantity}</Typography>
							</Grid>
							{totalConsumedQuantity > 0 && (
								<Typography
									onClick={handleOpenLicensedUsersDialog}
									variant="description"
									className={styles.viewUsersLink}
								>
									View users
								</Typography>
							)}
						</Grid>
						{customerInfo?.yourEmployeesEnabled && (
							<Grid container className={styles.standardWidthTextContainer}>
								<Typography variant="body1">Unassigned licenses</Typography>
								<Typography variant="body1">
									{Math.max(totalQuantity - totalConsumedQuantity, 0)}
								</Typography>
							</Grid>
						)}
					</>
				)}
				<Grid container className={styles.scheduledActionsContainer}>
					<Grid container className={styles.standardWidthTextContainer}>
						<Typography variant="body1">Scheduled for removal</Typography>
						<Typography variant="body1">{numRemovalsForSku}</Typography>
					</Grid>
				</Grid>
				<Grid container className={styles.standardWidthTextContainer}>
					<Typography variant="body1">License assignment errors</Typography>
					{users.isLoading ? (
						<Skeleton width={15} height={25} />
					) : (
						<Typography variant="body1">{numLicenseAssignmentErrors}</Typography>
					)}
				</Grid>
			</Grid>
			<Grid container>
				<Grid container className={styles.buttonsContainer}>
					<PrimaryButton
						text={"Buy licenses"}
						size="medium"
						variantStyle="contained"
						action={() =>
							handleOpenLicenseAdjustmentDrawer(
								LicenseAdjustmentDrawerType.BUY_LICENSES,
							)
						}
						isDisabled={!isActionRole}
						disabledDescription={"You do not have permission to buy licenses."}
					/>
					<SecondaryButton
						text={"Remove licenses"}
						size="medium"
						variantStyle="outlined"
						action={() =>
							handleOpenLicenseAdjustmentDrawer(
								LicenseAdjustmentDrawerType.REMOVE_LICENSES,
							)
						}
						isDisabled={licenseRemovalDisabled}
						disabledDescription={`${
							!customerInfo?.yourEmployeesEnabled
								? "This action requires the 'IT for Your Employees' service."
								: isActionRole
								? ""
								: "You do not have permission to remove licenses."
						}`}
					/>
				</Grid>
				<Grid item mt={2} className={clsx({ [styles.hidden]: !isActionRole })}>
					<InternalLink onClick={handleNavigateToPage} textDecoration="underline">
						Assign license to user
					</InternalLink>
				</Grid>
			</Grid>
			<LicensedUsersDialog />
		</Grid>
	);
};

export { LicenseAdjustmentCard };
