import { Divider, Grid, Typography } from "@mui/material";
import styles from "./SummaryCart.module.scss";
import { ChosenLicensesDetails } from "../../ChosenLicensesDetails";
import {
	formatCostString,
	formatScandinavianCostString,
} from "utilities/currency/numberFormattingUtility";
import { selectCartPriceTotal, selectCartPriceTotalDiscount } from "features/hardware/products";
import { useAppSelector } from "hooks/hooks";
import { licensePricesSelectors } from "features/licenses/licensePrices";
import { SummaryCartProducts } from "../SummaryCartProducts";

const SummaryCart = ({
	chosenLicenses = [],
	isOnboardingReceipt = false,
	shouldShowProducts = true,
	shouldShowLicenses = true,
}) => {
	const FREE_SHIPPING_THRESHOLD = 5000;
	const licensePrices = useAppSelector(licensePricesSelectors.selectEntities);
	const cartPriceTotal = useAppSelector(selectCartPriceTotal);
	const cartPriceTotalDiscount = useAppSelector(selectCartPriceTotalDiscount);

	const totalCostChosenLicenses = chosenLicenses.reduce(
		(acc, { unusedLicenses, chosenVariant }) =>
			acc + unusedLicenses <= 0
				? licensePrices[chosenVariant.costIdentifier]?.UnitPriceAfterDiscount ?? 0
				: 0,
		0,
	);

	const freeShipping = cartPriceTotal > FREE_SHIPPING_THRESHOLD;

	return (
		<Grid container direction="column" className={styles.summaryCartContainer}>
			{!isOnboardingReceipt && (
				<Grid item xs={12} className={styles.header}>
					<Typography variant="subtitle1" fontWeight={500}>
						Order summary
					</Typography>
				</Grid>
			)}
			<Grid container direction="column" className={styles.cartProductsContainer}>
				{shouldShowProducts && <SummaryCartProducts />}
			</Grid>
			<Grid container direction="column" className={styles.summaryCartContent}>
				{chosenLicenses.length > 0 && (
					<>
						<Grid pl={2} my={2}>
							<ChosenLicensesDetails chosenLicenses={chosenLicenses} />
						</Grid>
						<Divider />
					</>
				)}
				<Grid
					container
					justifyContent="space-between"
					alignItems="center"
					mt={isOnboardingReceipt ? 1 : 2}
					mb={isOnboardingReceipt ? 1 : 0}
					pl={2}
				>
					{shouldShowProducts && (
						<>
							<Typography variant="subtitle1">Shipping</Typography>
							{freeShipping ? (
								<Typography variant="subtitle1" className={styles.freeShippingText}>
									Free
								</Typography>
							) : (
								<Typography variant="subtitle1">
									{formatCostString(" ", 149, " ,-")}
								</Typography>
							)}
						</>
					)}
				</Grid>
				<Grid
					container
					justifyContent="space-between"
					alignItems="center"
					my={isOnboardingReceipt ? 0 : 2}
					pl={2}
				>
					{isOnboardingReceipt ? (
						<Grid
							container
							direction="column"
							classes={{ root: styles.priceContainer }}
						>
							{shouldShowProducts && (
								<Grid container justifyContent="space-between" mb={1}>
									<Typography variant="subtitle1">
										Total hardware eks.mva
									</Typography>
									<Typography variant="subtitle1">
										<strong>
											{formatScandinavianCostString(cartPriceTotal)}
										</strong>
									</Typography>
								</Grid>
							)}
							{shouldShowLicenses && (
								<Grid container justifyContent="space-between">
									<Typography variant="subtitle1">
										Total license cost eks.mva
									</Typography>
									<Typography variant="subtitle1" fontWeight={500}>
										{formatScandinavianCostString(totalCostChosenLicenses)} / pr
										month
									</Typography>
								</Grid>
							)}
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12} display="flex" justifyContent="space-between" mb={2}>
								<Typography variant="subtitle1" display="inline">
									Total discount:
								</Typography>
								<Typography
									variant="subtitle1"
									display="inline"
									color="text.secondary"
									className={styles.discountText}
								>
									{formatCostString(" - kr ", cartPriceTotalDiscount, " ,-")}
								</Typography>
							</Grid>
							<Grid item xs={12} display="flex" justifyContent="space-between">
								<Typography variant="subtitle1" display="inline">
									Subtotal:
								</Typography>
								<Typography variant="subtitle1" display="inline" fontWeight={500}>
									{formatScandinavianCostString(
										cartPriceTotal + totalCostChosenLicenses,
									)}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export { SummaryCart };
