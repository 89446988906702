import { Grid, Typography } from "@mui/material";
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import styles from "./SelectHardware.module.scss";
import { HardwareList } from "./HardwareList";
import clsx from "clsx";
import { fetchProducts } from "actions/productActions";
import { fetchHardwareBundles } from "actions/hardwareBundlesActions";
import { useAppSelector, useApiOnce, useAuth, useApiOnceWithAndWithoutCache } from "hooks/hooks";
import { productsSelectors, selectCartCount, selectProducts } from "features/hardware/products";
import { HardwareGroups, Manufacturers } from "types";
import useInfiniteLoader from "hooks/useInfiniteLoader";
import { SortBy } from "utilities/constants/enums";
import { NoResultMessage } from "./NoResultMessage";
import { SearchBanner } from "./SearchBanner";
import { FilterGroup } from "./FilterGroup";
import { HardwareDrawer } from "./HardwareDrawer";
import { HardwareDrawerType } from "utilities/constants/enums";
import { hardwareBundlesSelectors, selectHardwareBundles } from "features/hardware/hardwareBundles";
import { BundleCard } from "./HardwareBundles/BundleCard";
import Carousel from "./HardwareBundles/Carousel/Carousel";
import { SecondaryButton } from "components/Common/Buttons/Button";
import { Settings } from "@mui/icons-material";
import { AddToCartButton } from "./HardwareList/Product/AddToCartButton";
import { AddNewBundleCard } from "./HardwareBundles/BundleCard/AddNewBundleCard";
import { setHardwareDrawerOpen } from "features/hardware/hardwarePage";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useFilteredProducts } from "hooks/hardwareHooks";
import { SwapProductDialog } from "../SwapProductDialog";
import useRole from "utilities/roleUtils/roleCheck";

interface Props {
	isOnboarding?: boolean;
	onboardingCheckoutMethod?: () => void;
}

const SelectHardware = ({ isOnboarding = false, onboardingCheckoutMethod }: Props) => {
	const { dispatch } = useAuth();

	const cartItemsCount = useAppSelector(selectCartCount);

	const productState = useAppSelector(selectProducts);
	const products = useAppSelector(productsSelectors.selectAll);

	const bundleState = useAppSelector(selectHardwareBundles);
	const bundles = useAppSelector(hardwareBundlesSelectors.selectAll);

	useApiOnceWithAndWithoutCache(fetchProducts, productState);

	// TODO: Might need to include in other bundle components to ensure that bundles are loaded in bundlestate
	useApiOnce(fetchHardwareBundles, bundleState);

	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

	// TODO: UTLED BACKEND
	const maxPrice = 75000;

	const [pricerange, setPricerange] = useState<number[]>([0, maxPrice]);

	const handlePriceChange = (
		event: Event | SyntheticEvent<Element, Event>,
		newValue: number | number[],
	) => {
		setPricerange(newValue as number[]);
	};

	const [selectedGroups, setSelectedGroups] = useState<HardwareGroups>({
		PC_NOTEBOOK_BUSINESS: false,
		PHONES_MOBILE: false,
		PC_ACCESSORIES: false,
		MICE_KEYBOARDS: false,
		SOUND: false,
		MONITOR: false,
		APPLE_PC_NOTEBOOK: false,
	});

	const [selectedManufacturers, setSelectedManufacturers] = useState<Manufacturers>({
		Apple: false,
		Microsoft: false,
		Lenovo: false,
		HP: false,
		Samsung: false,
		Logitech: false,
	});

	const defaultSort = SortBy.NotSorted;

	const [sortBy, setSortBy] = useState<string>(defaultSort);
	const [showInStockOnly, setShowInStockOnly] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [totalProductsInSearch, setTotalProductsInSearch] = useState(products.length);

	const hasNoSearchResult = selectedProducts.length === 0 && !productState.isLoading;

	const { loaderRef, renderCount, resetRenderCount } = useInfiniteLoader({
		total: totalProductsInSearch,
	});

	const allProductsRef = useRef<HTMLDivElement>(null);

	const handleGroupChange = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
		setSelectedGroups((state) => {
			// TODO: enum
			if (name === "PC_NOTEBOOK_BUSINESS") {
				return {
					...state,
					[name]: checked,
					APPLE_PC_NOTEBOOK: checked,
				};
			} else {
				return {
					...state,
					[name]: checked,
				};
			}
		});
	};

	const handleManufacturerChange = ({
		target: { name, checked },
	}: ChangeEvent<HTMLInputElement>) => {
		setSelectedManufacturers((state) => ({
			...state,
			[name]: checked,
		}));
	};

	const setSortedSearch = (value: string) => {
		allProductsRef.current?.scrollIntoView({ behavior: "auto" });
		setSortBy(value);
	};

	const { navigateToPage } = useAppNavigate();

	const handleNavigateToBundles = () => {
		navigateToPage(NAVIGATION_NAMES.HARDWARE_BUNDLES.path);
	};

	const { filteredProducts, filteredTotalProducts } = useFilteredProducts({
		sortBy,
		setSortBy,
		products,
		selectedGroups,
		selectedManufacturers,
		showInStockOnly,
		renderCount,
		searchQuery,
		pricerange,
		setSelectedGroups,
		setSelectedManufacturers,
	});

	useEffect(() => {
		setTotalProductsInSearch(filteredTotalProducts);
		setSelectedProducts(filteredProducts);
	}, [filteredTotalProducts, filteredProducts]);

	useEffect(() => {
		// Do not open the cart drawer if the user is onboarding
		if (!isOnboarding) {
			// Make sure that it's always the cart drawer that is open when navigating to this page, not the bundle drawer
			dispatch(setHardwareDrawerOpen({ drawerType: HardwareDrawerType.Cart }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isActionRole, isAdmin } = useRole();

	return (
		<Grid container className={styles.wrapper}>
			<Grid container className={styles.hardwarePageContainer}>
				<Grid container className={styles.dividerContainer}>
					<Typography variant="h3" className={styles.title}>
						Bundles
					</Typography>
					<SecondaryButton
						action={() => handleNavigateToBundles()}
						variantStyle="outlined"
						size="large"
						icon={<Settings className={styles.settingsIcon} />}
						className={styles.settingsButton}
						text="My bundles"
						hidden={!isAdmin}
					/>
				</Grid>
				<Grid
					container
					className={clsx(styles.bundleContainer, {
						[styles.onBoardingOffset]: isOnboarding,
					})}
				>
					<Carousel
						productStateIsLoading={productState.isLoading}
						numBundles={bundles?.length}
					>
						<Grid container gap={4} flexWrap="nowrap">
							{!bundleState.isLoading ? (
								<>
									{bundles.map((bundle) => (
										<BundleCard
											key={bundle.id}
											bundle={bundle}
											productStateLoading={productState.isLoading}
											isOnboarding={isOnboarding}
										/>
									))}
									{!isOnboarding && (
										<AddNewBundleCard
											disabled={!isActionRole}
											hidden={!isActionRole && bundles.length > 0}
										/>
									)}
								</>
							) : (
								[0, 1, 2, 3].map((skeleton) => (
									<BundleCard key={skeleton} isLoading />
								))
							)}
						</Grid>
					</Carousel>
				</Grid>
				<Grid container className={styles.dividerContainer} ref={allProductsRef}>
					<Typography variant="h3" className={styles.title}>
						All products
					</Typography>
				</Grid>
				<Grid container className={styles.productsListViewContainer}>
					<HardwareDrawer
						isOnboarding={isOnboarding}
						onboardingCheckoutMethod={onboardingCheckoutMethod}
					/>

					<Grid container className={styles.productsWrapper}>
						<Grid container className={styles.formGroup}>
							<FilterGroup
								setSearchQuery={setSearchQuery}
								resetRenderCount={resetRenderCount}
								setShowInStockOnly={setShowInStockOnly}
								showInStockOnly={showInStockOnly}
								selectedGroups={selectedGroups}
								selectedManufacturers={selectedManufacturers}
								handleGroupChange={handleGroupChange}
								handleManufacturerChange={handleManufacturerChange}
								products={products}
								setPricerange={handlePriceChange}
								pricerange={pricerange}
								maxPrice={maxPrice}
							/>
						</Grid>
						<Grid container className={styles.products}>
							<Grid container className={styles.searchBannerContainer}>
								<SearchBanner
									type={HardwareDrawerType.Cart}
									searchQuery={searchQuery}
									sortBy={sortBy}
									isOnboarding={isOnboarding}
									defaultSort={defaultSort}
									setSearchQuery={setSearchQuery}
									setSortBy={setSortedSearch}
									resetRenderCount={resetRenderCount}
									setShowInStockOnly={setShowInStockOnly}
									showInStockOnly={showInStockOnly}
									selectedGroups={selectedGroups}
									selectedManufacturers={selectedManufacturers}
									handleGroupChange={handleGroupChange}
									handleManufacturerChange={handleManufacturerChange}
									products={products}
									isLoading={productState.isLoading}
									cartItemsCount={cartItemsCount}
									pricerange={pricerange}
									setPricerange={handlePriceChange}
									maxPrice={maxPrice}
								/>
							</Grid>
							<Grid className={styles.hardwareListContainer}>
								<HardwareList
									selectedProducts={selectedProducts}
									setShowCheckoutDialog={() =>
										dispatch(
											setHardwareDrawerOpen({
												drawerType: HardwareDrawerType.Cart,
												forceOpen: true,
											}),
										)
									}
									isLoading={productState.isLoading}
									addToCartComponent={AddToCartButton}
								/>
								{!hasNoSearchResult && (
									<div ref={loaderRef} className={styles.loaderRef} />
								)}
							</Grid>
							<Grid container className={styles.noItemsMessageContainer}>
								{hasNoSearchResult && (
									<NoResultMessage
										searchQuery={searchQuery}
										manufacturers={selectedManufacturers}
										groups={selectedGroups}
										inStock={showInStockOnly}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{<SwapProductDialog />}
		</Grid>
	);
};

export { SelectHardware };
