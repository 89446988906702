import { Grid, Typography } from "@mui/material";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";

import styles from "./HardwareNavigation.module.scss";
import { capitalize } from "utilities/stringFormattingUtility";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
	selectHardwareDrawerState,
	setHardwareDrawerClosed,
	setSwapType,
} from "features/hardware/hardwarePage";
import { useEffect } from "react";
import { SwapType } from "utilities/constants/enums";

const HardwareNavigation = () => {
	const { navigateToPage } = useAppNavigate();
	const dispatch = useAppDispatch();

	const pathSegments = window.location.pathname.split("/");
	const activePage = pathSegments[pathSegments.length - 1];
	const pageChain = window.location.pathname.split("/").slice(1);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [activePage]);

	const handleNavigateToPressedBreadcrumb = (page: string) => {
		if (page === activePage) return;
		dispatch(setHardwareDrawerClosed());
		if (page === "hardware") {
			navigateToPage(NAVIGATION_NAMES.HARDWARE.path);
		} else if (page === "bundles") {
			navigateToPage(NAVIGATION_NAMES.HARDWARE_BUNDLES.path);
		} else if (page === "edit bundle") {
			navigateToPage(NAVIGATION_NAMES.HARDWARE_EDIT_BUNDLE.path);
		} else if (page === "create bundle") {
			navigateToPage(NAVIGATION_NAMES.HARDWARE_CREATE_BUNDLE.path);
		} else if (page === "hardware checkout") {
			navigateToPage(NAVIGATION_NAMES.HARDWARE_CHECKOUT.path);
		} else {
			navigateToPage(NAVIGATION_NAMES.HARDWARE.path);
		}
	};

	const getDisplayName = (page: string) => {
		const pageDisplayName = page.split("-").join(" ");
		const capitalizedPage = capitalize(pageDisplayName);
		return capitalizedPage;
	};

	// This part handles the active swap type.
	// If drawer is open, the swap type is "swap", which will delete a current product, and add a new one.
	// If drawer is closed, the swap type is "add", which will add a new product to the cart.
	const { activeDrawerType, isDrawerOpen } = useAppSelector(selectHardwareDrawerState);
	useEffect(() => {
		if (activeDrawerType.includes("Bundle")) {
			dispatch(
				setSwapType(isDrawerOpen ? SwapType.SwapWithBundleProduct : SwapType.AddToBundle),
			);
		} else {
			dispatch(setSwapType(isDrawerOpen ? SwapType.SwapWithCartProduct : SwapType.AddToCart));
		}
	}, [dispatch, isDrawerOpen, activeDrawerType]);

	return (
		<Grid container className={styles.wrapper}>
			<Grid container item className={styles.headerContainer}>
				<Grid item>
					<Typography variant="h2">{getDisplayName(activePage)}</Typography>
				</Grid>
				<Grid item mt={1}>
					{pageChain.length > 1 &&
						pageChain.map((page, index) => (
							<span key={page}>
								<Typography
									variant="description"
									className={clsx({
										[styles.lastChild]: index === pageChain.length - 1,
										[styles.breadcrumb]: true,
									})}
									onClick={() =>
										handleNavigateToPressedBreadcrumb(page.toLowerCase())
									}
								>
									{getDisplayName(page)}
								</Typography>{" "}
								{index !== pageChain.length - 1 && "> "}
							</span>
						))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export { HardwareNavigation };
